import './GlobirdPagination.css';
import { ServiceResponse, PageResponse } from 'data/CustomerPortalTypes';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Pagination } from 'antd';

type GlobirdPaginationControl = {
    refresh: () => void
};

const GlobirdPagination = forwardRef<GlobirdPaginationControl | undefined, {
    requestDataFunc: (offset: number, limit: number) => Promise<ServiceResponse<PageResponse<any>> | null>
    onJumpToPage?: (currentDataList: any[], totalCount: number) => void
}>(
    (props, ref) => {
        let isRequesting: boolean = false;
        const [allDataList, setAllDataList] = useState<any[]>([]);
        const [totalCount, setTotalCount] = useState(1);
        const [currentPageSize, setCurrentPageSize] = useState(10);
        const [currentPageIndex, setCurrentPageIndex] = useState(0);

        useImperativeHandle(
            ref,
            () => ({
                refresh() {
                    setAllDataList([]);
                    clickPageIndex(0, currentPageSize, true);
                }
            }))

        useEffect(() => {
            clickPageIndex(0, currentPageSize); // todo: do we need to make it depend on currentPageSize???
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const onShowSizeChange = (pageIndex: number, pageSize: number) => {
            setCurrentPageSize(pageSize);
        };

        const clickPageIndex = async (pageIndex: number, pageSize: number = 10, forceRefresh?: boolean) => {
            if (isRequesting === true || pageIndex < 0 || (totalCount !== 0 && pageIndex * pageSize >= totalCount)) {
                return;
            }

            isRequesting = true;
            let missData: boolean = false;
            let offset = pageIndex * pageSize;
            let limit = pageSize;
            let tempTotalCount = totalCount;
            let currentDataList: any[] = [];

            setCurrentPageIndex(pageIndex);
            // check if we have enough data in cache
            for (let i = offset; i < offset + limit; i++) {
                if (!allDataList[i]) {
                    missData = true;
                    currentDataList = [];
                    break;
                }

                currentDataList.push(allDataList[i]);
            }

            if (missData === true || forceRefresh === true) {
                var data = await props.requestDataFunc(offset, limit);
                isRequesting = false;
                if (data?.success === true && data?.data) {
                    currentDataList = data.data.data;
                    tempTotalCount = data.data.totalCount;
                    let tempAllDataList = forceRefresh === true ? [] : allDataList.slice(0);
                    // adding data for cache
                    for (var i = 0; i < currentDataList.length; i++) {
                        tempAllDataList[offset + i] = currentDataList[i];
                    }
                    setTotalCount(tempTotalCount);
                    setAllDataList(tempAllDataList);
                    
                }
            }

            if (props.onJumpToPage) {
                props.onJumpToPage(currentDataList, tempTotalCount);
            }
        };
        return (
            <div>
                <div className="normal-pagination">
                    <Pagination defaultCurrent={1} total={totalCount} onShowSizeChange={onShowSizeChange} onChange={(pageIndex, pageSize) => {clickPageIndex(pageIndex - 1, pageSize)}}/>
                </div>
                
                <div className="simple-pagination">
                    <div className="page-item" onClick={() => { clickPageIndex(currentPageIndex - 1) }}>Previous</div>
                    <div className="page-item" onClick={() => { clickPageIndex(currentPageIndex + 1) }}>Next</div>
                </div>
            </div>
        );
    });

export default GlobirdPagination;

export type {
    GlobirdPaginationControl
}