import { GlobirdTableContainer } from 'data/GlobirdTableContainer';
import './GlobirdTable.css';

const GlobirdTable = (props: { dataContainer: GlobirdTableContainer }) => {
    return (
        <div>
            <table className="globird-table wrapped-table">
                <thead>
                    <tr>
                        {
                            props.dataContainer.headerList.map((header, i) => 
                                <th key={i}>{header}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.dataContainer.rowList.map((row, i) => 
                            <tr key={i}>
                                {
                                    row.map((cell, j) => 
                                        <td key={j}>
                                            {cell}
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>

            <div className="globird-small-table">
                {
                    props.dataContainer.rowList.map((row, i) => 
                        <div className="row-block" key={i}>
                            {
                                row.map((cell, j) => 
                                    <div className="globird-row-fix-horizontal" key={j}>
                                        <div className="globird-col-4 header">{props.dataContainer.headerList[j]}</div>
                                        <div className="globird-col-6">{cell}</div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default GlobirdTable;