import './FAQ.css'
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { useNavigate } from 'react-router-dom';
import { List } from 'antd';
import GlobirdSelectorOption from "data/GlobirdSelectorOption";

const FAQ = (props: {menuOptions: GlobirdSelectorOption<string>[]}) => {
    const { menuOptions } = props;
    const navigate = useNavigate();

    const data = [
        { title: "FAQs about GloBird", url: "https://www.globirdenergy.com.au/help-support/faq/#AboutGlobird" },
        { title: "Rates", url: "https://www.globirdenergy.com.au/help-support/faq/#Rates" },
        { title: "Switching / Moving", url: "https://www.globirdenergy.com.au/help-support/faq/#SwitchingMoving" },
        { title: "Billing / Payments", url: "https://www.globirdenergy.com.au/help-support/faq/#BillingPayments" },
        { title: "Fees", url: "https://www.globirdenergy.com.au/help-support/faq/#Fees" },
        { title: "Meters / Tariffs", url: "https://www.globirdenergy.com.au/help-support/faq/#MetersTariffs" },
        { title: "Faults / Outages / Emergencies", url: "https://www.globirdenergy.com.au/help-support/faq/#FaultsOutagesEmergencies" },
        { title: "Solar FAQs", url: "https://www.globirdenergy.com.au/help-support/faq/#Solar" },
        { title: "Energy Industry FAQs", url: "https://www.globirdenergy.com.au/help-support/faq/#EnergyIndustry" }
    ];

    return (
        <div className="faq-block">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="faqMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions[0]}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>

            <div style={{ marginTop: "30px" }}>
                <List
                    dataSource={data}
                    renderItem={item =>
                        <List.Item>
                            <a href={item.url} target="_blank" rel="noreferrer">{item.title}</a>
                        </List.Item>
                    }>
                </List>
            </div>

        </div>
    );
}

export default FAQ;