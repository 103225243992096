import './Assistance.css'
import { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentAccount } from 'store/userSlice';
import { Form, Input, notification, Select } from 'antd';
import { ContactType, EmailAssistanceInfo } from 'data/CustomerPortalTypes';
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import RequestDataService from 'services/RequestDataService';
import menuOptions from '../MenuOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import links from 'data/Links';

const iconStyle: CSSProperties = {
    fontSize: '52px',
    textAlign: 'center',
    color: '#ffffff',
    background: '#ec008c',
    borderRadius: '50%',
    padding: '10px 12px'
};

const Assistance = () => {
    const navigate = useNavigate();
    const { Item } = Form;
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const currentAccount = useSelector(selectCurrentAccount);
    const [submitFinish, setSubmitFinish] = useState<boolean>(false);
    const [currentContactType, setCurrentContactType] = useState<ContactType>(ContactType.Email);
    const [ticketNumber, setTicketNumber] = useState<string | null>(null);

    const onFinish = (values: any) => {
        if (currentAccount) {
            let emailAssistanceRequest: EmailAssistanceInfo = {
                accountId: currentAccount.accountId,
                requestContent: form.getFieldValue("requestContent")
            };
            RequestDataService.submitAssistance(values.contactType, currentAccount.accountId, emailAssistanceRequest).then(data => {
                if (data?.success === true) {
                    notification.success({
                        message: "Success",
                        description: "Assistance request submitted"
                    });

                    setTicketNumber(data.data);
                    setSubmitFinish(true);
                }
            });
        }
    }

    const callUs = (e: any) => {
        e.preventDefault();
        setCurrentContactType(ContactType.Call);
        form.setFieldsValue({
            contactType: ContactType.Call
        });
    };

    return (
        <div className="assistance-block">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="assistanceMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions[1]}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>
            <div className="assistance-form">
                {
                    submitFinish === false ?
                        <Form
                            form={form}
                            name="accountSummaryForm"
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                contactType: ContactType.Email
                            }}>
                            <Item
                                name="contactType"
                                label={<label className="globird-form-field-label">Contact type</label>}>
                                <Select
                                    onChange={val => setCurrentContactType(val)}>
                                    <Select.Option value={ContactType.Call}>Call us</Select.Option>
                                    <Select.Option value={ContactType.Email}>Email us</Select.Option>
                                </Select>
                            </Item>

                            {
                                currentContactType === ContactType.Email ?
                                    <div>
                                        <Item
                                            name="requestContent"
                                            label={<label className="globird-form-field-label">Type your request</label>}
                                            rules={[{ required: true, message: 'Request content is required' }]}>
                                            <TextArea rows={4} />
                                        </Item>
                                        <p>
                                            We’ll get back to you as soon as we can via the email address on your energy account.
                                            Sometimes it can take a few days before we can respond.
                                            If your enquiry is urgent please<a href="#" onClick={callUs}> call us</a>.
                                        </p>
                                        <Item>
                                            <button className="globird-button-primary" style={{ width: '100%' }}>Submit</button>
                                        </Item>
                                    </div>
                                    :
                                    <div>
                                        <div className="assistance-phone-block">
                                            <div className="phone-icon">
                                                <FontAwesomeIcon
                                                    icon={faPhone}
                                                    style={iconStyle} />
                                            </div>
                                            <p>
                                                Phone<br />
                                                <a href={links.phone.general}>{links.phone.generalDisplay}</a> or <a href={links.phone.international}>{links.phone.internationalDisplay}</a><br />
                                                Or <a href={links.phone.chinese}>{links.phone.chineseDisplay}</a> (中文)<br />
                                                Or interpreter service <a href={links.phone.interpreter}>{links.phone.interpreterDisplay}</a> (other language)
                                            </p>
                                        </div>
                                        <hr style={{ margin: "30px 0px" }}></hr>
                                        <div className="assistance-clock-block">
                                            <div className="clock-icon">
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                    style={iconStyle} />
                                            </div>
                                            <p>
                                                Business Hours<br />
                                                Customer Service<br />
                                                Monday to Friday : 8:30 am to 5:30 pm<br />
                                                Saturday and Public Holidays 9:00 am to 3:00 pm (Eastern Standard Time)
                                            </p>
                                        </div>
                                    </div>
                            }
                            {/* <Item 
                        name="phoneNumber"
                        label={<label className="globird-form-field-label">Phone Number</label>} 
                        rules={[{ required: true, message: 'Phone number cannot be empty' },
                                { pattern: new RegExp(/^(04)([0-9]){8}$/g), message: 'Must be 10 digits long, and starts with 04' }]}>
                            <Input allowClear />
                    </Item>
                    <Item 
                        name="scheduleCallbackTime"
                        label={<label className="globird-form-field-label">Choose a date and time to callback</label>} 
                        rules={[{ required: true, message: 'Schedule call back time cannot be empty' }]}>
                            <DatePicker 
                                style={{width: "100%"}} 
                                allowClear 
                                showTime
                                format="MMM DD, YYYY HH:mm"
                                disabledDate={(current) => { return current && current.isBefore(moment());}}/>
                    </Item> */}
                        </Form>
                        :
                        <div style={{ textAlign: "center" }}>
                            <h1 style={{ marginTop: "10px" }}>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ color: '#0bb50b' }} /> Submit Success
                            </h1>
                            <p>
                                Your email request has been submitted successfully.<br />
                                Your ticket number is <b>{ticketNumber}</b>
                            </p>
                            <button className="globird-button-primary" onClick={() => { navigate(`/dashboard`); }}>Done</button>
                        </div>
                }
            </div>
        </div>
    );
}

export default Assistance;