import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'data/CustomerPortalTypes';
import { RootState } from 'store/store';
import authService from 'services/AuthService';

type UserState = {
    data: User | null
};

const initialState: UserState = {
    data: null
};

export const refreshUser = createAsyncThunk(
    'data/refreshUser',
    async () => {
        return await authService.authenticate();
    });

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.data = action.payload;
        },
        unsetUser(state) {
            state.data = null
        },
        updateCurrentAccount(state, action: PayloadAction<number>) {
            if (state.data) {
                var newAcccountId = action.payload;
                var targetIndex = state.data.accounts.findIndex(
                    x => x.accountId === newAcccountId);

                if (targetIndex !== -1) {
                    state.data.currentAccount = state.data.accounts[targetIndex];
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshUser.fulfilled, (state, action) => {
            if (!action?.payload) {
                return;
            }

            if (state.data?.currentAccount) {
                const newCurrentAccount = action.payload
                    .accounts
                    ?.find(a => a?.accountId === state.data?.currentAccount!.accountId);
                
                if (newCurrentAccount) {
                    action.payload.currentAccount = newCurrentAccount;
                }
            }

            state.data = action.payload;
        })
    },
});

export const { setUser, unsetUser, updateCurrentAccount } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.user.data;

export const selectCurrentAccount = (state: RootState) =>
    state.user.data?.currentAccount ?? null;

export const selectCurrentAccountId = (state: RootState) =>
    state.user.data?.currentAccount?.accountId ?? null;