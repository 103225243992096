import {Checkbox, Divider, Form, Input, Modal, Result, Select} from 'antd';
import styles from '../Concession.module.css';
import {useEffect, useMemo, useState} from 'react';
import {
    Account,
    AccountOwnerInfoDto,
    AccountServiceInfo,
    AddConcessionCardResult,
    ConcessionCardTypeDto,
    EuropaAccountServiceStatus,
    FuelType,
    ServiceType,
    StateCode
} from 'data/CustomerPortalTypes';
import {
    getAccountOwners,
    getAccountServiceStatusDateAsync,
    getConcessionCardTypes,
    submitConcessionDetails
} from 'services/RequestDataService';
import DatePicker from 'components/Shared/DatePicker';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import superManImg from 'assets/globirdSuperman1.png';
import {FieldData} from 'rc-field-form/lib/interface';
import {uniqBy} from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { useNavigate } from 'react-router';
import links from 'data/Links';

const { Item } = Form;

const displayOwnerName = (owner: AccountOwnerInfoDto) => {
    if (!owner) {
        return '';
    }
    var displayName = owner.firstName;
    if (owner.middleName) {
        displayName += ' ' + owner.middleName;
    }
    if (owner.lastName) {
        displayName += ' ' + owner.lastName;
    }
    return displayName;
}

const isSupportedAccountService = (accStatus: EuropaAccountServiceStatus) => {
    return accStatus === EuropaAccountServiceStatus.Switched
    || accStatus === EuropaAccountServiceStatus.PendingSwitchOut
    || accStatus === EuropaAccountServiceStatus.PendingSwitch
    || accStatus === EuropaAccountServiceStatus.PendingMoveIn
    || accStatus === EuropaAccountServiceStatus.TransferPending
    || accStatus === EuropaAccountServiceStatus.TransferRequested
    || accStatus === EuropaAccountServiceStatus.PendingSwitchResults
}

const ConcessionForm = ({
    currentAccount
}: {
    currentAccount: Account | null
}) => {

    const [allSites, setAllSites] = useState<AccountServiceInfo[]>([]);
    const [switchedSites, setswitchedSites] = useState<AccountServiceInfo[]>([]);
    const [accountOwners, setAccountOwners] = useState<AccountOwnerInfoDto[]>([]);
    const [concessionCardTypes, setConcessionCardTypes] = useState<ConcessionCardTypeDto[]>();
    const [selectedCardType, setSelectedCardType] = useState<string>();
    const [form] = Form.useForm();
    const [formErrored, setFormErrored] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [accountHasSASite, setAccountHasSASite] = useState<boolean>(false);
    const [selectedAccountService, setSelectedAccountService] = useState<number>();
    const [resultModalVisible, setResultModalVisible] = useState<boolean>(false);
    const [submissionResult, setSubmissionResult] = useState<AddConcessionCardResult | undefined>(undefined);
    const [isExpiryDateRequired] = useMemo(() => {
        const accountService = currentAccount!.services.find(s => s.accountServiceId === selectedAccountService)
        return [accountService?.state !== "QLD" || selectedCardType !== "QGSC"]
    }, [selectedAccountService, selectedCardType])

    const navigate = useNavigate();

    useEffect(() => {
        if (currentAccount?.services?.length === 0) {
            return;
        }

        setAllSites([]);
        setAccountOwners([]);
        setConcessionCardTypes([]);
        setSelectedCardType('');
        setFormErrored(false);
        setTermsAccepted(false);
        setAccountHasSASite(false);
        setSelectedAccountService(0);
        setResultModalVisible(false);
        setSubmissionResult(undefined);

        var accountServiceList = currentAccount!.services;

        const asyncAction = async () => {
            const result = (await getAccountServiceStatusDateAsync(
                currentAccount!.services.map(x => x.accountServiceId)))
                ?.data;

            if (!result) {
                return;
            }

            //Check for south australian sites
            if (accountServiceList.some(x => 
                x.state === StateCode.SA
                && isSupportedAccountService(result[x.accountServiceId]?.status))) {
                setAccountHasSASite(true);
                return;
            }

            var switchedSites = accountServiceList.filter(x =>
                isSupportedAccountService(result[x.accountServiceId]?.status));
            setswitchedSites(switchedSites)
            var filteredSites = uniqBy(switchedSites, 'concessionAddress');
            setAllSites(filteredSites);
            setSelectedAccountService(filteredSites[0].accountServiceId);
            loadConcessionCardTypes(filteredSites[0].accountServiceId, switchedSites);
            const owners = await getAccountOwners(currentAccount!.accountId)

            if (owners.success && owners.data) {
                setAccountOwners(owners.data);
            }
        }

        asyncAction();
    }, [currentAccount]);

    useEffect(() => {
        if (form.getFieldValue('concessionNumber') !== undefined) {
            form.validateFields(['concessionNumber']);
        }
    }, [form, selectedCardType])

    var loadConcessionCardTypes = async (accountServiceId: number, sites?: AccountServiceInfo[] ): Promise<void> => {

        var address = (sites ?? switchedSites).find(x => x.accountServiceId === accountServiceId)?.concessionAddress
        var sitesByAddress = (sites ?? switchedSites).filter(x => x.concessionAddress == address)
        if (sitesByAddress.length === 0) {
            setConcessionCardTypes([]);
            return;
        }
        var hasPower = sitesByAddress.findIndex(x => x.serviceType === ServiceType.Power) > -1
        var hasGas = sitesByAddress.findIndex(x => x.serviceType === ServiceType.Gas) > -1
        var fuelType = hasPower&&hasGas?FuelType.Dual:(  hasPower ? FuelType.Electricity : FuelType.Gas)
        const cct = await getConcessionCardTypes(sitesByAddress[0].state, fuelType);

        if (cct.success && cct.data) {
            setConcessionCardTypes(cct.data);
        }
    }

    var genRulesForCardNumber = () => {
        var cardNum = form.getFieldValue('concessionNumber');

        //Default max 10
        if (!cardNum) {
            return {
                max: 10,
                message: 'A Concession Card should have max 10 characters'
            }
        }

        //Health Care Card and Pension Concession Card
        //9 numbers & 1 character
        if (selectedCardType === 'HCC' || selectedCardType === 'PCC') {
            return {
                pattern: /^\d{9}[a-zA-Z]{1}$/,
                message: selectedCardType === 'HCC'
                    ? 'A Health Care Card number should be 9 numbers followed by 1 alphabet letter'
                    : 'A Pensioner Concession Card number should be 9 numbers followed by 1 alphabet letter'
            }
        }
        //Queensland Gvt Seniors Card
        //7 or 8 characters
        if (selectedCardType === 'QGSC') {
            return {
                max: 8,
                min: 7,
                message: 'A Queensland Government Seniors Card is 7 or 8 digits'
            }
        }
        //DVA Cards
        if (selectedCardType?.startsWith('DV')) {
            return {
                validator: (_: any, value: any) => {
                    const pattern = /^[NVQWSTnvqwst][a-zA-Z][a-zA-Z\d]{2}\d{4}[a-zA-Z]?$/;
                    var isValid = pattern.test(value);

                    if (!isValid) {
                        //Secondary Test - if card beings with correct letters, show different error message based on length
                        const secondPattern = /^[NVQWSTnvqwst][a-zA-Z][a-zA-Z\d]{6,7}/;
                        var cardStartLettersValid = secondPattern.test(value);

                        //Veteran cards - 8 digits
                        if ((value as string).length === 8 && cardStartLettersValid) {
                            return Promise.reject('A DVA card number of eight characters long, ends with at least 4 numbers, e.g. QSS12345');
                        }
                        //Dependant cards - 9 digits
                        if ((value as string).length === 9 && cardStartLettersValid) {
                            return Promise.reject('A DVA card number for dependents is nine characters long, has at least 4 numbers and the last character will be a letter, e.g. VSS54321A');
                        }
                        return Promise.reject('A DVA card number should start with N,Q,S,T,V,W, followed by another letter and should be a total of 8 or 9 characters in length, e.g. QSS12345');
                    }
                    return Promise.resolve();
                }
            }
        }

        return {
            max: 10,
            message: 'A Concession Card should have max 10 characters'
        }
    }

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
        let errored = false;
        for (let field of allFields) {
            if (field.errors?.length
                && field.errors?.length > 0) {
                errored = true;
            }
        }
        setFormErrored(errored);
    }

    if (accountHasSASite) {
        return <div className={styles['concession-warning-wrapper']}>
            <div className={styles['concession-warning']}>
                <p>Energy retailers in South Australia cannot transfer your concession, you must contact ConcessionsSA</p>
                <p>To apply you will need:</p>
                <ul>
                    <li>Your Centrelink customer reference number (CRN) or DVA file number</li>
                    <li>Information from your most recent electricity bill, including:</li>
                    <ul>
                        <li>Retailer Name</li>
                        <li>Account Number</li>
                        <li>National Metering Identifier (NMI) number</li>
                        <li>Billing Dates</li>
                    </ul>
                </ul>
                <p>For more information, <a href={links.concession.saConcessionInfo}>click here</a></p>
            </div>
        </div>;
    }

    if (!accountOwners?.length || !allSites || !selectedAccountService) {
        return <div className={styles['concession-warning-wrapper']}>
            <div className={styles['concession-warning']}>
                Sorry, there are no sites on your account where concession cards can be added.
            </div>
        </div>
    }

    const onFinish = async (values: any) => {
        if (!currentAccount?.accountId || !selectedCardType) {
            return;
        }

        var result = await submitConcessionDetails(currentAccount.accountId, {
            contactId: values.concessionName,
            accountServiceId: values.concessionAddress,
            cardType: selectedCardType,
            cardNumber: values.concessionNumber,
            startDate: moment(values.concessionStartDate),
            expiryDate: isExpiryDateRequired ? moment(values.concessionExpiryDate) : undefined
        })
        if (result.success !== null && result.data !== null) {
            setSubmissionResult(AddConcessionCardResult[result.data.addConcessionCardResult]);
            setResultModalVisible(true);
            return;
        }
        setSubmissionResult(AddConcessionCardResult.Failed);
        setResultModalVisible(false);
        return;
    }
    
    const resultsText = () => {
        switch (submissionResult) {
            case AddConcessionCardResult.Success:
                return 'Thank you for providing your Concession Card details. Please allow up to 2 business days to' +
                    ' process your request. Once processed you will see the status of your request on the history page and we will communicate with you if there are any issues.';
            case AddConcessionCardResult.DuplicatePending:
                return <p>This concession card has already been submitted and is currently pending approval. Please
                    allow up to 2 business days to process your request. Once processed you will see the status
                    of your request on the history page and we will communicate with you if there are any
                    issues.</p>;
            default:
                return <p>We are unable to apply this concession card to your account.
                    If the card details entered are correct, please
                    <span style={{color: '#e71d73', marginLeft: '4px', textDecoration: 'underline'}}>
                        <a href='assistance'>contact us</a>
                    </span>
                    &nbsp;so that we can further assist you.</p>;
        }
    }

    return <><Form
        form={form}
        labelAlign='left'
        layout={'vertical'}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        initialValues={{
            concessionName: accountOwners[0].contactId,
            concessionAddress: selectedAccountService
        }}
        onValuesChange={() => form.validateFields()}
        requiredMark={false}>
        <div className={styles['manage-concession-split-panel']}>
            <div className={styles['manage-concession-form-wrapper']}>
                <p className={styles['manage-concession-heading']}>Add a Concession</p>
                <Item
                    name='concessionName'
                    valuePropName='value'
                    className={styles['concession-form-item-nomargin']}
                    label={
                        <p className={styles['manage-concession-field-label']}>
                            Name on Concession Card*
                        </p>
                    }
                    colon={false}>
                        <Select
                            style={{minWidth: '100%'}}
                            options={
                                accountOwners.map(ao => ({
                                    label: displayOwnerName(ao),
                                    value: ao.contactId
                                }))
                            }
                            disabled={accountOwners.length < 2}
                            showArrow={accountOwners.length >= 2}/>
                        
                </Item>
                <br />
                <Item
                    name='concessionAddress'
                    valuePropName='value'
                    className={styles['concession-form-item-nomargin']}
                    label={
                        <p className={styles['manage-concession-field-label']}>
                            Address On Card*
                        </p>
                    }
                    tooltip={'Sometimes the distributors for the different services will have a slightly different address for the same site. If there are two addresses listed for your primary address, please select the address that is the closest match to the address on your concession card.'}
                    colon={false}>
                    <Select
                        options={
                            allSites.map(s => ({
                                label: s.concessionAddress,
                                value: s.accountServiceId
                            }))
                        }
                        placeholder='Select an address'
                        onChange={accountServiceId => loadConcessionCardTypes(accountServiceId)}
                        disabled={allSites.length < 2}
                        allowClear={true}
                        showArrow={allSites.length >= 2} />
                </Item>
                <p className={styles['manage-concession-field-sub-text']}>Your concession card can only be linked to one property, your primary address.</p>
                <br />
                <Item
                    name='concessionType'
                    valuePropName='value'
                    rules={[{
                        required: true,
                        message: 'Please select a card type'
                    }]}
                    label={
                        <p className={styles['manage-concession-field-label']}>
                            Card Type
                        </p>
                    }>
                    <Select
                        options={
                            concessionCardTypes?.map(cct => ({
                                label: cct.description,
                                value: cct.code
                            }))
                        }
                        onChange={card => setSelectedCardType(card)}
                        placeholder='Select a type'>
                    </Select>
                </Item>
                <Item
                    name='concessionNumber'
                    valuePropName='value'
                    rules={[{
                        required: true,
                        message: 'Please enter your concession card number'
                    }, genRulesForCardNumber()]}
                    label={
                        <p className={styles['manage-concession-field-label']}>
                            Card Number
                        </p>
                    }>
                    <Input
                        onInput={() => form.validateFields(['concessionNumber'])}
                        autoComplete='none'
                        placeholder='Enter card number'>
                    </Input>
                </Item>
                <Item
                    name='concessionStartDate'
                    valuePropName='value'
                    rules={[{
                        required: true,
                        message: 'Please enter the start date listed on your card.'
                    },
                    {
                        validator: (_, value: any) => {
                            if (!value) {
                                return Promise.resolve();
                            }
                            if (moment(value) > moment().endOf('day')) {
                                return Promise.reject('Start date should not be in the future');
                            }
                            return Promise.resolve();
                        }
                    }]}
                    label={
                        <p className={styles['manage-concession-field-label']}>
                            Start Date
                        </p>
                    }>
                    <DatePicker
                        id='concessionStartDatePicker'
                        autoComplete='off'
                        picker='date'
                        mode='year'
                        disabledDate={x => x && x > moment().endOf('day')}
                        onChange={() => isExpiryDateRequired && form.getFieldValue('concessionExpiryDate') && form.validateFields(['concessionExpiryDate'])}
                        style={{ minWidth: '100%'}}>
                    </DatePicker>
                </Item>
                {
                isExpiryDateRequired
                    ? 
                    <Item
                        name='concessionExpiryDate'
                        valuePropName='value'
                        rules={[{
                            required: true,
                            message: 'Please enter the expiry date listed on your card.'
                        },
                        {
                            validator: () => {
                                const start = form.getFieldValue('concessionStartDate');
                                const expiry = form.getFieldValue('concessionExpiryDate');
                                if (!start || !expiry) {
                                    return Promise.resolve();
                                }
                                if (start > expiry) {
                                    return Promise.reject('Expiry date should be after start date');
                                }
                                return Promise.resolve();
                            }
                        }]}
                        label={
                            <p className={styles['manage-concession-field-label']}>
                                Expiry Date
                            </p>
                        }>
                        <DatePicker
                            id='concessionEndDatePicker'
                            autoComplete='off'
                            picker='date'
                            mode='year'
                            style={{ minWidth: '100%'}}
                            onBlur={() => form.validateFields(['concessionExpiryDate'])}
                            disabledDate={x => x && x > moment().endOf('day') && x < form.getFieldValue('concessionStartDate')}>
                        </DatePicker>
                    </Item>
                    : <></>
                }
                <p className={classnames(styles['manage-concession-notes-item'], styles['incorrect-card-details'])}>
                    If the name or address on your card is incorrect,
                    please contact your card issuer.
                    <br />
                    If the name or address on your concession card
                    is not an exact match with your GloBird account details - 
                    please
                    <span style={{color: '#e71d73', marginLeft: '4px', textDecoration: 'underline'}}>
                        <a href='assistance'>contact us</a>
                    </span>
                    .
                </p>
            </div>
            <Divider
                type={'vertical'}
                className={styles['concession-split-divider']}
            />
            <div className={styles['manage-concession-terms-wrapper']}>
                <div className={styles['concession-superman']}>
                    <img className={styles['concession-superman-img']} src={superManImg} alt='Globird SuperMan' />
                    <div className={styles['concession-superman-text']}>
                        <div>Important information<br /> regarding Concessions</div>
                    </div>
                </div>
                <ul className={styles['manage-concession-notes']}>
                    <li className={styles['manage-concession-notes-item']} style={{marginBottom: '0.5rem'}}>
                        Globird Energy is seeking your consent to collect & use your concession card information
                        from the Department of Human Services to validate your concession eligibility for as long as
                        Globird is your energy retailer.
                    </li>
                    <li className={styles['manage-concession-notes-item']} style={{marginBottom: '0.5rem'}}>
                        You can revoke your consent at any time by contacting us. You must tell us if
                        your concession eligibility changes.
                    </li>
                    <li className={styles['manage-concession-notes-item']}>
                        There is a range of payment assistance available including flexible payment plans. 
                        To learn more about our payment assistance policy in Victoria or our hardship policy for other states, visit: <a href='https://www.globirdenergy.com.au/legal/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "underline" }}>https://www.globirdenergy.com.au/legal/</a>
                    </li>
                </ul>

                <Item
                    name='concessionTerms'
                    valuePropName='checked'
                    rules={[{
                        required: true,
                        message: 'Please accept to continue'
                    }]}>
                    <Checkbox
                        style={{fontWeight: '600', fontSize: '1.1rem'}}
                        value={termsAccepted}
                        onChange={event => setTermsAccepted(event.target.checked)}>
                        I understand & consent to this.
                    </Checkbox>
                </Item>

                <p className={styles['manage-concession-full-details']}>
                    For full details on concessions, visit:
                    <br />
                    <span style={{color: '#e71d73', textDecoration: 'underline'}}>
                        <a href={links.corporateWebsite.concessions}>www.globirdenergy.com.au/concessions/</a>
                    </span>
                </p>

                <Item
                    style={{justifyContent: 'center'}}>
                    <Bwtt
                        type='primary'
                        htmlType='submit'
                        disabled={!termsAccepted || formErrored}
                        disableMessage={'Please fill in all details & accept the terms.'}
                        btnNode='Submit'
                        componentClassName={['form-save-button']}
                        id={"submit"}
                    />
                </Item>
            </div>
        </div>
    </Form>
    <Modal
        visible={resultModalVisible}
        onOk={() => {
            if (submissionResult === AddConcessionCardResult.Success) {
                navigate('/dashboard');
                return;
            }
            setResultModalVisible(false);
            setSubmissionResult(AddConcessionCardResult.Failed); // reset default failed
        }}
        onCancel={() => {
            if (submissionResult === AddConcessionCardResult.Success) {
                navigate('/dashboard');
                return;
            }
            setResultModalVisible(false);
            setSubmissionResult(AddConcessionCardResult.Failed);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable
        style={{minWidth: '700px'}}
        okButtonProps={{
            size: 'large',
            style: { minWidth: '150px', margin: '0 auto', display: 'block' },
            id: 'okButton'
        }}
        centered>
        <Result
            status={submissionResult === AddConcessionCardResult.Success ? 'success' : 'error'}
            title={resultsText()}
        />
    </Modal></>
}

export default ConcessionForm;