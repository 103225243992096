import {
    ActivateAccount,
    ActivationOutcome,
    AuthCookieName,
    ChangeLoginDto,
    ChangeLoginResultDto,
    ChangePasswordResultDto,
    GetAccessTokenResultDto,
    ImpersonateTokenName,
    LoginResultDto,
    SendOtpResult,
    RegisterAccountDto,
    RegisterResultDto,
    RequestResetPasswordOutcome,
    ServiceResponse,
    UnavailableSessionKey,
    User,
    OtpLoginRequest,
} from 'data/CustomerPortalTypes';
import Cookie from 'js-cookie';
import {HttpMethod, HttpStatusCode, JsonHeader} from 'utilities/HttpHelper';
import {DashboardDataCache} from 'components/Dashboard';
import rsa from 'js-crypto-rsa';
import {
    bytesToBase64,
    clearRecord,
    DataCache,
    genJsonHeaderWithHCaptchaToken,
    handleResponse,
    RequestSameOrigin,
} from 'utilities/Utility';
import MessageService from 'services/MessageService';
import {MessageTopic} from 'data/Message';

const loginUrl: string = '/api/account/login';
const logoutUrl: string = '/api/account/logout';
const pwdTokenUrl: string = '/api/account/publicjwk';
const userUrl: string = '/api/account/currentuser';
const registerUrl: string = '/api/account/register';
const tokenUrl: string = '/api/account/token';

type AuthenticateResult = {
    isSucceeded: boolean,
    user: User | null,
    resultPhrase: string,
    exception: boolean
};

const authenticationWrapper = <T extends (...args: any) => any>(func: T) =>
    (...params: Parameters<T>): ReturnType<T> => {
        MessageService.get().send({
            topic: MessageTopic.requestStart,
            data: null
        });

        let isPromise = false;

        try {
            const result = func(...(params ?? [] as any[]));

            if (result && Object.prototype.toString.call(result) === "[object Promise]") {
                isPromise = true;

                (result as Promise<any>).finally(() =>
                    MessageService.get().send({
                        topic: MessageTopic.requestEnd,
                        data: null
                    })
                );
            }

            return result;
        }
        finally {
            !isPromise && MessageService.get().send({
                topic: MessageTopic.requestEnd,
                data: null
            });
        }
    }

let rsaPubKeyCache: DataCache<JsonWebKey>;

const authServiceLocal = {
    async logout() {
        await fetch(new RequestSameOrigin(logoutUrl));
        clearRecord(DashboardDataCache);
    },
    async getPwdToken() {
        rsaPubKeyCache ??= await DataCache.Create<JsonWebKey>(
            async () => {
                const jwkResult = await fetch(
                    new RequestSameOrigin(
                        pwdTokenUrl,
                        { method: HttpMethod.GET }
                    ));

                if (jwkResult.status !== HttpStatusCode.OK) {
                    throw Error('Unable to get required data.');
                }

                return await jwkResult.json();
            },
            300000);

        return rsaPubKeyCache.data;
    },
    async encryptPassword(
        password: string) {

        const jwk = await this.getPwdToken();

        if (!jwk) {
            throw Error('Unable to get required data.');
        }

        return bytesToBase64(await rsa.encrypt(
            new TextEncoder().encode(password),
            jwk,
            'SHA-256'));
    },
    async activateAccount(params: ActivateAccount): Promise<ServiceResponse<ActivationOutcome> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/activate`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({
                    ...params,
                    Password: await this.encryptPassword(params.Password),
                    ConfirmPassword: undefined
                })
            }
        ));

        return await handleResponse(responseData, false, false);
    },
    async changePassword(
        oldPassword: string,
        newPassword: string,
        hCaptchaToken: string | null): Promise<ServiceResponse<ChangePasswordResultDto> | null> {

        return await handleResponse(
            fetch(new RequestSameOrigin(
                `/api/account/changepassword`,
                {
                    method: HttpMethod.POST,
                    headers: genJsonHeaderWithHCaptchaToken(hCaptchaToken),
                    body: JSON.stringify({
                        oldPassword: await this.encryptPassword(oldPassword),
                        newPassword: await this.encryptPassword(newPassword)
                    })
                }
            )),
            false);
    },
    async changeLogin(dto: ChangeLoginDto, hCaptchaToken: string | null): Promise<ServiceResponse<ChangeLoginResultDto> | null> {

        return await handleResponse(
            fetch(new RequestSameOrigin(
                `/api/account/changelogin`,
                {
                    method: HttpMethod.POST,
                    headers: genJsonHeaderWithHCaptchaToken(hCaptchaToken),
                    body: JSON.stringify({
                        ...dto,
                        password: await this.encryptPassword(dto.password)
                    })
                }
            )),
            false,
            false);
    },
    async sendOtpCode(
        email: string,
        hCaptchaToken: string | null
    ): Promise<ServiceResponse<SendOtpResult> | null> {
        return await handleResponse(
            fetch(new RequestSameOrigin(
                `/api/account/sendotp`,
                {
                    method: HttpMethod.POST,
                    headers: genJsonHeaderWithHCaptchaToken(hCaptchaToken),
                    body: JSON.stringify(email)
                }
            )),
            true,
            false);
    },
    async requestResetPassword(
        email: string,
        accountNumber?: string): Promise<ServiceResponse<RequestResetPasswordOutcome> | null> {

        const url = accountNumber
            ? `/api/account/resetpassword?email=${encodeURIComponent(email)}&accountNumber=${accountNumber}`
            : `/api/account/resetpassword?email=${encodeURIComponent(email)}`

        let responseData = fetch(new RequestSameOrigin(
            url,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, false);
    },
    async resetPasswordTokenMeta(
        token: string): Promise<ServiceResponse<boolean> | null> {

        let responseData = fetch(new RequestSameOrigin(
            `/api/account/resetpasswordtokenmeta?token=${encodeURIComponent(token)}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, true, false);
    },
    async register(
        regDto: RegisterAccountDto,
        hCaptchaToken: string | null): Promise<ServiceResponse<RegisterResultDto> | null> {

        return await handleResponse(
            fetch(new RequestSameOrigin(
                registerUrl,
                {
                    method: HttpMethod.POST,
                    headers: genJsonHeaderWithHCaptchaToken(hCaptchaToken),
                    body: JSON.stringify(regDto)
                }
            )),
            false);
    },
    async login(
        emailAddress: string,
        password: string,
        rememberMe: boolean,
        hCaptchaToken: string | null): Promise<ServiceResponse<LoginResultDto> | null> {

        return await handleResponse(
            fetch(new RequestSameOrigin(
                loginUrl,
                {
                    method: HttpMethod.POST,
                    body: JSON.stringify({
                        emailAddress,
                        password: await this.encryptPassword(password),
                        rememberMe
                    }),
                    headers: genJsonHeaderWithHCaptchaToken(hCaptchaToken)
                })),
            true,
            false,
            () => sessionStorage.setItem(UnavailableSessionKey, JSON.stringify({ unavailableFlag: true })));
    },
    async otpLogin(request: OtpLoginRequest): Promise<ServiceResponse<LoginResultDto> | null> {
        let url = '/api/account/OtpLogin';
        let responseData = fetch(new RequestSameOrigin(
            url,
            {
                method: HttpMethod.POST,
                headers: genJsonHeaderWithHCaptchaToken(request.hCaptchaToken),
                body: JSON.stringify({
                    ...request
                }),
            },
        ))
        return await handleResponse(responseData, true, false)
    },
    async resetPassword(
        email: string,
        token: string,
        newPassword: string): Promise<ServiceResponse<boolean> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/account/resetpassword`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({
                    email,
                    token,
                    newPassword: await this.encryptPassword(newPassword)
                })
            }
        ));

        return await handleResponse(responseData, false);
    },
    authenticate: async (): Promise<User | null> => {

        if (!Cookie.get(AuthCookieName) && !sessionStorage.getItem(ImpersonateTokenName)) {

            return null;
        }

        let user = (await handleResponse<User>(
            fetch(new RequestSameOrigin(
                userUrl,
                { method: HttpMethod.GET }
            )),
            true,
            false,
            () => {
                sessionStorage.setItem(UnavailableSessionKey, JSON.stringify({ unavailableFlag: true }));
                sessionStorage.removeItem(ImpersonateTokenName);
            }
        ))?.data;

        user && (user.currentAccount = user.accounts[0]);

        return user ?? null;
    },
    getAccessToken: async (): Promise<ServiceResponse<GetAccessTokenResultDto> | null> => {
        const responseData = fetch(new RequestSameOrigin(
            tokenUrl,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, false);
    }
};

const authService = {
    logout: authServiceLocal.logout,
    changePassword: authenticationWrapper(
        authServiceLocal.changePassword.bind(authServiceLocal)),
    login: authenticationWrapper(
        authServiceLocal.login.bind(authServiceLocal)),
    sendOtpCode: authenticationWrapper(
        authServiceLocal.sendOtpCode.bind(authServiceLocal)),
    otpLogin: authenticationWrapper(
        authServiceLocal.otpLogin.bind(authServiceLocal)),
    requestResetPassword: authenticationWrapper(
        authServiceLocal.requestResetPassword.bind(authServiceLocal)),
    resetPassword: authenticationWrapper(
        authServiceLocal.resetPassword.bind(authServiceLocal)),
    authenticate: authenticationWrapper(
        authServiceLocal.authenticate.bind(authServiceLocal)),
    activateAccount: authenticationWrapper(
        authServiceLocal.activateAccount.bind(authServiceLocal)),
    register: authenticationWrapper(
        authServiceLocal.register.bind(authServiceLocal)),
    resetPasswordTokenMeta: authenticationWrapper(
        authServiceLocal.resetPasswordTokenMeta.bind(authServiceLocal)),
    changeLogin: authenticationWrapper(
        authServiceLocal.changeLogin.bind(authServiceLocal)),
    getAccessToken: authenticationWrapper(
        authServiceLocal.getAccessToken.bind(authServiceLocal)),
};

export default authService;

export const {
    logout,
    changePassword,
    login,
    otpLogin,
    requestResetPassword,
    sendOtpCode,
    resetPassword,
    authenticate,
    activateAccount,
    register,
    resetPasswordTokenMeta,
    changeLogin,
    getAccessToken
} = authService;