import { Form, Select, notification } from 'antd';
import DatePicker from 'components/Shared/DatePicker';
import { CallbackCategory, CallbackContactDto, DateTimeFormat, LodgeCallbackRequest, PreferredCallbackTime } from 'data/CustomerPortalTypes';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCallbackCategories, getCallbackTimeSlotInfo, getContactsForCallback, lodgeCallback } from 'services/RequestDataService';
import { selectCurrentAccount } from 'store/userSlice';
import '../Callback.css';
import { disabledCallbackDates as isCallbackDateDisabled, generateRequiredRule, getRuleObject } from 'utilities/Utility';
import { defaultCallbackTimeSlots, renderTimeSlotSelectOptions } from '../index';
import trackEvent from 'services/AnalyticsService';
import { addBusinessDays } from 'utilities/DateTool';

const preferredCallbackTimeOptions = [
    { label: 'Anytime', value: PreferredCallbackTime.Anytime },
    { label: 'Schedule a time', value: PreferredCallbackTime.Scheduled }
];

const NewCallbackForm = ({
    refreshCallbackInfo,
    publicHolidays
} : {
    refreshCallbackInfo: () => void,
    publicHolidays: string[]
}) => {
    const [form] = Form.useForm();
    const { Item } = Form;
    const currentAccount = useSelector(selectCurrentAccount);

    const [callbackContacts, setCallbackContacts] = useState<CallbackContactDto[]>([]);
    const [selectedContact, setSelectedContact] = useState<CallbackContactDto | null>(null);
    const [callbackCategories, setCallbackCategories] = useState<CallbackCategory[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
    const [preferredCallbackTime, setPreferredCallbackTime] = useState<PreferredCallbackTime>(PreferredCallbackTime.Anytime);
    const [callbackTimeSlots, setCallbackTimeSlots] = useState<Map<number, boolean>>(defaultCallbackTimeSlots);
    const [selectedCallbackDate, setSelectedCallbackDate] = useState<Moment | null>(null);

    const refreshContacts = useCallback(async () => {
        if (!currentAccount) {
            return;
        }

        const contactResponse = await getContactsForCallback(currentAccount.accountId);

        const { success, data } = contactResponse || {};

        if (success && data) {
            setCallbackContacts(data);
        }
    }, [currentAccount]);

    useEffect(() => {
        if (publicHolidays) {
            const today = moment();
            const nextBusinessDay = addBusinessDays(today, 1, publicHolidays);
            setSelectedCallbackDate(nextBusinessDay);
        }
    }, [publicHolidays]);

    const refreshCallbackCategories = async () => {
        const categoriesResponse = await getCallbackCategories();

        const { success, data } = categoriesResponse || {};

        if (success && data) {
            setCallbackCategories(data);
        }
    };

    const getCallbackTimeSlots = useCallback(async (targetDate: Moment) => {
        const timeSlotsResponse = await getCallbackTimeSlotInfo(targetDate);

        const { success, data: timeSlotMap } = timeSlotsResponse || {};

        if (success && timeSlotMap && timeSlotMap.size > 0) {
            setCallbackTimeSlots(timeSlotMap);

            // find the first time slot which is not booked, and set it as the default value
            const timeSlotKeys = Array.from(timeSlotMap.keys());
            const firstAvailableTimeSlot = timeSlotKeys.find(time => !timeSlotMap?.get(time));

            if (firstAvailableTimeSlot) {
                form.setFieldsValue({ callbackTimeSlot: firstAvailableTimeSlot });
            } else {
                //No available time slots, display error on form
                form.setFields([
                    {
                        name: 'callbackDate',
                        errors: ['No available time slots for this date. Please select another date.']
                    }
                ]);
                form.setFieldsValue({ callbackTimeSlot: undefined });
            }
        } else {
            setCallbackTimeSlots(defaultCallbackTimeSlots);
        }
    }, [form]);

    useEffect(() => {
        refreshContacts();
        refreshCallbackCategories();
    }, [currentAccount, refreshContacts]);

    useEffect(() => {
        if (!selectedContact) {
            setSelectedContact(callbackContacts[0]);
        }
    }, [callbackContacts]);

    useEffect(() => {
        if (!selectedCategoryId) {
            setSelectedCategoryId(callbackCategories[0]?.callbackCategoryId);
        }
    }, [callbackCategories, selectedCategoryId]);

    useEffect(() => {
        if (preferredCallbackTime === PreferredCallbackTime.Scheduled && selectedCallbackDate) {
            getCallbackTimeSlots(selectedCallbackDate);
        }
    }, [selectedCallbackDate, preferredCallbackTime, getCallbackTimeSlots]);

    const onFinish = async (values: any) => {
        if (!currentAccount || !selectedContact || !selectedCategoryId) {
            return;
        }

        if (preferredCallbackTime === PreferredCallbackTime.Scheduled && (!selectedCallbackDate || !values.callbackTimeSlot) ) {
            return;
        }

        //Combine the date and time to create a single datetime object
        const scheduledDateTime = preferredCallbackTime === PreferredCallbackTime.Scheduled
            ? moment(selectedCallbackDate).set({ 'h': values.callbackTimeSlot, 'm': 0, 's': 0 }).format(DateTimeFormat)
            : undefined;

        const formInput : LodgeCallbackRequest = {
            phoneNumber: selectedContact?.phoneNumber,
            categoryId: selectedCategoryId,
            scheduledDateTime
        };

        const response = await lodgeCallback(currentAccount.accountId, formInput);

        const { success, data } = response || {};

        if (success && data) {
            notification.success({
                message: "Success",
                description: "Callback request submitted"
            });
            
            trackEvent("click", "callback", "lodged_callback", currentAccount.accountId);

            refreshCallbackInfo();
        }
    }

    const validateScheduledDateValue = (value: Moment | null) => {
        if (!value) {
            return null;
        }

        return isCallbackDateDisabled(value, publicHolidays)
            ? 'Invalid date'
            : null;
    }


    if (!currentAccount || !selectedContact || !selectedCategoryId) {
        return null;
    }

    return (
        <div className="callback-form">
            <h2>Request a callback</h2>
            <Form
                form={form}
                name="callbackForm"
                layout="vertical"
                onFinish={onFinish}>
                    <Item
                        name="callbackContactId"
                        label={<label className="globird-form-field-label">Contact to</label>}
                        initialValue={selectedContact.callbackContactId}>
                        <Select
                            onChange={val => setSelectedContact(callbackContacts.filter(x => x.callbackContactId === val)[0])}>
                            {callbackContacts.map((contact, i) => (
                                <Select.Option key={i} value={contact.callbackContactId}>{contact.phoneNumber} - {contact.contactName}</Select.Option>
                            ))}
                        </Select>
                    </Item>
                    <Item
                        name="callbackCategoryId"
                        label={<label className="globird-form-field-label">Category</label>}
                        initialValue={selectedCategoryId}>
                        <Select
                            onChange={val => setSelectedCategoryId(val)}>
                            {callbackCategories.map((category, i) => (
                                <Select.Option key={i} value={category.callbackCategoryId}>{category.categoryName}</Select.Option>
                            ))}
                        </Select>
                    </Item>
                    <Item
                        name="preferredCallbackTime"
                        label={<label className="globird-form-field-label">Preferred callback time</label>}
                        initialValue={preferredCallbackTime}>
                        <Select onChange={val => setPreferredCallbackTime(val)}>
                            {preferredCallbackTimeOptions.map((option, i) => (
                                <Select.Option key={i} value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                    </Item>
                    {preferredCallbackTime === PreferredCallbackTime.Scheduled && selectedCallbackDate
                        ? <>
                            <Item
                                name="callbackDate"
                                label={<label className="globird-form-field-label">Callback Date</label>}
                                initialValue={selectedCallbackDate}
                                rules={[
                                    generateRequiredRule('Callback Date'),
                                    getRuleObject(value => validateScheduledDateValue(value))
                                ]}>
                                <DatePicker
                                    id='callbackDate'
                                    style={{ width: '100%' }}
                                    mode='date'
                                    onChange={val => setSelectedCallbackDate(val)}
                                    disabledDate={currentDate => isCallbackDateDisabled(currentDate, publicHolidays)}
                                    allowClear={false}/>
                            </Item>
                            <Item
                                name="callbackTimeSlot"
                                label={<label className="globird-form-field-label">Callback Time Slot</label>}
                                rules={[generateRequiredRule('Callback Time Slot')]}>
                                <Select>
                                    {renderTimeSlotSelectOptions(callbackTimeSlots)}
                                </Select>
                            </Item>
                        </>
                        : null
                    }
                    <Item>
                        <button className="globird-button-primary" style={{ width: '100%' }} type="submit">Submit</button>
                    </Item>
            </Form>
        </div>
    );
}

export default NewCallbackForm;