import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import Bowser from "bowser";
import store from "store/store";
import { removeHashAndQueryFromUrl } from "utilities/Utility";
import { ImpersonateTokenName } from "data/CustomerPortalTypes";

var telemetryInitializer: (item: ITelemetryItem) => boolean | void = (
  envelope: ITelemetryItem
) => {
  if (
    (envelope?.baseType === "PageviewPerformanceData" ||
      envelope?.baseType === "PageviewData") &&
    envelope?.baseData?.name &&
    envelope?.ext?.trace?.name
  ) {
    try {
      let traceName = envelope.ext.trace.name as string;

      envelope.baseData.name = removeHashAndQueryFromUrl(traceName);
    } catch {}
  }

  if (
    envelope?.baseType === "RemoteDependencyData" &&
    envelope?.baseData?.name
  ) {
    try {
      let traceName = envelope.baseData.name as string;

      envelope?.data && (envelope.data.url = traceName.split(" ")[1]);

      envelope.baseData.name = removeHashAndQueryFromUrl(traceName);
    } catch {}
  }

  envelope?.data && (envelope.data.applicationName = "CustomerPortalFrontend");

  envelope?.data &&
    (store.getState()?.user?.data?.isImpersonatedUser ||
      sessionStorage.getItem(ImpersonateTokenName) ||
      window.location.search?.indexOf("impersonate") >= 0) &&
    (envelope.data.isImpersonatedUser = true);

  if (
    (envelope?.baseType === "RemoteDependencyData" ||
      envelope?.baseType === "PageviewData") &&
    envelope?.data &&
    window?.screen?.width &&
    window?.screen?.height &&
    window?.devicePixelRatio
  ) {
    try {
      const horizontalPixels = Math.floor(
        window.devicePixelRatio * window.screen.width
      );
      const verticalPixels = Math.floor(
        window.devicePixelRatio * window.screen.height
      );

      envelope.data.horizontalPixels = horizontalPixels;
      envelope.data.verticalPixels = verticalPixels;

      verticalPixels &&
        (envelope.data.aspectRatio = Number(
          (horizontalPixels / verticalPixels).toFixed(2)
        ));
    } catch {}
  }

  if (
    (envelope?.baseType === "PageviewPerformanceData" ||
      envelope?.baseType === "PageviewData") &&
    envelope?.data
  ) {
    try {
      const uaInfo = Bowser.parse(window.navigator.userAgent);

      envelope.data.browserName = uaInfo?.browser?.name;
      envelope.data.browserVersion = uaInfo?.browser?.version;

      envelope.data.osName = uaInfo?.os?.name;
      envelope.data.osVersion = uaInfo?.os?.version;
      envelope.data.osVersionName = uaInfo?.os?.versionName;

      envelope.data.clientPlatform = uaInfo?.platform?.type;

      envelope.data.browserEngineName = uaInfo?.engine?.name;
      envelope.data.browserEngineVersion = uaInfo?.engine?.version;
    } catch {}
  }
};

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "981b6f55-3200-4ed1-8366-28f21b590e0e",
    endpointUrl: "/api/appinsight",
    enableAutoRouteTracking: true,
    disableFetchTracking: false,
    enableUnhandledPromiseRejectionTracking: true,
  },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(telemetryInitializer);

export default appInsights;