type HeaderType = (string | JSX.Element | undefined | null)[];
type RowType = (string | JSX.Element | undefined | null)[][];

class GlobirdTableContainer {
    headerList: HeaderType = [];
    rowList: RowType = [];

    constructor(
        headerList?: HeaderType,
        rowList?: RowType) {

        headerList && (this.headerList = headerList);
        rowList && (this.rowList = rowList);
    }
}

export { GlobirdTableContainer }