import styles from './CorrespondenceDetail.module.css';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { selectCurrentAccount } from 'store/userSlice';
import { getCorrespondenceDetailAsync } from 'services/RequestDataService';
import { Document } from 'data/CustomerPortalTypes';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Bwtt from 'components/Shared/ButtonWithToolTip';

const CorrespondenceDetail = () => {
    const currentAccount = useSelector(selectCurrentAccount);
    const contentBlockEle = useRef<HTMLDivElement>(null);
    const [attachmentList, setAttachmentList] = useState<Document[]>([]);

    const correspondenceDetailIdStr = useMatch('/correspondences/:id')?.params.id

    const correspondenceDetailId = correspondenceDetailIdStr
        ? parseInt(correspondenceDetailIdStr)
        : 0;

    useEffect(() => {

        if (!currentAccount?.accountId || !correspondenceDetailId || isNaN(correspondenceDetailId)) {
            return;
        }

        const action = async () => {
            const res = await getCorrespondenceDetailAsync(currentAccount!.accountId, correspondenceDetailId);

            if (!res?.success || !res.data?.contentBody || !contentBlockEle.current) {
                return;
            }

            contentBlockEle.current.innerHTML = res.data?.contentBody;
            setAttachmentList(res.data.documentList ?? []);
        }

        action();

    }, [currentAccount, correspondenceDetailId]);

    return (
        <div className={styles['correspondence-detail-block']}>
            <div ref={contentBlockEle}></div>
            <hr className={styles['attachment-seperator']}></hr>
            <div className={styles['attachment-block']}>
                {attachmentList.map((d) => <Bwtt
                    disabled={d.isReversedInvocie}
                    disableMessage='Reversed invoices are not available for download.'
                    btnClassName={[styles['attachment']]}
                    noDefaultBtnClass
                    onClick={d.isReversedInvocie
                        ? undefined :
                        () => window.open(
                            `/api/correspondence/document?accountId=${currentAccount?.accountId}&documentId=${d.documentId}`,
                            '_blank')}
                    componentStyle={{ display: 'inline-block', marginRight: '10px' }}
                    btnNode={<>
                        <FontAwesomeIcon icon={faPaperclip} />
                        <p>{d.name}</p>
                    </>} />)
                }
            </div>
        </div>

    );
}

export default CorrespondenceDetail;