import { UnavailableSessionKey, UnavailableState } from 'data/CustomerPortalTypes';
import { selectUser } from 'store/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect} from 'react';
import links from 'data/Links';

const Unavailable = () => {
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const state = useLocation().state as UnavailableState;
    const unavailableState = state || JSON.parse(sessionStorage.getItem(UnavailableSessionKey) || '{}') as UnavailableState;

    useEffect(() => {
        if (user) {
            navigate('/dashboard', { replace : true });
        }
        
        if (!unavailableState?.unavailableFlag) {
            navigate('/login', { replace : true });
        }

        return;
    }, [user, unavailableState]);

    return (
        <div style={{ 
            textAlign: 'center',
            marginTop: '80px'
            }}>
            <h1>
                Sorry, your My Account is no longer active. Contact us on <a href={links.phone.general}>{links.phone.generalDisplay}</a> if we can help.
            </h1>
        </div>      
    );
}

export default Unavailable;