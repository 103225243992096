import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectCurrentAccount } from 'store/userSlice';
import { Form, Radio, Input, Modal, Result, Checkbox, Button } from 'antd';
import CreditCardFields, { CreditCardField } from 'components/Shared/CreditCardFields';
import styles from './PayBill.module.css';
import {
    getRuleObject,
    amountFormater,
    amountFormaterRealTime,
    generateCheckBoxRule,
    formatAmount
} from 'utilities/Utility';
import {
    fetchAccountBalance,
    fetchAccountPaymentBtnFlag,
    getBalanceByAccountId,
    getPaymentBtnFlagByAccountId
} from 'store/transactionSlice';
import { DirectPaymentMethod, CreditCardDirectDebit } from 'data/CustomerPortalTypes';
import { directPayment, defaultDirectDebitAmount, setupDirectDebit } from 'services/RequestDataService';
import GlobirdDivider from 'components/Shared/GlobirdDivider';
import menuOptions from '../MenuOptions';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import ActionResultPanel from 'components/ActionResultPanel';
import links from 'data/Links';
import classnames from 'classnames';

const cardErrorFormFields = [
    {
        name: 'CardHolderName',
        errors: ['']
    },
    {
        name: 'CardExp',
        errors: ['']
    },
    {
        name: 'CardNumber',
        errors: ['Please check your card details.']
    },
    {
        name: 'CardCvn',
        errors: ['']
    }
];

const cardValidFormFields = [
    {
        name: 'CardHolderName',
        errors: []
    },
    {
        name: 'CardExp',
        errors: []
    },
    {
        name: 'CardNumber',
        errors: []
    },
    {
        name: 'CardCvn',
        errors: []
    }
];

const tAndCmsg = 'Please read and agree the direct debit service term and condition before continue.';

interface PaymentForm extends CreditCardField {
    PaymentMethod: DirectPaymentMethod,
    Amount: string
    AgreeTandC: boolean
};

const validateAmount = (amount: string) => {
    if (!amount) {
        return 'Payment amount is required.';
    }

    const amountNumber = Number.parseFloat(amount);

    if (Number.isNaN(amountNumber)
        || String(amountNumber).split('.')[1]?.length > 2) {
        return 'Invalid payment amount.';
    }

    if (amountNumber <= 0) {
        return 'Payment amount must be more than 0.00 AUD.';
    }

    if (amountNumber > 50000) {
        return 'Payment amount must be less than 50000.00 AUD.';
    }

    return null;
}

let paymentSubmitted = false;

const PayBill = () => {
    const account = useSelector(selectCurrentAccount);
    const paymentBtnDisabledFlag = useSelector(getPaymentBtnFlagByAccountId(account?.accountId)); 
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [paymentResult, setPaymentResult] = useState<boolean | null>(null);
    const [defaultAmount, setDefaultAmount] = useState<number | null>(null);
    const [preventSubmit, setPreventSubmit] = useState<boolean>(false);
    const [amount, setAmount] = useState<string>('');
    const [paymentForm] = Form.useForm<PaymentForm>();

    const [setupDD, setSetupDD] = useState<boolean | null>(false);
    const accountBalance = useSelector(getBalanceByAccountId(account?.accountId));

    useEffect(() => {
        if (!account || paymentResult === false) {
            return;
        }

        const func = async (accountId: number) => {

            const result = await defaultDirectDebitAmount(accountId);

            if (!result?.success) {
                return;
            }

            setDefaultAmount(result.data ?? 0);
            setAmount((result.data ?? 0).toFixed(2));
            paymentForm.setFieldsValue({
                Amount: (result.data ?? 0).toFixed(2)
            });

            dispatch(fetchAccountBalance(account.accountId));
        }

        func(account.accountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, paymentResult]);

    const initialValue = {
        PaymentMethod: DirectPaymentMethod.CreditCard,
        Amount: defaultAmount?.toFixed(2) ?? '',
        CardHolderName: '',
        CardExp: null,
        CardNumber: '',
        CardCvn: '',
        CardType: null,
        AgreeTandC: false
    };

    useEffect(() => {
        paymentForm.resetFields();
        setPaymentResult(null);
        setSetupDD(false);
    }, [account, paymentForm]);

    useEffect(() => {
        if(!account || paymentBtnDisabledFlag !== null) {
            return;
        }

        dispatch(fetchAccountPaymentBtnFlag(account.accountId));
    }, [dispatch, account, paymentBtnDisabledFlag])

    if (!account || defaultAmount === null) {
        return <></>;
    }

    // navigate to dashboard if not allow to do payment
    if (paymentBtnDisabledFlag) {
        navigate(`/dashboard`);
    }

    const onPay: (val: PaymentForm) => Promise<void> = async (val) => {

        if (paymentSubmitted || preventSubmit) {

            return;
        }

        paymentSubmitted = true;

        try {

            const payResult = await directPayment(
                account.accountId,
                {
                    ...val,
                    CardExp: val.CardExp!
                });

            if (payResult?.data) {
                if(val.AgreeTandC)
                {
                    const ccDto: CreditCardDirectDebit = {
                        CardNumber: val.CardNumber,
                        CardHolderName:  val.CardHolderName,
                        CardExp:  val.CardExp!
                    };
                    const ccResult = await setupDirectDebit(
                        account.accountId,
                        ccDto);
                    if(ccResult?.data)
                    {
                        setPaymentResult(true);
                        dispatch(fetchAccountBalance(account.accountId));
                    }
                    else
                    {
                        setPaymentResult(false);
                    }
                }
                else if(!val.AgreeTandC)
                {
                    setPaymentResult(true);
                    dispatch(fetchAccountBalance(account.accountId));
                }
                else
                {
                    setPaymentResult(false);
                }
            }
            else if (payResult?.data === false
                && paymentForm.getFieldValue('PaymentMethod') ===
                DirectPaymentMethod.CreditCard) {

                setPreventSubmit(true);
                paymentForm.setFields(cardErrorFormFields);
            }
            else {
                setPaymentResult(false);
            }
            
            setTimeout(() => {
                dispatch(fetchAccountPaymentBtnFlag(account.accountId));
            }, 2000);
        } finally {
            paymentSubmitted = false;
        }
    }

    const resetPaymentResultAndRedirect = (path: string) => {
        setPaymentResult(null);
        navigate(path);
    }

    return (
        <div className="form-container">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="paymentMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions[0]}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>
            {(paymentResult === null || paymentResult === true) && <div className="field-container">
                <Form
                    onValuesChange={() => {
                        if (!preventSubmit) {
                            return;
                        }

                        setPreventSubmit(false);
                        paymentForm.setFields(cardValidFormFields);
                        paymentForm.validateFields();
                    }}
                    onFinish={onPay}
                    initialValues={initialValue}
                    form={paymentForm}
                    // validateTrigger="onBlur"
                    layout={'vertical'}
                    name="paymentForm">
                    {
                        accountBalance !== null 
                            ? <Form.Item label="Account Balance">
                                <span>${formatAmount((Math.abs(accountBalance)).toString()) + (accountBalance > 0 ? ' credit' : '')}</span>
                            </Form.Item>
                            : null
                    }
                    <Form.Item
                        className={styles['form-field-amount']}
                        label={<div>Payment Amount <br/>
                            <span style={{color:'red'}} >{ ((accountBalance !== null && accountBalance < 0 && accountBalance != defaultAmount) ? '(after any applicable discounts)' : '')}</span>
                        </div>}            
                        rules={[getRuleObject(validateAmount)]}
                        name="Amount"
                        style={{ width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Input
                                style={{
                                    textAlign: 'right',
                                    width:'60%'
                                }}
                                onChange={e => {
                                    const formatted = amountFormaterRealTime(e);
                                    setAmount(formatted);
                                    paymentForm.setFieldsValue({
                                        Amount: formatted
                                    });
                                }}
                                onBlur={e => {
                                    const formatted = amountFormater(e);
                                    setAmount(formatted);
                                    paymentForm.setFieldsValue({
                                        Amount: formatted
                                    });
                                    paymentForm.validateFields(['Amount']);
                                }}
                                value={amount}
                                id={"paymentAmount"}
                            />
                            <span style={{
                                display: 'block',
                                position: 'relative',
                                top: '4px'
                            }}>
                                &nbsp;AUD
                            </span>
                        </div>
                    </Form.Item>
                    <Form.Item noStyle>
                        <GlobirdDivider />
                    </Form.Item>
                    <Form.Item
                        label="Payment Method"
                        name="PaymentMethod"
                        style={{ marginBottom: '0' }}>
                        <Radio.Group>
                            <Radio value={DirectPaymentMethod.CreditCard}>CreditCard</Radio>
                            {/* <Radio value={PaymentMethod.Paypal}>Paypal</Radio> */}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item noStyle>
                        <GlobirdDivider />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prv, crt) =>
                            prv.PaymentMethod !== crt.PaymentMethod}>
                        {({ getFieldValue }) => {
                            if (getFieldValue('PaymentMethod') === DirectPaymentMethod.CreditCard) {
                                return <CreditCardFields ccForm={paymentForm} cvnRequired={true} />;
                            }
                            return <></>
                        }}
                    </Form.Item>
                    <Form.Item
                    label="Direct Debit">
                        <Checkbox onChange={e => setSetupDD(e.target.checked)}>
                            <span style={{ fontSize: '11px' }}>
                                Use above account details for direct debit.
                            </span>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        style={setupDD ? {} : {display: 'none'}}
                        name="AgreeTandC"
                        label="Direct Debit Terms &#38; Conditions"
                        valuePropName="checked"
                        rules = {setupDD ? [generateCheckBoxRule(tAndCmsg)] : undefined}>
                        <Checkbox>
                            <span style={{ fontSize: '11px' }}>
                                I have read and agreed with the
                                <a className="globird-link" target="_blank" href={links.terms.directDebitTerms} rel="noreferrer">
                                    &nbsp;Direct Debit service terms and conditions
                                </a>.
                            </span>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Bwtt
                            disabled={preventSubmit}
                            type="primary"
                            htmlType="submit"
                            useAntdBtn={true}
                            size="large"
                            btnStyle={{ display: 'block', margin: 'auto', minWidth: '150px' }}
                            disableMessage={"Please check your card details before proceeding."}
                            btnNode="Pay"
                            id={"pay"}
                        />
                    </Form.Item>
                </Form>
            </div>}
            {paymentResult === false && <ActionResultPanel status="error">
                Sorry, we are unable to process your payment at this moment, please try again later or contact our customer service for help.
            </ActionResultPanel>}
            {<Modal
                visible={paymentResult === true}
                onCancel={() => setPaymentResult(null)}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable={false}
                maskClosable={false}
                keyboard={false}
                footer={[
                    <Button
                        key="payment-history"
                        className={classnames("globird-button-primary", styles["payment-result-button"])}
                        onClick={() => resetPaymentResultAndRedirect('/paymenthistory')}>
                            Payment History
                    </Button>,
                    <Button
                        key="ok"
                        className={classnames("globird-button-primary", styles["payment-result-button"])}
                        onClick={() => resetPaymentResultAndRedirect('/')}>
                            OK
                    </Button>
                ]}
                centered>
                <Result
                    status="success"
                    title={setupDD ? "Thank you, your payment has been successfully processed. Your direct debit details have been successfully saved." : "Thank you, your payment has been successfully processed."} />
            </Modal>}
        </div >
    );
}

export default PayBill;