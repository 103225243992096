import { useEffect, useState } from 'react';
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Menu, Tooltip } from 'antd';
import { selectCurrentAccount } from 'store/userSlice';
import { useSelector } from 'react-redux';
import menuOptions from '../MenuOptions';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import InfoCircleFilled from '@ant-design/icons/lib/icons/InfoCircleFilled';
// import SelfReadForm from './SelfReadForm';
import SelfReadForm from './NewSelfReadForm';
import SelfReadList from './SelfReadList';

const SelfRead = () => {
    let { pathname } = useLocation();

    const currentAccount = useSelector(selectCurrentAccount);

    const navigate = useNavigate();

    pathname = pathname.endsWith('/')
        ? pathname.substring(0, pathname.length - 1)
        : pathname;

    const currentMenu = pathname.toLowerCase() === `/selfread/newupload`
        ? 'new'
        : 'list';

    const [menu, setMenu] = useState<'new' | 'list'>(currentMenu);

    const [smallScreen, setSmallScreen] = useState<boolean>(window.innerWidth < 650);

    useEffect(() => {
        const handler = () => {
            if (window.innerWidth < 650) {
                setSmallScreen(true);
            }
            else {
                setSmallScreen(false);
            }
        };

        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }, []);

    const [requireRefreshList, setRequireRefreshList] = useState<boolean>(false);

    const onMenuChange = ({ key }: { key: string }) => {

        setMenu(key as any);

        if (key === 'new') {
            navigate(`/selfread/newupload`);
        }

        if (key === 'list') {
            navigate(`/selfread`);
        }
    };

    currentMenu !== menu && onMenuChange({ key: currentMenu });

    const onFormFinish = () => {
        setRequireRefreshList(true);
        onMenuChange({ key: 'list' });
    };

    const onListRefreshed = () => setRequireRefreshList(false);

    return <div
        className="form-container"
        style={{
            width: smallScreen ? '95%' : '80%',
            maxWidth: '1000px'
        }}>
        <div className="globird-menu-option">
            <p className="globird-menu-label">Show me: </p>
            <GlobirdSelector
                id="directDebitMenuSelector"
                options={menuOptions}
                defaultOption={menuOptions[1]}
                onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
        </div>
        <Menu
            style={{ backgroundColor: 'rgba(249,249,249,1)' }}
            onClick={onMenuChange}
            selectedKeys={[menu]}
            mode="horizontal">
            <Menu.Item key="list">
                Previous Uploads
            </Menu.Item>
            <Menu.Item
                key="new"
                icon={<PlusCircleOutlined />}>
                <span>New Upload
                    {/* &nbsp;
                    <Tooltip
                        title={<span style={{ fontWeight: 'normal' }}>
                            Learn about uploading a self read here:<br />
                            <a href="https://www.globirdenergy.com.au/upload-self-read/">
                                https://www.globirdenergy.com.au/upload-self-read/
                            </a>
                        </span>}
                        overlayStyle={{
                            minWidth: '300px',
                            fontWeight: 'bold'
                        }}
                    >
                        <InfoCircleFilled
                            style={{
                                color: 'rgb(197,208,222)',
                                position: 'relative',
                                top: '1px'
                            }} />
                    </Tooltip> */}
                </span>
            </Menu.Item>
        </Menu>
        <Routes>
            <Route path="newupload" element={<SelfReadForm
                currentAccount={currentAccount}
                onFinish={onFormFinish}
                smallScreen={smallScreen} />} />
            <Route path="/" element={<SelfReadList
                currentAccount={currentAccount}
                forceRefresh={requireRefreshList}
                onListRefreshed={onListRefreshed} />} />
        </Routes>
    </div >;
}

export default SelfRead;