import { CSSProperties, FC } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const globirdIconParams: any = {
    prefix: 'fac',
    iconName: 'globird',
    icon: [
        1434, // width
        1024,// height
        [],
        'fffa',
        ['m995.839,335.7c161.684,-56.149 345.877,-71.93 512.81,-31.6c227.415,55.057 354.825,223.61 531.726,353.028c62.843,45.828 332.88,141.676 266.727,-25.785c-57.875,-146.467 -195.359,-271.411 -320.127,-360.355c-195.748,-139.488 -401.746,-187.188 -642.581,-189.935c-188.772,-2.113 -356.586,55.586 -524.118,122.408c-162.776,64.85 -848.508,122.514 -808.598,-203.461c-67.174,549.2 152,1203.4 747.094,1353.533c192.119,48.47 361.94,60.764 508.689,-82.709c284.445,-278.1 7.151,-774.253 -271.623,-935.127', 'm1498.961941,473.780986a69.589,69.589 0 1 1 -11.2,-97.715a69.619,69.619 0 0 1 11.2,97.715z']
    ]
};

library.add(globirdIconParams);

const icon: FC<{ style?: CSSProperties }> = ({ style }) =>
    <FontAwesomeIcon
        icon={['fac', 'globird'] as any}
        style={{
            '--fa-primary-opacity': 1,
            '--fa-secondary-opacity': 1,
            ...style
        } as any} />;


export default icon;