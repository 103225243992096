import './GlobirdSelector.css';
import { CSSProperties, Fragment, useRef, useState } from 'react';
import GlobirdSelectorOption from 'data/GlobirdSelectorOption';
import { delay, isMobileAndTablet } from 'utilities/Utility';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GlobirdSelector = <TValue,>(props: {
    id: string,
    defaultOption?: GlobirdSelectorOption<TValue>,
    options: GlobirdSelectorOption<TValue>[],
    style?: CSSProperties,
    onSelected?: (selectedOption: GlobirdSelectorOption<TValue>) => void,
    value?: TValue
}) => {

    const collapseRef = useRef<HTMLDivElement>(null);

    let [currentSelectedOption, setCurrentSelectedOption] =
        useState(props.defaultOption
            ? props.defaultOption
            : props.options[0]);
    
    if (props.defaultOption && props.defaultOption != currentSelectedOption) {
        setCurrentSelectedOption(props.defaultOption)
    }

    let optionIndex = -1;

    props.value !== undefined
        && (optionIndex = props.options.findIndex(o => o.value === props.value)) >= 0
        && (currentSelectedOption = props.options[optionIndex]);

    const controlDropdown = async (show: boolean) => {

        if (!collapseRef?.current) {
            return;
        }

        const collapseDiv = collapseRef.current;

        if (show) {

            collapseDiv.style.display = 'block';
            let targetHeight = collapseDiv.clientHeight;
            collapseDiv.style.height = '0px';

            await delay(1);

            collapseDiv.style.height = targetHeight + 'px';
        }
        else {
            collapseDiv.style.height = '0px';

            await delay(200);

            collapseDiv.style.display = 'none';
            collapseDiv.style.height = '';
        }
    }

    const chooseOption = (selectedOption: GlobirdSelectorOption<TValue>) => {

        setCurrentSelectedOption(selectedOption);

        props.onSelected?.(selectedOption);

        controlDropdown(false);
    }

    const holderOption = () => currentSelectedOption
        ? currentSelectedOption
        : props.options.length > 0
            ? props.options[0]
            : null

    return (
        <div className="globird-selector-container">
            <div
                className="globird-selector"
                onClick={() => isMobileAndTablet() && controlDropdown(true)}
                onMouseEnter={() => !isMobileAndTablet() && controlDropdown(true)}
                onMouseLeave={() => controlDropdown(false)}
                style={props.style}>
                <div
                    className="selection"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls={props.id}>
                    <p className='ellipsis' title={holderOption()?.name}>
                        { holderOption()
                            ? holderOption()?.cutName || holderOption()?.name
                            : "Choose option"}
                    </p>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ fontSize: "10px", margin: "5px 0 0 10px" }} />
                </div>
                <div className="collapse-option" id={props.id} ref={collapseRef}>
                    <div className="option-list">
                        {
                            props.options.map((el, i) =>
                                currentSelectedOption?.value === el.value
                                    && props.options.length !== 1
                                    ? <Fragment key={`${props.id}-${i}`}></Fragment>
                                    : <div
                                        className="option ellipsis"
                                        key={`${props.id}-${i}`}
                                        title={el.name}
                                        onClick={() => chooseOption(el)}>
                                        {el.cutName || el.name}
                                    </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GlobirdSelector;