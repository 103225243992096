import styles from './UpdateJointOwnerForm.module.css'
import {
    useEffect,
    useMemo,
    useState
} from "react";
import { useSelector } from 'react-redux';
import { selectCurrentAccount } from 'store/userSlice';
import {
    Divider,
    Form,
    Input,
    notification,
    Select,
} from 'antd'
import {
    SecondaryContactDto,
    DeliveryType
} from 'data/CustomerPortalTypes';
import {
    getJointOwners, updateJointOwner
} from 'services/RequestDataService';
import {
    dtoDateToFormDate,
    formatDisplayDate,
    SuburbNameRule,
    generateRequiredRule as genRqdRule,
    AuMobileRule,
    EmailRule,
    PostcodeRule,
    generateLengthRule as genLenRule,
    VarcharRule,
    AllContactTitles,
    AllDeliveryTypes,
    AllStateCodes
} from 'utilities/Utility';
import classNames from 'classnames';
import { FieldData } from 'rc-field-form/lib/interface';
import ButtonWithToolTip from 'components/Shared/ButtonWithToolTip';
import FilteredInput from 'components/Shared/FilteredInput';

const { Item } = Form;

const DeliveryTypeDisplayText: Record<DeliveryType, string> = {
    [DeliveryType.Email]: 'Email',
    [DeliveryType.PostalMail]: 'Postal Mail'
};

const UpdateJointOwnerForm = () => {
    const currentAccount = useSelector(selectCurrentAccount);

    const [jointOwners, setJointOwners] = useState<SecondaryContactDto[] | undefined>();
    const [selectedJointOwner, setSelectedJointOwner] = useState<SecondaryContactDto | undefined>();
    const [showJointOwnerDropDown, setShowJointOwnerDropDown] = useState<boolean>(false);
    const [formValueUpdated, setFormValueUpdated] = useState<boolean>(false);
    const [formErrored, setFormErrored] = useState<boolean>(false);

    const [form] = Form.useForm<SecondaryContactDto>();

    const saveBtnDisableMsg = () => {
        if (formErrored) {
            return 'Please fix all errors before saving.';
        }

        if (!formValueUpdated) {
            return 'Joint owner contact details unchanged.';
        }

        return '';
    };

    const refreshJointOwnerInfo = async () => {
        if (!currentAccount) {
            return;
        }

        const result =
            await getJointOwners(currentAccount.accountId);
        
        if (result?.success !== true || !result.data) {
            return;
        }

        setJointOwners(result.data);

        if(result.data.length === 0) {
            setSelectedJointOwner(undefined);
            return;
        }

        if (!selectedJointOwner) {
            setSelectedJointOwner(result.data[0]);
        }

        if (result.data.length > 1) {
            setShowJointOwnerDropDown(true);
        }
    }

    const resetForm = () => {
        setFormValueUpdated(false);
        setFormErrored(false);
    }

    useEffect(() => {
        refreshJointOwnerInfo();
        resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount]);

    useEffect(() => {
        if (!selectedJointOwner) {
            return;
        }

        const formData = {
            ...selectedJointOwner,
            dateOfBirth: dtoDateToFormDate(selectedJointOwner.dateOfBirth)
        };

        form.setFieldsValue(formData);
        setFormValueUpdated(false);
    }, [selectedJointOwner, form]);

    if (!currentAccount || !jointOwners || !selectedJointOwner) {
        return <div className={classNames(styles['no-joint-owner-text'])}>
            Your account does not have any joint owners.
        </div>;
    }

    const onFinish = async (values: SecondaryContactDto) => {
        if (!currentAccount) {
            return;
        }

        // Add contactId to form values
        const updatedValues = {
            ...values,
            contactId: selectedJointOwner.contactId
        }

        const result = await updateJointOwner(currentAccount.accountId, updatedValues);

        if (result?.success !== true) {
            return;
        }

        notification.success({
            message: "Success",
            description: "Successfully updated account summary."
        });

        resetForm();

        await refreshJointOwnerInfo();
    }

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
        if (!selectedJointOwner) {
            return;
        }

        let formChanged = false;
        let formErrored = false;

        for (let field of allFields) {

            const changePropName = (field.name as unknown as string[])[0] as keyof SecondaryContactDto;

            const currentValue = selectedJointOwner[changePropName];

            const fieldValue = field.value as typeof currentValue;

            if (changePropName === 'email') {
                const fieldValueNorm = (fieldValue as string)?.toLowerCase();
                const currentValueNorm = (currentValue as string)?.toLowerCase();

                if (fieldValueNorm !== currentValueNorm) {
                    formChanged = true;
                }
            } else {
                if (fieldValue !== currentValue) {
                    formChanged = true;
                }
            }
            
            if (field.errors?.length
                && field.errors?.length > 0) {
                    formErrored = true;
            }
        }

        setFormValueUpdated(formChanged);
        setFormErrored(formErrored);
    }

    return (
        <div className="field-container" style={{ width: '90%' }}>
            <Form
                form={form}
                name="accountSummaryForm"
                layout="vertical"
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}>
                <div className="globird-row">
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="contactId"
                            style={{ marginBottom: '0px'}}
                            label={<label className="globird-form-field-label">Joint Owner</label>}
                            hidden={!showJointOwnerDropDown}>
                            <Select
                                id={"contactId"}
                                onSelect={(x: number) => setSelectedJointOwner(jointOwners.filter(y => y.contactId === x)[0])}>
                                {jointOwners.map(k =>
                                    <Select.Option value={k.contactId} key={`jointOwner-contactId-${k.contactId}`} data-auto={"contact-option"}>{k.firstName} {k.lastName}</Select.Option>)}
                            </Select>
                        </Item>
                    </div>
                </div>

                {showJointOwnerDropDown ? <Divider /> : null}

                <div className="globird-row">
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="firstName"
                            style={{ marginBottom: '0px'}}
                            label={<label className="globird-form-field-label">First Name</label>}>
                            <p>{selectedJointOwner.firstName}</p>
                        </Item>
                    </div>
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="lastName"
                            style={{ marginBottom: '0px'}}
                            label={<label className="globird-form-field-label">Last Name</label>}>
                            <p>{selectedJointOwner.lastName}</p>
                        </Item>
                    </div>
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="dateOfBirth"
                            style={{ marginBottom: '0px'}}
                            label={<label className="globird-form-field-label">Date of birth</label>}>
                            <p>{formatDisplayDate(selectedJointOwner.dateOfBirth)}</p>
                        </Item>
                    </div>
                </div>

                <Divider />

                <div className="globird-row">
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="deliveryType"
                            label={<label className="globird-form-field-label">
                                Correspondence Method
                            </label>}
                            rules={[genRqdRule('Bill delivery method')]}>
                            <Select
                                id={"deliverymethod"}
                                defaultValue={selectedJointOwner.deliveryType}>
                                {AllDeliveryTypes.map(k =>
                                    <Select.Option
                                        value={k}
                                        key={`acctSumm-deliveryType-${k}`}>
                                        {DeliveryTypeDisplayText[k] ?? k}
                                    </Select.Option>)}
                            </Select>
                        </Item>
                        <Item
                            name="title"
                            label={<label className="globird-form-field-label">Title</label>}
                            rules={[genRqdRule('Title')]}>
                            <Select
                                id={"title"}>
                                {AllContactTitles.map(k =>
                                    <Select.Option value={k} key={`acctSumm-contactTitle-${k}`} data-auto={"title-option"}>{k}</Select.Option>)}
                            </Select>
                        </Item>
                        <Item
                            name="phoneNumber"
                            label={<label className="globird-form-field-label">Mobile Number</label>}
                            rules={[genRqdRule('Mobile number'), AuMobileRule]}>
                            <Input
                                allowClear
                                className={classNames('input-with-button-hidden')}
                                id={"mobilenumber"}
                            />
                        </Item>
                        <Item
                            name="email"
                            label={<label className="globird-form-field-label">Email</label>}
                            rules={[genRqdRule('Email address'), EmailRule]}>
                            <FilteredInput
                                allowClear
                                className={classNames('input-with-button-hidden')}
                                id="email"
                                filterExpression={/\s/g} />
                        </Item>
                        <Item
                            name="addressLine1"
                            label={<label className="globird-form-field-label">Postal Address Line 1</label>}
                            rules={[genRqdRule('Address line 1'), genLenRule('Address line 1', 100), VarcharRule]}>
                            <Input allowClear id={"postalAddress1"} />
                        </Item>
                        <Item
                            name="addressLine2"
                            label={<label className="globird-form-field-label">Postal Address Line 2</label>}
                            rules={[genLenRule('Address line 2', 100), VarcharRule]}>
                            <Input allowClear id={"postalAddress2"} />
                        </Item>
                    </div>
                </div>
                <div className="globird-row">
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="suburb"
                            label={<label className="globird-form-field-label">Suburb</label>}
                            rules={[genRqdRule('Suburb'), SuburbNameRule, genLenRule('Suburb', 50), VarcharRule]}>
                            <Input allowClear id={"suburb"}/>
                        </Item>
                    </div>
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="stateCode"
                            label={<label className="globird-form-field-label">State Code</label>}
                            rules={[genRqdRule('State code')]}>
                            <Select id={"state"}>
                                {AllStateCodes.map(k =>
                                    <Select.Option value={k} key={`acctSumm-stateCode-${k}`} data-auto={"state-option"}>{k}</Select.Option>)}
                            </Select>
                        </Item>
                    </div>
                    <div className={
                        classNames(
                            'globird-col',
                            styles['joint-owner-col-block'])
                    }>
                        <Item
                            name="postcode"
                            label={<label className="globird-form-field-label">Post Code</label>}
                            rules={[genRqdRule('Post code'), PostcodeRule]}>
                            <Input allowClear id={"postcode"} />
                        </Item>
                    </div>
                </div>
                <Item>
                    <ButtonWithToolTip
                        disabled={!formValueUpdated || formErrored}
                        disableMessage={saveBtnDisableMsg()}
                        componentClassName={['form-save-button']}
                        btnNode="Save"
                    />
                </Item>
            </Form>
        </div>
    );
}

export default UpdateJointOwnerForm;