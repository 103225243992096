import Cookie from 'js-cookie';
import authService from 'services/AuthService';
import {selectUser, setUser} from 'store/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import * as queryString from 'query-string';
import {Checkbox, Form, Input, Popover, Spin} from 'antd';
import {CommonPasswordValidator} from 'utilities/Validators';
import {EmailRule, generateRequiredRule as genRqdRule, removeFromQuery, showErrorToast} from 'utilities/Utility';
import {useEffect, useRef, useState} from 'react';
import {AuthCookieName} from 'data/CustomerPortalTypes';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FilteredInput from 'components/Shared/FilteredInput';
import Captcha from 'components/Shared/Captcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {GlobirdOrPip} from "components/Shared/GlobirdOr";

const parseRedirectUrl = (query: queryString.ParsedQuery<string>) => {
    const returnPath = query['returnPath'];

    if (!returnPath || typeof returnPath === 'object') {
        return null;
    }

    return `${returnPath}${removeFromQuery(query, ['returnPath'])}`;
};

const { Item } = Form;

let submitted = false;

const loginFailedPhrase = 'Sorry, we are unable to process your login request. Please try again later.';

let tmpLoginDto: any = {};

export const Login = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectUser);
    const navigate = useNavigate()

    const { search: query } = useLocation();
    const redirectUrl = parseRedirectUrl(queryString.parse(query));

    const [showCaptcha, setShowCaptcha] = useState(false);
    const [requireCaptcha, setRequireCaptcha] = useState(false);
    const captchaRef = useRef<HCaptcha>(null);

    const postMessageToReferrer = (data: object) => {
        window.opener?.postMessage(data, "*");
        window.close();
        return null;
    }

    useEffect(() => {
        const getAccessToken = async () => {
            const result = await authService.getAccessToken();
            const { data, success } = result || {};

            if (!success || !data) {
                showErrorToast(loginFailedPhrase);
                return;
            }

            postMessageToReferrer({
                token: data.accessToken,
            });
        };

        const searchParams = new URLSearchParams(query);
        const referrer = searchParams.get("referrer");
        if (currentUser) {
            if (referrer !== "globird-oss") {
                return;
            }

            getAccessToken().catch(() => {
                throw new Error();
            });
        }

        let lastLoginCookie = Cookie.get(AuthCookieName);
        let pendingDetectResult = false;

        const detectLogin = setInterval(async () => {

            if (pendingDetectResult) {
                return;
            }

            pendingDetectResult = true;

            try {
                const newCookie = Cookie.get(AuthCookieName);

                if (!newCookie || newCookie === lastLoginCookie) {
                    return;
                }

                lastLoginCookie = newCookie;

                const user = await authService.authenticate();

                if (user) {
                    clearInterval(detectLogin);
                    dispatch(setUser(user));
                }
            } finally {
                pendingDetectResult = false;
            }

        }, 3000);

        return () => clearInterval(detectLogin);
    }, [currentUser, dispatch, query]);


    if (currentUser) {
        return <Navigate to={redirectUrl ?? "/dashboard"} replace />;
    }
    
    const loginLocal = async (
        email: string,
        password: string,
        rememberMe: boolean,
        hCaptchaToken: string | null) => {

        try {
            const authResult = await authService.login(
                email,
                password,
                rememberMe,
                hCaptchaToken);
            
            const { data, success } = authResult || {};
            const { requireRetryCaptCha, requireHCaptcha, isLoginSucceeded, alreadyLoggedIn } = data || {};

            if (!data || !success) {
                throw new Error();
            }

            if (isLoginSucceeded || alreadyLoggedIn) {

                const user = await authService.authenticate();

                if (!user) {
                    throw new Error();
                }

                dispatch(setUser(user));

                return;
            }

            if (requireRetryCaptCha) {
                tmpLoginDto = { email, password, rememberMe };

                setShowCaptcha(true);

                return;
            }

            if (requireHCaptcha) {
                setRequireCaptcha(true);
            }

            form.setFields([
                {
                    name: 'email',
                    errors: ['']
                },
                {
                    name: 'password',
                    errors: ['Please check your Email address and password.']
                }]);
        } catch {
            showErrorToast(loginFailedPhrase, 'Error');
        }
    }

    const onFinish = async (value: any) => {

        if (submitted) {
            return;
        }

        submitted = true;

        try {
            if (requireCaptcha) {

                setShowCaptcha(true);
                setRequireCaptcha(false);
                tmpLoginDto = { ...value };

                return;
            }

            await loginLocal(value.email, value.password, value.rememberMe, null);
        }
        finally {
            submitted = false;
        }
    };

    const onCaptchaVerified = (token: string) => {

        setShowCaptcha(false);
        setRequireCaptcha(false);
        captchaRef?.current?.resetCaptcha();

        loginLocal(tmpLoginDto.email, tmpLoginDto.password, tmpLoginDto.rememberMe, token);
    }

    return (
        <div className="login">
            <Spin
                spinning={showCaptcha}
                wrapperClassName="form-spin-wrapper">
                <div className="globird-form" style={{ maxWidth: 'none' }}>
                    <Form form={form} name="login" layout="vertical" onFinish={onFinish} className="login-form">
                        <div className="globird-form-title">
                            <FontAwesomeIcon
                                icon={faKey}
                                style={{ marginRight: '10px', transform: 'scaleX(-1)' }} />
                            SIGN IN to My Account with the email linked to your GloBird account
                        </div>
                        <Item
                            name="email"
                            label={<label className="globird-form-field-label">Email address</label>}
                            rules={[
                                genRqdRule('Email address'),
                                EmailRule
                            ]}>
                            <FilteredInput
                                allowClear
                                style={{ width: "100%" }}
                                filterExpression={/\s/g} />
                        </Item>
                        <Item
                            name="password"
                            label={<label className="globird-form-field-label">Password</label>}
                            rules={[
                                genRqdRule('Password'),
                                CommonPasswordValidator() // be careful if we change the password requirement in the future
                            ]}>
                            <Input.Password allowClear style={{ width: "100%" }} />
                        </Item>
                        <Item>
                            <Popover
                                visible={showCaptcha}
                                content={<Captcha
                                    ref={captchaRef}
                                    onVerify={onCaptchaVerified} />}>
                                <button
                                    className="globird-button-primary"
                                    style={{ width: '100%' }}>
                                    Sign in
                                </button>
                            </Popover>
                        </Item>
                        <Item name="rememberMe" valuePropName="checked" style={{ textAlign: 'center' }}>
                            <Checkbox>Remember me on this device</Checkbox>
                        </Item>
                    </Form>
                    <GlobirdOrPip/>
                    <button
                        onClick={() => navigate('/otp')}
                        className="globird-button-primary"
                        style={{width: '100%', marginBottom: '10px'}}>
                        Sign in with one-time code
                    </button>
                </div>
            </Spin>
            <div className="router-block">
                <p>First time visiting GloBird My Account?&nbsp;
                    <Link className="globird-link" to="/register">Click here</Link> to register.
                </p>
                <p>Forgotten your password?&nbsp;
                    <Link className="globird-link" to="/resetpasswordrequest">Click here</Link> to reset.
                </p>
            </div>
        </div>
    );
}

export default Login;