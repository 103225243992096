import { FC, PropsWithChildren, ReactElement, useEffect } from "react";
import { Navigate, useLocation, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, unsetUser } from 'store/userSlice';
import * as queryString from 'query-string';
import { AuthCookieName } from "data/CustomerPortalTypes";
import Cookie from 'js-cookie';
import { DashboardDataCache } from 'components/Dashboard';
import { clearRecord } from "utilities/Utility";

type CustomerPortalRouterProp = {
    loginPath: string,
    returnPathParamName: string
}

const CustomerPortalRouter: FC<PropsWithChildren<CustomerPortalRouterProp>> = (prop) => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {

        if (!user) {
            return;
        }

        const checkLogoutInterval = setInterval(() => {
            if (Cookie.get(AuthCookieName)
                || user?.isImpersonatedUser) {

                return;
            }

            dispatch(unsetUser());
            clearRecord(DashboardDataCache);
            clearInterval(checkLogoutInterval);

        }, 3000)

        return () => clearInterval(checkLogoutInterval);
    }, [user, dispatch]);

    const location = useLocation();

    if (!prop?.children) {
        return <></>;
    }

    let children: ReactElement<PropsWithChildren<{}>, FC<PropsWithChildren<{}>>>[];

    if (!Array.isArray(prop.children)) {
        children = [prop.children] as ReactElement<PropsWithChildren<{}>, FC<PropsWithChildren<{}>>>[];
    }
    else {
        children = prop.children as ReactElement<PropsWithChildren<{}>, FC<PropsWithChildren<{}>>>[];
    }

    const anyRoutes = children
        ?.filter(c => c.type?.displayName === 'AnyRoutes')
        ?.[0];

    const protectedRoutes = children
        ?.filter(c => c.type?.displayName === 'ProtectedRoutes')
        ?.[0];

    const anonymousRoutes = children
        ?.filter(c => c.type?.displayName === 'AnonymousRoutes')
        ?.[0];

    let searchString: string;

    if (location.pathname !== '/' && location.pathname !== prop.loginPath) {
        const query = queryString.parse(location.search);
        query[prop.returnPathParamName] = location.pathname;
        searchString = `?${queryString.stringify(query)}`;
    }
    else {
        searchString = '';
    }

    // console.log(authenticated)

    return <Routes>
        {anyRoutes?.props?.children}
        {!user && anonymousRoutes?.props?.children}
        {user && protectedRoutes?.props?.children}
        {!user && <Route path="*" element={<Navigate
            to={{
                pathname: prop.loginPath,
                search: searchString
            }} replace />} />}
    </Routes>;
};

const ProtectedRoutes: FC = () => (<></>);
const AnonymousRoutes: FC = () => (<></>);
const AnyRoutes: FC = () => (<></>);

ProtectedRoutes.displayName = 'ProtectedRoutes';
AnonymousRoutes.displayName = 'AnonymousRoutes';
AnyRoutes.displayName = 'AnyRoutes';

export default CustomerPortalRouter;

export {
    ProtectedRoutes,
    AnyRoutes,
    AnonymousRoutes
};