import ActionResultPanel from 'components/ActionResultPanel';
import { Form, Input } from 'antd';
import { clearRecord, formatJsonDate } from 'utilities/Utility';
import { linkAccount } from 'services/RequestDataService';
import { useState } from 'react';
import { LinkAccount } from 'data/CustomerPortalTypes';
import DatePicker from 'components/Shared/DatePicker';
import SectionMenu from '../SectionMenu';
import { refreshUser } from 'store/userSlice';
import { useDispatch } from 'react-redux';
import { DashboardDataCache } from 'components/Dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons';

const { Item } = Form;

const dobInputId = 'linkAccountDobInput';

let submitted = false;

const AccountLink = () => {
    const [form] = Form.useForm<LinkAccount>();
    const [linkSucceeded, setLinkSucceeded] = useState<boolean | null>(null);
    const [preventSubmit, setPreventSubmit] = useState<boolean>(false);

    const dispatch = useDispatch();

    const onSubmit: (event?: React.MouseEvent<Element, MouseEvent>) => void = async (e) => {
        e?.preventDefault();

        if (submitted || preventSubmit) {
            return;
        }

        try {
            submitted = true;

            const formValues = await form.validateFields();

            const actResult = await linkAccount({
                ...formValues,
                DateOfBirth: formatJsonDate(formValues.DateOfBirth) // dateOfBirth should not be nullable here
            });

            if (actResult?.data) {
                setLinkSucceeded(true);

                clearRecord(DashboardDataCache);
                dispatch(refreshUser());
            }
            else if (actResult?.data === false) {
                setPreventSubmit(true);

                form.setFields(
                    [
                        {
                            name: 'AccountNumber',
                            errors: ['Please check the account details.']
                        },
                        {
                            name: 'DateOfBirth',
                            errors: ['']
                        },
                        {
                            name: 'FirstName',
                            errors: ['']
                        },
                        {
                            name: 'LastName',
                            errors: ['']
                        }
                    ]
                );
            }
            else {
                setLinkSucceeded(false);
            }
        } finally {
            submitted = false;
        }
    }


    return (
        <div className="form-container">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <SectionMenu
                    id="accntLinkMenuSelector"
                    defaultIndex={0} />
            </div>
            <div className="field-container">
                {
                    linkSucceeded === null && <Form
                        onFieldsChange={() => setPreventSubmit(false)}
                        form={form}
                        initialValues={{
                            AccountNumber: '',
                            DateOfBirth: null,
                            FirstName: '',
                            LastName: ''
                        }}
                        onKeyDown={e => {
                            if (e.code === 'Enter') {
                                e.preventDefault();
                                onSubmit();
                            }
                        }}
                        name="linkAccountForm"
                        layout="vertical"
                    // validateTrigger="onBlur"
                    >
                        <div className="globird-form-title">
                            <FontAwesomeIcon
                                icon={faKey}
                                style={{ marginRight: '10px', transform: 'scaleX(-1)' }} />Link Your GloBird Account
                        </div>
                        <Item
                            name="AccountNumber"
                            label={<label className="globird-form-field-label">Account Number</label>}
                            rules={[
                                { required: true, message: 'Account number is required.' },
                                { pattern: /^\d{8}$/, message: 'Account number contains numeric characters only with length of 8.' }]}
                            tooltip="You can find your Account Number in your Welcome Pack, as well as on your bills.">
                            <Input
                                type="text"
                                allowClear
                                style={{ width: "100%" }} />
                        </Item>

                        <Item
                            name="FirstName"
                            label={<label className="globird-form-field-label">First name</label>}
                            rules={[{
                                required: true,
                                message: 'First name is required.'
                            }]}>
                            <Input
                                type="text"
                                allowClear
                                onBlur={(e) => {
                                    const { value } = e.target;
                                    form.setFieldsValue({ FirstName: value.trim() });
                                    form.validateFields(['FirstName']);
                                }}
                                style={{ width: "100%" }} />
                        </Item>

                        <Item
                            name="LastName"
                            label={<label className="globird-form-field-label">Last name</label>}
                            rules={[{
                                required: true,
                                message: 'Last name is required.'
                            }]}>
                            <Input
                                type="text"
                                allowClear
                                onBlur={(e) => {
                                    const { value } = e.target;
                                    form.setFieldsValue({ LastName: value.trim() });
                                    form.validateFields(['LastName']);
                                }}
                                style={{ width: "100%" }} />
                        </Item>

                        <Item
                            name="DateOfBirth"
                            label={<label className="globird-form-field-label">Date of birth</label>}
                            rules={[{
                                required: true,
                                message: 'Date of birth is required.'
                            }]}>
                            <DatePicker
                                style={{ width: "100%" }}
                                allowClear
                                id={dobInputId}
                                picker={'date'}
                            />
                        </Item>

                        <Item>
                            <button
                                className="globird-button-primary"
                                style={{ width: '100%' }}
                                onClick={onSubmit}
                                disabled={preventSubmit}>
                                Link GloBird Account
                            </button>
                        </Item>
                    </Form>
                }
                {
                    linkSucceeded === true && <ActionResultPanel status="success">
                        Congratulations!
                        <br />
                        <span>Your GloBird account </span>
                        <span style={{ fontWeight: 'bold' }}>
                            {form.getFieldValue('AccountNumber')}
                        </span> is now linked.
                    </ActionResultPanel>
                }
                {
                    linkSucceeded === false && <ActionResultPanel status="error">
                        Oops! Something went wrong, please try again later or contact our customer service for help.
                    </ActionResultPanel>
                }
            </div>
        </div >
    );
}

export default AccountLink;