import SectionMenu from '../SectionMenu';
import styles from './Concession.module.css'
import { Menu } from 'antd';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentAccount } from 'store/userSlice';
import ConcessionForm from './ConcessionForm';
import ConcessionHistory from './ConcessionHistory';

const Concession = () => {
    let { pathname } = useLocation();

    const currentAccount = useSelector(selectCurrentAccount);

    const navigate = useNavigate();

    pathname = pathname.endsWith('/')
        ? pathname.substring(0, pathname.length - 1)
        : pathname;
    
    const [menu, setMenu] = useState<'manage' | 'history'>(
        pathname.toLowerCase() === `/concession/history`
            ? 'history'
            : 'manage'
    );
    
    const onTabChange = ({ key } : { key: string }) => {
        setMenu(key as any);

        if (key === 'manage') {
            navigate('/concession');
        }

        if (key === 'history') {
            navigate('/concession/history');
        }
    }

    return (
        <div className='form-container'
            style={{
                maxWidth: '60%'
            }}>
            <div className='globird-menu-option'>
                <p className='globird-menu-label'>Show me: </p>
                <SectionMenu
                    id='concessionMenuSelector'
                    defaultIndex={6} />
            </div>
            <Menu 
                mode='horizontal'
                className={styles['concession-tab-menu']}
                onClick={onTabChange}
                selectedKeys={[menu]}>
                <Menu.Item key='manage'>
                    Manage Concession
                </Menu.Item>
                <Menu.Item key='history'>
                    History
                </Menu.Item>
            </Menu>
            <Routes>
                <Route path='/' element={<ConcessionForm
                    currentAccount={currentAccount}/>}/>
                <Route path='history' element={<ConcessionHistory
                    currentAccount={currentAccount}/>}/>
            </Routes>
        </div>
    );
}

export default Concession;