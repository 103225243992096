import {Form, Popover, Button, DatePicker} from 'antd';
import {Link} from 'react-router-dom';
import {
    EmailRule,
    formatJsonDate,
    generateRequiredRule,
    showErrorToast,
    useCountdown,
    workoutCountDownTimeStr,
} from 'utilities/Utility';
import FilteredInput from 'components/Shared/FilteredInput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import {useRef, useState} from 'react';
import authService, {otpLogin as otpLoginService, sendOtpCode} from 'services/AuthService';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Captcha from 'components/Shared/Captcha';
import {setUser} from 'store/userSlice';
import {useDispatch} from 'react-redux';
import { OtpLoginRequest } from 'data/CustomerPortalTypes';
import moment from 'moment';

const {Item} = Form;

let sendOtpCaptchaToken: string|null = null;
const LoginOneTimePassword = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [otpResetCountdownSeconds, startOtpResetCountdownSeconds] = useCountdown('otp-login', 60);
    const [email, setEmail] = useState<string>('');
    const [emailSent, setEmailSent] = useState<boolean>(false)
    const [requireCaptcha, setRequireCaptcha] = useState(false);
    const captchaRef = useRef<HCaptcha>(null);
    const [showCaptchaForLogin, setShowCaptchaForLogin] = useState(false);
    const [showCaptchaForSendOtp, setShowCaptchaForSendOtp] = useState(false);
    const [tmpLoginDto, setTmpLoginDto] = useState<any>({});
    const [additionalVerificationRequired, setAdditionalVerificationRequired] = useState<boolean>(false);

    const otpFailedPhrase = 'Sorry, we are unable to send you a one-time code. Please try again later.';

    const loginFailedPhrase = 'Login failed. Please make sure the inputs are correct and try again.';

    let submitted = false;

    const otpLogin = async (request: OtpLoginRequest) => {
        try {
            const authResult = await otpLoginService(request)
            const { data, success } = authResult || {};
            const { requireHCaptcha, isLoginSucceeded, alreadyLoggedIn } = data || {};

            if (!data || !success) {
                throw new Error();
            }

            if (requireHCaptcha) {
                setRequireCaptcha(true);
            }

            if (isLoginSucceeded || alreadyLoggedIn) {
                const user = await authService.authenticate();

                if (!user) {
                    throw new Error();
                }

                dispatch(setUser(user));
                return;
            } else {
                throw new Error();
            }
        } catch {
            showErrorToast(loginFailedPhrase, 'Error');
        }
    }

    const sendOtpButtonClickHandler = async (e: any) => {
        e.preventDefault(true)
        await sendOtp()
    }

    const sendOtp = async () => {
        await form.validateFields(['emailAddress']);
        try {
            if (otpResetCountdownSeconds > 0)
            {
                return
            }

            if (requireCaptcha)
            {
                setShowCaptchaForSendOtp(true);
                setRequireCaptcha(false);
                return;
            }

            startOtpResetCountdownSeconds();
            let response = await sendOtpCode(email, sendOtpCaptchaToken);
            const { data, success } = response || {};
            const { requireRetryCaptCha, requireHCaptcha, alreadyLoggedIn, additionalVerificationRequired } = data || {};

            if (!success && !data)
            {
                throw new Error()
            }

            if (requireRetryCaptCha) {
                setShowCaptchaForSendOtp(true);
                return;
            }

            if (requireHCaptcha) {
                setRequireCaptcha(true);
            }
            
            if (!success && alreadyLoggedIn)
            {
                const user = await authService.authenticate();

                if (!user)
                {
                    throw new Error();
                }

                dispatch(setUser(user));
                return;
            }

            setEmailSent(true)
            setAdditionalVerificationRequired(additionalVerificationRequired === true);
        }
        catch (error: any) {
            showErrorToast(otpFailedPhrase, 'Error');
        }
    }

    const onFinish = async (value: any) => {
        if (submitted) {
            return;
        }

        if (!emailSent)
        {
            sendOtp();
            submitted = false;
            return;
        }

        submitted = true;

        try {
            if (requireCaptcha) {
                setShowCaptchaForLogin(true);
                setRequireCaptcha(false);
                setTmpLoginDto({...value});
                return;
            }

            const dateOfBirth = value.dateOfBirth;
            const formattedDateOfBirth = dateOfBirth ? formatJsonDate(dateOfBirth) : null;

            otpLogin({
                emailAddress: value.emailAddress,
                code: value.code,
                hCaptchaToken: null,
                dateOfBirth: formattedDateOfBirth
            });
        } finally {
            submitted = false;
        }
    }

    const onCaptchaVerified = (token: string) => {
        setShowCaptchaForLogin(false);
        setRequireCaptcha(false);
        captchaRef?.current?.resetCaptcha();

        const dateOfBirth = tmpLoginDto.dateOfBirth;
        const formattedDateOfBirth = dateOfBirth ? formatJsonDate(dateOfBirth) : null;

        otpLogin({
            emailAddress: tmpLoginDto.emailAddress,
            code: tmpLoginDto.code,
            hCaptchaToken: token,
            dateOfBirth: formattedDateOfBirth
        });
    }
    
    const onSendCaptchaVerified = (token: string) => {
        setShowCaptchaForSendOtp(false);
        setShowCaptchaForLogin(false);
        setRequireCaptcha(false);
        captchaRef?.current?.resetCaptcha();
        sendOtpCaptchaToken = token;
    }

    return (
        <div className="login">
            <div className="globird-form">
                <Form form={form} name="login" layout="vertical" onFinish={onFinish} className="login-form">
                    <div className="globird-form-title">
                        <FontAwesomeIcon
                            icon={faKey}
                            style={{marginRight: '10px', transform: 'scaleX(-1)'}}/>
                        SIGN IN to My Account with the email linked to your GloBird account
                    </div>
                    <Item
                        name="emailAddress"
                        label={<label className="globird-form-field-label">Email address</label>}
                        rules={[
                            generateRequiredRule('Email address'),
                            EmailRule
                        ]}>
                        <FilteredInput
                            allowClear
                            filterExpression={/\s/g}
                            onChange={(newValue: string) => setEmail(newValue)}/>
                    </Item>
                    {additionalVerificationRequired ?
                        <Item
                            name="dateOfBirth"
                            label={<label className="globird-form-field-label">Date of birth</label>}
                            rules={[
                                generateRequiredRule('Date of birth')
                            ]}>
                            <DatePicker
                                style={{ width: "100%" }}
                                allowClear
                                autoComplete="off"
                                picker="date"
                                defaultPickerValue={moment('1990-01-01')}
                            />
                        </Item>
                        : undefined}
                    {emailSent ?
                        <Item
                            name="code"
                            label={<label className="globird-form-field-label">Enter 6 digit code received</label>}
                            rules={[
                                generateRequiredRule('Code'),
                                {pattern: /^[0-9]*$/, message: 'Code can only be numbers'}
                            ]}>
                            <FilteredInput
                                maxLength={6}
                                style={{width: '100%'}}/>
                        </Item>
                        : undefined}
                    <Popover
                        visible={showCaptchaForSendOtp}
                        content={<Captcha
                            ref={captchaRef} 
                            onVerify={onSendCaptchaVerified}/>}>
                        <Button
                            className={emailSent ? 'globird-button-secondary' : 'globird-button-primary'}
                            onClick={sendOtpButtonClickHandler}
                            block
                            disabled={otpResetCountdownSeconds > 0}
                            style={{width: '100%', marginTop: '0px'}}>
                            {otpResetCountdownSeconds > 0
                                ? `Resend after (${workoutCountDownTimeStr(otpResetCountdownSeconds)})`
                                : emailSent
                                    ? 'Resend code'
                                    : 'Send one-time code'}
                        </Button>
                    </Popover>
                    {emailSent ?
                        <>
                            <p style={{marginBottom: '10px'}}>
                                {emailSent
                                    ? 'If you have a registered email address with GloBird, you will receive a one-time code.'
                                    : ''
                                }
                            </p>
                            <Popover
                                visible={showCaptchaForLogin}
                                content={<Captcha
                                    ref={captchaRef}
                                    onVerify={onCaptchaVerified}/>}>
                                <button
                                    className="globird-button-primary"
                                    style={{width: '100%', marginTop: '0px'}}>
                                    Continue
                                </button>
                            </Popover>
                        </>
                        : undefined}
                </Form>
            </div>
            <div className="router-block">
                <p><Link className="globird-link" to="/login">Click here</Link> to login with password</p>
            </div>
        </div>
    );
}

export default LoginOneTimePassword;