import './Correspondences.css';
import { useEffect, useRef, useState } from 'react';
import { selectCurrentAccount } from 'store/userSlice';
import { useSelector } from 'react-redux';
import { formatDisplayDate } from 'utilities/Utility';
import { Correspondence } from 'data/CustomerPortalTypes';
import { useNavigate } from 'react-router-dom';
import GlobirdPagination, { GlobirdPaginationControl } from '../Shared/GlobirdPagination/index';
import RequestDataService from 'services/RequestDataService';

const Correspondences = () => {
    let navigate = useNavigate();
    const currentAccount = useSelector(selectCurrentAccount);
    const [currentCorrespondenceList, setCurrentCorrespondenceList] = useState<Correspondence[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const globirdPagination = useRef<GlobirdPaginationControl>(null);
    const jumpPage = (currentCorrespondenceList: Correspondence[], totalCount: number) => {
        setCurrentCorrespondenceList(currentCorrespondenceList);
        setTotalCount(totalCount);
    };

    const redirectToCorresDetail = (targetId: number | null) => {
        if (targetId) {
            navigate(`/correspondences/${targetId}`);
        }
    }

    useEffect(() => {
        globirdPagination.current?.refresh();
    }, [currentAccount]);

    return (
        <div style={{ paddingBottom: "50px" }}>
            <div className="correspondence-block">
                <h4 className="correspondence-title">Correspondence from GloBird</h4>
                <div className="correspondence-subblock">
                    <h4 style={{ fontWeight: "bolder" }}>Messages</h4>
                    <p>You have total {totalCount} messages</p>
                </div>
                {
                    currentCorrespondenceList.map((corr, i) => (
                        <div className="correspondence-item" key={i} onClick={() => { redirectToCorresDetail(corr.id) }}>
                            <div className="globird-row">
                                <div className="globird-col">
                                    <p className="title">{corr.title}</p>
                                </div>
                                <div className="globird-col">
                                    <div className="time-block">
                                        <div className="time">
                                            <p>{formatDisplayDate(corr.createdDate)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className="pagination-block">
                    <GlobirdPagination
                        ref={globirdPagination}
                        requestDataFunc={(offset, limit) => { return RequestDataService.getAccountCorrespondenceList(currentAccount?.accountId, offset, limit) }}
                        onJumpToPage={jumpPage} />
                </div>
            </div>

        </div>
    );
}

export default Correspondences;