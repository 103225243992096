import GlobirdSelector from "components/Shared/GlobirdSelector";
import { CallbackInfo, HourOnlyFormat } from "data/CustomerPortalTypes";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExistingCallbacksForAccount, getPublicHolidays } from "services/RequestDataService";
import { selectCurrentAccount } from "store/userSlice";
import menuOptions from "../MenuOptions";
import ExistingCallback from "./ExistingCallback";
import NewCallbackForm from "./NewCallbackForm";
import { Select, Tooltip } from "antd";
import moment from "moment";

const defaultCallbackTimeSlots = new Map<number, boolean>([
    [10, false],
    [11, false],
    [12, false],
    [13, false],
    [14, false],
    [15, false],
    [16, false],
    [17, false]
]);

const renderTimeSlotSelectOptions = (callbackTimeSlots: Map<number, boolean>): React.ReactNode[] => {
    //For each time slot, render select option, which is disabled if map value is true
    let selectOptions: React.ReactNode[] = [];
    callbackTimeSlots.forEach((isBooked, timeSlot) =>
        selectOptions.push(<Select.Option
            key={timeSlot}
            value={timeSlot}
            disabled={isBooked}
            >
            <Tooltip
                title={isBooked ? 'This time slot is fully booked' : ''}
                placement='right'>
                {moment().hour(timeSlot).format(HourOnlyFormat)
                    + ' - '
                    + moment().hour(timeSlot + 1).format(HourOnlyFormat)}
            </Tooltip>
        </Select.Option>));
    return selectOptions;
}

const Callback = () => {
    const navigate = useNavigate();
    const currentAccount = useSelector(selectCurrentAccount);

    const [existingCallbackInfoList, setExistingCallbackInfoList] = useState<CallbackInfo[] | null>(null);
    const [publicHolidays, setPublicHolidays] = useState<string[]>([]);

    const refreshCallbackInfo = useCallback(async () => {
        if (!currentAccount) {
            return;
        }

        const callbackInfo = await getExistingCallbacksForAccount(currentAccount.accountId);

        const { success, data } = callbackInfo || {};

        if (success && data && data.length > 0) {
            setExistingCallbackInfoList(data);
        } else {
            setExistingCallbackInfoList(null);
        }
    }, [currentAccount]);

    useEffect(() => {
        const fetchPublicHolidays = async () => {
            const publicHolidaysResponse = await getPublicHolidays();

            const { success, data } = publicHolidaysResponse || {};

            if (success && data) {
                const vicHolidays = data?.get('VIC') ?? [];
                setPublicHolidays(vicHolidays);
            }
        }

        fetchPublicHolidays();
    }, []);

    useEffect(() => {
        refreshCallbackInfo();
    }, [currentAccount, refreshCallbackInfo]);

    return (
        <div className="callback-block">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="assistanceMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions.find(x => x.value === "callback")}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>
            {existingCallbackInfoList
                ? <ExistingCallback
                    existingCallbackInfoList={existingCallbackInfoList}
                    refreshCallbackInfo={refreshCallbackInfo}
                    publicHolidays={publicHolidays} />
                : <NewCallbackForm
                    refreshCallbackInfo={refreshCallbackInfo}
                    publicHolidays={publicHolidays} />}
        </div>
    );
};

export default Callback;
export { defaultCallbackTimeSlots, renderTimeSlotSelectOptions };