import { Moment } from 'moment';
import { CalendarControlDateFormat, CalendarControlDto, DefaultDisplayDateFormat } from 'data/CustomerPortalTypes';
import moment from 'moment';

export const isHoliday = (date: Moment,
  statePublicHolidays: string[],
  disableWeekDay: number[] = [0, 6],
  disableDayBeforePublicHoliday: boolean) =>

  disableWeekDay.indexOf(date.day()) > -1
  || statePublicHolidays.indexOf(date.format(DefaultDisplayDateFormat)) > -1
  || (disableDayBeforePublicHoliday && statePublicHolidays.indexOf(date.clone().add(1, 'day').format(DefaultDisplayDateFormat)) > -1);

export const addBusinessDays = (date: Moment, days: number, statePublicHolidays: string[]) => {
  let result = date.clone();
  let count = 0;
  while (count < days) {
    result.add(1, 'days');
    if (!isHoliday(result, statePublicHolidays, [0, 6], false)) {
      count++;
    }
  }
  return result;
}

export const isDisabledDate = (date: Moment,
  calendar: CalendarControlDto) => {
  if (calendar.daysOfWeekDisabled.indexOf(date.day()) > -1) {
    return true;
  }

  if (calendar.disabledDates.indexOf(date.format(CalendarControlDateFormat)) > -1) {
    return true;
  }

  if (calendar.minDate && date < moment(calendar.minDate, CalendarControlDateFormat).startOf('day')) {
    return true;
  }

  if (calendar.maxDate && date > moment(calendar.maxDate, CalendarControlDateFormat).endOf('day')) {
    return true;
  }

  return false;
}