import { formatDisplayDate } from "utilities/Utility";
import { Alert, Form } from "antd";
import {
  NewSelfMeterRead,
  NewRegisterRead,
  BasicMeterRead,
  MeterReadQuality,
} from "data/CustomerPortalTypes";

const { Item: FormItem } = Form;

const isSelfReadTooHigh = (lastActualRead: number, currentSelfRead: number) => {
    // If self read is more than 50% higher than last actual read and more than 200 units higher than last actual read
    const conditionOne = (currentSelfRead >= (lastActualRead * 1.5)) && (currentSelfRead >= (lastActualRead + 200));
    // If self read is more than 2000 units higher than last actual read
    const conditionTwo = currentSelfRead >= (lastActualRead + 2000);

    return conditionOne || conditionTwo;
}

// If self read is more than 100 units lower than last actual read
const isSelfReadTooLow = (lastActualRead: number, currentSelfRead: number) =>
    (currentSelfRead <= (lastActualRead - 100))

const isSelfReadOutOfRange = (lastActualRead: number, currentSelfRead: number) =>
    isSelfReadTooHigh(lastActualRead, currentSelfRead) || isSelfReadTooLow(lastActualRead, currentSelfRead);

const LastReadsInformation = ({
  latestBasicMeterReads,
  meterRead,
  registerRead,
}: {
  latestBasicMeterReads: BasicMeterRead[] | null;
  meterRead: NewSelfMeterRead;
  registerRead?: NewRegisterRead | null;
}) => {
  const { meterSerial, readIndex: meterReadIndex } = meterRead;

  const {
    registerId,
    allowIgnoreRegisterId,
    readIndex: registerReadIndex,
  } = registerRead || {};

  const currentReadIndex = registerReadIndex ?? meterReadIndex;

  let meterReadsForSelectedMeter = latestBasicMeterReads?.filter(
    (m) => m.serial === meterSerial
  );

  if (!meterReadsForSelectedMeter?.length) {
    return null;
  }

  // If list contains multiple registers for same meter but registerId is not selected
  // then return empty fragment
  if (
    [...new Set(meterReadsForSelectedMeter.map((x) => x.registerId).filter(x => x))].length > 1 &&
    !registerId
  ) {
    return null;
  }

  // If registerId is selected then filter out the meterReadsForSelectedMeter
  // which contains the selected registerId
  if (registerId) {
    if (allowIgnoreRegisterId) {
      return null;
    }

    if (!meterReadsForSelectedMeter.some((m) => m.registerId === registerId)) {
      return null;
    }

    meterReadsForSelectedMeter = meterReadsForSelectedMeter.filter(
      (m) => m.registerId === registerId
    );
  }

  const lastSelfRead = meterReadsForSelectedMeter.find(
    (m) => m.quality === MeterReadQuality.Customer
  );

  const lastActualRead = meterReadsForSelectedMeter.find(
    (m) => m.quality === MeterReadQuality.Actual
  );

  const { meterIndex: lastSelfReadIndex, readDate: lastSelfReadDate } =
    lastSelfRead || {};
  const { meterIndex: lastActualReadIndex, readDate: lastActualReadDate } =
    lastActualRead || {};

  let lastRead = lastActualReadIndex;
  if(!lastActualReadDate ||
    (lastSelfReadDate && lastActualReadDate < lastSelfReadDate)) {
    lastRead = lastSelfReadIndex
  }

  const displaySelfReadWarning =
    lastRead !== 0 &&
    lastRead &&
    currentReadIndex &&
    isSelfReadOutOfRange(lastRead, Number(currentReadIndex));
  
  const idSuffix = registerId ? `${meterSerial}-${registerId}` : `${meterSerial}`;

  return (
    <FormItem id={`LastReadInfo-${idSuffix}`}>
      {displaySelfReadWarning ? (
        <span id={`SelfReadWarning-${idSuffix}`}>
          <Alert
            type="warning"
            message="It seems like the meter read you've entered is outside the expected range. Please check and confirm the digits entered are correct."
          />
          <br />
        </span>
      ) : null}
      <span id={`LastSelfRead-${idSuffix}`}>
        Last Self Read: <b>{lastSelfReadIndex ?? "Not Available"}</b>
        {lastSelfReadDate ? ` ( ${formatDisplayDate(lastSelfReadDate)} )` : ""}
      </span>
      <br />
      <span id={`LastActualRead-${idSuffix}`}>
        Last Actual Read: <b>{lastActualReadIndex ?? "Not Available"}</b>
        {lastActualReadDate
          ? ` ( ${formatDisplayDate(lastActualReadDate)} )`
          : ""}
      </span>
    </FormItem>
  );
};

export default LastReadsInformation;
