import { Form, Input, Popover, Spin } from 'antd';
import { useRef, useState } from 'react';
import { RegisterAccountDto } from 'data/CustomerPortalTypes';
import { Link, useNavigate } from 'react-router-dom';
import { register } from 'services/AuthService';
import { RegistrationOutcome } from 'data/CustomerPortalTypes';
import {
    generateRequiredRule as genRqdRule,
    AccountNumberRule,
    EmailRule
} from 'utilities/Utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons';
import FilteredInput from 'components/Shared/FilteredInput';
import Captcha from 'components/Shared/Captcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import ActionResultPanel from 'components/ActionResultPanel';

const { Item } = Form;

const mismatchFormErr = [{
    name: 'EmailAddress',
    errors: [''],
}, {
    name: 'EuropaAccountNumber',
    errors: ['Please check the Email Address and Account Number.']
}];

let tmpRegDto: RegisterAccountDto | null = null;

let submitted = false;

const AccountRegistration = () => {
    const [form] = Form.useForm<RegisterAccountDto>();
    const [registrationResult, setRegistrationResult] = useState<RegistrationOutcome | null>(null);

    const [showCaptcha, setShowCaptcha] = useState(false);
    const [requireCaptcha, setRequireCaptcha] = useState(false);
    const captchaRef = useRef<HCaptcha>(null);
    const navigate = useNavigate();

    const registerLocal = async (regDto: RegisterAccountDto, hCaptchaToken: string | null) => {

        const regRes = await register(regDto, hCaptchaToken);

        // console.log('regRes', regRes);

        if (!regRes?.success
            || !regRes.data) {

            return;
        }

        const regResultData = regRes.data;

        if (regRes.data.registrationOutcome === RegistrationOutcome.InformationMismatch) {
            form.setFields(mismatchFormErr);
        }

        if (regRes.data.registrationOutcome === RegistrationOutcome.UserUnavailable) {
            navigate('/unavailable', { replace : true, state : { unavailableFlag : true } });
        }

        if (regResultData.requireRetryCaptCha) {
            tmpRegDto = { ...regDto };

            setShowCaptcha(true);

            return;
        }

        if (regResultData.requireHCaptcha) {
            setRequireCaptcha(true);
        }

        setRegistrationResult(regRes.data.registrationOutcome);
    }

    const onFinish = async (value: RegisterAccountDto) => {

        if (submitted) {
            return;
        }

        submitted = true;

        try {
            if (requireCaptcha) {

                setShowCaptcha(true);
                setRequireCaptcha(false);
                tmpRegDto = { ...value };

                return;
            }

            registerLocal(value, null);

        } finally {
            submitted = false;
        }
    }

    const onCaptchaVerified = (token: string) => {

        setShowCaptcha(false);
        setRequireCaptcha(false);
        captchaRef?.current?.resetCaptcha();

        registerLocal(tmpRegDto!, token);
    }

    // console.log(registrationResult)

    return (
        <div className="login">
            <Spin
                spinning={showCaptcha}
                wrapperClassName="form-spin-wrapper">
                <div className="globird-form" style={{ maxWidth: 'none' }}>
                    {
                        (registrationResult === null
                            || registrationResult === RegistrationOutcome.InformationMismatch)
                        && <Form
                            form={form}
                            onFinish={onFinish}
                            name="registerAccount"
                            layout="vertical"
                            // validateTrigger="onBlur"
                            className="login-form">
                            <div className="globird-form-title">
                                <FontAwesomeIcon
                                    icon={faKey}
                                    style={{ marginRight: '10px', transform: 'scaleX(-1)' }} />Register My Account
                            </div>
                            <Item
                                tooltip="The Email address you used when signing up with us."
                                name="EmailAddress"
                                label={<label className="globird-form-field-label">Email Address</label>}
                                rules={[
                                    genRqdRule('Email address'),
                                    EmailRule
                                ]}>
                                <FilteredInput
                                    allowClear
                                    filterExpression={/\s/g}
                                    style={{ width: "100%" }} />
                            </Item>
                            <Item
                                tooltip="You can find your Account Number in your Welcome Pack, as well as on your bills."
                                name="EuropaAccountNumber"
                                label={<label className="globird-form-field-label">Account Number</label>}
                                rules={[genRqdRule('Account number'), AccountNumberRule]}>
                                <Input
                                    type="text"
                                    allowClear
                                    style={{ width: "100%" }} />
                            </Item>
                            <Item>
                                <Popover
                                    visible={showCaptcha}
                                    content={<Captcha
                                        ref={captchaRef}
                                        onVerify={onCaptchaVerified} />}>
                                    <button
                                        className="globird-button-primary"
                                        style={{ width: '100%' }}>
                                        Register
                                    </button>
                                </Popover>
                            </Item>
                        </Form>
                    }
                    {
                        registrationResult === RegistrationOutcome.ActivationEmailSent && <ActionResultPanel status="success">
                            Registration Email sent to:
                            <br />
                            <span style={{ fontWeight: 'bold' }}>
                                {form.getFieldValue('EmailAddress')}
                            </span>
                            <br />
                            Please follow the link in the Email to complete your registration of GloBird My Account.
                        </ActionResultPanel>
                    }
                    {
                        registrationResult === RegistrationOutcome.AlreadyRegistered && <ActionResultPanel status="success">
                            It seems you are already with us.
                        </ActionResultPanel>
                    }
                    <div className="router-block">
                        <p><Link className="globird-link" to="/login">Click here</Link> to go back to login</p>
                    </div>
                </div>
            </Spin>
        </div>
    );
}

export default AccountRegistration;