import { useRef } from 'react';

export const CustomUploadWrapper = (props: {
    children: (
        triggerUpload: () => void
    ) => void | React.ReactElement,
    accept?: string,
    onChange?: (f: File) => void
    // ,
    // value?: File
}) => {

    const { children, accept, onChange: propOnChange } = props;

    const fileInputRef = useRef<HTMLInputElement>(null);

    return <>
        {children(
            () => fileInputRef.current?.click())}
        <input
            accept={accept}
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={e => {
                const newFile = e.target.files?.[0];
                if (!newFile) {
                    return;
                }

                propOnChange?.(newFile);

                fileInputRef.current!.files = new DataTransfer().files;
            }}
        />
    </>;
}

export default CustomUploadWrapper;