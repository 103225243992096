import classnames from "classnames";
import styles from './AddJointOwnerForm.module.css';
import { useNavigate } from "react-router-dom";
import links from "data/Links";
import trackEvent from "services/AnalyticsService";

const AddJointOwnerForm = () => {
    const navigate = useNavigate();

    const downloadJointOwnerForm = () => {
        trackEvent("click", "joint_owner", "download_joint_owner_form");

        window.open(
            links.forms.ALL.jointOwnerApplicationForm,
            "_blank",
            "noopener noreferrer");
    };

    return (
        <div className="field-container" style={{ width: '90%' }}>
            <p>
                To add a joint account owner, signed consent is required from both the current account owner, and the new joint owner. 
                Please read and sign the form, then return to: 
                <a rel="noreferrer noopener" href={"mailto:" + links.email.cs} target="_blank"> {links.email.cs} </a>
            </p>
            <div className={styles["joint-owner-form-download-button"]}>
                <button
                    id={"downloadJointOwnerFormButton"}
                    className={classnames('globird-button-primary')}
                    onClick={downloadJointOwnerForm}
                >
                    Download form
                </button>
            </div>
            <p>
                We'll get in touch as soon as we've completed adding joint owner to your account.
            </p>
            <br />
            <p className={styles["account-holder-information-heading"]}>
                Important information regarding account types
            </p>
            <p className={styles["account-holder-information-text"]}>
                <b>Joint account owners</b> can make enquiries, authorise data sharing arrangements and changes to the account, are named on the bill, and are financially responsible.
                <br /><b>Authorised contacts</b> can make enquiries, authorise data sharing arrangements and changes to the account but are not named on the bill or financially responsible.
                <br />If you would prefer to add an authorised contact instead, click {" "}
                <u><a href="#" onClick={() => navigate("/accountholder")}>here</a></u>
            </p>
        </div>
    )
}

export default AddJointOwnerForm;