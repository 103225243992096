export enum MessageTopic {
    requestStart,
    requestEnd
}

type Message = {
    topic: MessageTopic,
    data: any
}

type MessageWorker = {
    message: Message,
    handler: (message: Message) => void
}

export type { Message, MessageWorker }
