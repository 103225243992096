import { getRuleObjectDelayed, getRuleObject } from './Utility';

const existSingleCharacterRegex = /[^ -~]+/;
const charactersRegex = /[A-Za-z]/;
const numbersRegex = /\d/;

const PasswordValidator = (v: string) => {
    if (!v) {
        return null;
    }

    if (v.length > 30 || v.length < 8) {
        return "Password should contain 8~30 characters."
    }

    if (existSingleCharacterRegex.test(v)) {
        return "Password contains characters not allowed."
    }

    if (!(charactersRegex.test(v) && numbersRegex.test(v))) {
        return "Password should contain both numbers and alphabets.";
    }

    const charDic = new Map<string, boolean>();

    for (let i = 0; i < v.length; i++) {
        charDic.set(v.charAt(i), true);
    }

    if (Array.from(charDic.keys()).length < 4) {
        return "Password should contain at least 4 unique characters.";
    }

    return null;
}

const PasswordUsernameOverlapValidator = (
    passwordFieldName: string,
    usernameFieldName: string,
    usernameDisplayName: string,
    testEmailUsername: boolean = true) =>

    getRuleObject((getFieldsValue: any) => {
        let pwd = getFieldsValue()?.[passwordFieldName] as string;

        let un = getFieldsValue()?.[usernameFieldName] as string;

        if (typeof pwd !== 'string' || typeof un !== 'string') {
            return null;
        }

        if (testEmailUsername) {
            const splitted = un.split('@');

            un = splitted.splice(0, splitted.length === 1 ? 1 : splitted.length - 1).join('@');
        }

        if (!pwd || !un || pwd.length <= 4 || un.length <= 4) {
            return null;
        }

        pwd = pwd.toLowerCase();
        un = un.toLowerCase();

        if (un.indexOf(pwd) >= 0 || pwd.indexOf(un) >= 0) {
            return `Password should not overlap with ${usernameDisplayName}.`;
        }

        return null;
    }, true);

const PasswordUsernameOverlapStaticValidator = (
    userName: string,
    usernameDisplayName: string,
    testEmailUsername: boolean = true) =>

    getRuleObject((pwd: string) => {

        if (typeof pwd !== 'string' || typeof userName !== 'string') {
            return null;
        }

        if (testEmailUsername) {
            const splitted = userName.split('@');

            userName = splitted.splice(0, splitted.length === 1 ? 1 : splitted.length - 1).join('@');
        }

        if (!userName || !pwd || userName.length <= 4 || pwd.length <= 4) {
            return null;
        }

        pwd = pwd.toLowerCase();
        userName = userName.toLowerCase();

        if (pwd.indexOf(userName) >= 0 || userName.indexOf(pwd) >= 0) {
            return `Password should not overlap with ${usernameDisplayName}.`;
        }

        return null;
    });

const RepeatPasswordValidator = (
    passwordFieldName: string,
    confirmPasswordFieldName: string) =>

    getRuleObject((getFieldsValue: any) => {
        const pwd = getFieldsValue()?.[passwordFieldName] as string;

        const cpwd = getFieldsValue()?.[confirmPasswordFieldName] as string;

        if (typeof pwd !== 'string' || typeof cpwd !== 'string' || !pwd || !cpwd) {
            return null;
        }

        if (pwd !== cpwd) {
            return 'Password does not match.';
        }

        return null;
    }, true);

const CommonPasswordValidator = (delay?: number) => {
    delay ??= 300;

    if (delay === 0) {
        return getRuleObject(PasswordValidator);
    }

    return getRuleObjectDelayed(PasswordValidator, delay)
};

export {
    CommonPasswordValidator,
    PasswordUsernameOverlapStaticValidator,
    PasswordUsernameOverlapValidator,
    RepeatPasswordValidator
}
