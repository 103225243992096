import './PaymentHistory.css'
import { useEffect, useRef, useState } from 'react';
import requestDataService from 'services/RequestDataService';
import { selectCurrentAccount } from 'store/userSlice';
import { useSelector } from 'react-redux';
import GlobirdPagination, { GlobirdPaginationControl } from 'components/Shared/GlobirdPagination';
import GlobirdTable from 'components/Shared/GlobirdTable';
import { DefaultDisplayDateFormat, Payment } from 'data/CustomerPortalTypes';
import { formatAmount, formatDisplayDate } from 'utilities/Utility';
import { GlobirdTableContainer } from 'data/GlobirdTableContainer';
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import menuOptions from '../MenuOptions';

const paymentDescDic = { // todo: use PaymentTypeCode instead of PaymentTypeDescription
    'Direct Debit': 'Direct Debit',
    'CBA BPay DD': 'BPay Direct Debit',
    'CBA BPoint': 'Direct Debit',
    'Utility Relief Grant - Electricity': 'Utility Relief Grant - Electricity',
    'Centre Pay': 'Centre Pay',
    'Utility Relief Grant': 'Utility Relief Grant',
    'WePay Transaction': 'WePay',
    'HEEAS': 'HEEAS',
    'Cheque Run': 'Cheque',
    'Batch Of Cash Payments': 'Cash',
    'CBA BPay CC': 'BPay Credit Card',
    'Commbiz Deposit': 'Commbiz Deposit',
    'Utility Relief Grant - Gas': 'Utility Relief Grant - Gas',
    'Credit Card': 'Credit Card',
    'Australia Post Billpay': 'Australia Post Billpay',
    'Customer Portal BPOINT Credit Card': 'Credit Card'
};

const PaymentHistory =() => {
    const currentAccount = useSelector(selectCurrentAccount);
    const navigate =useNavigate();
    const { RangePicker } = DatePicker;
    const [currentPaymentList, setCurrentPaymentList] = useState<Payment[]>([]);
    const [filterDateRange, setFilterDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const globirdPagination = useRef<GlobirdPaginationControl>(null);
    const jumpPage = (currentPaymentList: Payment[], totalCount: number) => {
        setCurrentPaymentList(currentPaymentList);
    };

    const convertGlobirdTableContainer = (paymentList: Payment[]): GlobirdTableContainer => {
        let dataContainer = new GlobirdTableContainer();
        dataContainer.headerList.push("Payment Received", "Receipt Number", "Amount", "Type", "Status");
        for (let payment of paymentList) {
            var rowData = [
                formatDisplayDate(payment.paymentReceivedDate),
                payment.paymentReceiptId,
                "$" + formatAmount(payment.paymentAmount.toString()),
                payment.paymentType,
                payment.paymentStatus,
            ];
            dataContainer.rowList.push(rowData);
        }

        return dataContainer;
    }

    const configDateRange = (dates: any) => {
        if (dates) {
            setFilterDateRange([dates[0], dates[1]]);
        }
        else {
            setFilterDateRange([null, null]);
        }
    }

    useEffect(() => {
        globirdPagination.current?.refresh();
    }, [currentAccount, filterDateRange]);

    return (
        <div className="payment-history-block">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="paymentMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions[2]}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>
            <div className="filter-block">
                <p className="filter-label">Set date range (optional)</p>
                <RangePicker onChange={configDateRange} format={DefaultDisplayDateFormat} />
            </div>

            <GlobirdTable dataContainer={convertGlobirdTableContainer(currentPaymentList)}></GlobirdTable>

            <GlobirdPagination
                ref={globirdPagination}
                requestDataFunc={async (offset, limit) => {

                    const result = await requestDataService.getAccountPaymentList(
                        currentAccount?.accountId,
                        filterDateRange[0],
                        filterDateRange[1],
                        offset,
                        limit);

                    if (result?.data?.data && Array.isArray(result.data.data)) {
                        result.data.data.forEach(r => {
                            if (r.paymentType && (r.paymentType in paymentDescDic)) {
                                r.paymentType = (paymentDescDic as any)[r.paymentType];
                            }
                        });
                    }

                    return result;
                }}
                onJumpToPage={jumpPage} />
        </div>
    );
}

export default PaymentHistory;