import { Button, Form, Input, Modal, notification } from "antd";
import {
    generateVerificationRule as genCodeRule,
    workoutCountDownMessage,
} from 'utilities/Utility';
import { useMemo, useState, MouseEvent, ChangeEvent } from "react";
import {
    VerificationCodePurpose,
    VerificationCodeType,
    DefaultVerificationCodeLength as dftVrfctLen
} from "data/CustomerPortalTypes";
import { RuleObject } from "antd/lib/form";
import { verifyCode } from "services/RequestDataService";

const codeRule: RuleObject = genCodeRule(dftVrfctLen) as any;

const { Item } = Form;

const debounceDic: { [key: string]: boolean } = {};

const VerificationCodeModal = (
    {
        title,
        visible,
        onCancel,
        codePurpose,
        codeType,
        corrlelationId,
        recipientIdentifier,
        onVerificationSucceeded,
        canVerify,
        accountId,
        resendCountdownLeft,
        resendCb,
        maskClosable
    }: {
        title: string,
        visible: boolean,
        onCancel: (e: MouseEvent<HTMLElement>) => void,
        codePurpose: VerificationCodePurpose,
        codeType: VerificationCodeType,
        corrlelationId: string,
        recipientIdentifier: string,
        onVerificationSucceeded: (tickId: string, recipientIdentifier: string) => any
        canVerify?: () => boolean,
        accountId?: number,
        resendCountdownLeft?: number,
        resendCb?: () => any,
        maskClosable?: boolean
    }) => {

    const [code, setCode] = useState<string>('');
    const [codeValid, setCodeValid] = useState<boolean>(true);
    const [codeHelpMsg, setCodeHelpMsg] = useState<string>('');

    const onVerify = useMemo(() => async () => {
        if (debounceDic[codePurpose]) {
            return;
        }

        debounceDic[codePurpose] = true;

        try {
            if (canVerify && !canVerify()) {
                return;
            }

            if (!code) {
                setCodeValid(false);
                setCodeHelpMsg('Verification code is required.');

                return;
            }

            if (!codeRule.pattern!.test(code)) {
                setCodeValid(false);
                setCodeHelpMsg(codeRule.message as string);

                return;
            }

            const codeVerifyResult = await verifyCode(
                {
                    Purpose: codePurpose,
                    RecipientIdentifier: recipientIdentifier,
                    Code: code,
                    CorrelationId: corrlelationId,
                    CodeType: codeType
                }, accountId);

            if (!codeVerifyResult?.data?.verificationTicketId) {
                notification.error({
                    message: "Fail",
                    description: "Verification failed."
                });

                return;
            }

            notification.success({
                message: "Success",
                description: "Verification succeeded."
            });

            setCode('');
            setCodeValid(true);
            setCodeHelpMsg('');

            onVerificationSucceeded(
                codeVerifyResult.data.verificationTicketId,
                recipientIdentifier);
        }
        finally {
            debounceDic[codePurpose] = false;
        }

    }, [canVerify,
        codePurpose,
        codeType,
        recipientIdentifier,
        corrlelationId,
        accountId,
        code,
        onVerificationSucceeded]);

    const onCodeChange = useMemo(() => (event: ChangeEvent<HTMLInputElement>) => {
        !codeValid && setCodeValid(true);
        codeHelpMsg && setCodeHelpMsg('');

        setCode(event.target.value);
    }, [codeHelpMsg, codeValid]);

    return <Modal
        maskClosable={maskClosable ?? false}
        visible={visible}
        title={title}
        onCancel={onCancel}
        footer={resendCb
            ? [<Button
                key={codePurpose + '-resend-btn'}
                type="primary"
                disabled={resendCountdownLeft !== undefined && resendCountdownLeft > 0}
                onClick={resendCb}>
                Resend {codeType === VerificationCodeType.Sms ? 'SMS' : 'Email'}{workoutCountDownMessage(resendCountdownLeft ?? 0)}
            </Button>,
            <Button
                key={codePurpose + '-verify-btn'}
                type="primary"
                onClick={onVerify}>Verify</Button>]
            : [<Button
                key={codePurpose + '-verify-btn'}
                type="primary"
                onClick={onVerify}>Verify</Button>]
        }
    >
        <p>A {dftVrfctLen} digits PIN has been sent via {codeType} to <span style={{ fontWeight: 'bold' }}>{recipientIdentifier}</span></p>
        <p>Cannot receive the PIN? {codeType === VerificationCodeType.Sms ? "Click the \"Resend SMS\" button." : "Please check your spam box or click the \"Resend Email\" button."}<br /></p>
        <Item
            validateStatus={codeValid ? 'success' : 'error'}
            help={codeHelpMsg}>
            <Input onChange={onCodeChange} value={code} allowClear />
        </Item>
    </Modal>;
};

export default VerificationCodeModal;
