import styles from './BillingHistory.module.css'
import { useEffect, useRef, useState } from 'react';
import { selectCurrentAccount } from 'store/userSlice';
import { useSelector } from 'react-redux';
import GlobirdPagination, { GlobirdPaginationControl } from 'components/Shared/GlobirdPagination/index';
import GlobirdTable from 'components/Shared/GlobirdTable';
import { DefaultDisplayDateFormat, Invoice } from 'data/CustomerPortalTypes';
import { formatAmount, formatDisplayDate } from 'utilities/Utility';
import { GlobirdTableContainer } from 'data/GlobirdTableContainer';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import pdfIcon from 'assets/file-pdf.svg';
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { getAccountInvoicesAsync } from 'services/RequestDataService';
import menuOptions from '../MenuOptions';

const { RangePicker } = DatePicker;

const BillingHistory = () => {
    const currentAccount = useSelector(selectCurrentAccount);
    const navigate = useNavigate();
    const [currentInvoiceList, setCurrentInvoiceList] = useState<Invoice[]>([]);
    const [filterDateRange, setFilterDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const globirdPagination = useRef<GlobirdPaginationControl>(null);

    const jumpPage = (currentInvoiceList: Invoice[]) => setCurrentInvoiceList(currentInvoiceList);

    const convertGlobirdTableContainer = (invoiceList: Invoice[]): GlobirdTableContainer => {
        const dataContainer = new GlobirdTableContainer();

        dataContainer.headerList.push('Issue Date', 'Due Date', 'Amount Before Discount', 'Inv. No.', '');

        invoiceList.forEach(i => dataContainer.rowList.push([
            formatDisplayDate(i.issuedDate),
            formatDisplayDate(i.dueDate),
            ("$" + formatAmount(i.amount.toString()) + (i.amount >= 0 ? "" : " credit")),
            i.invoiceNumber?.toString(),
            (<a target="_blank"
                rel="noreferrer"
                href={`/api/correspondence/document?accountId=${currentAccount!.accountId}&documentId=${i.documentId}`}>
                <img className={styles['pdf-icon']} src={pdfIcon} alt="pdf-icon" />
            </a>)
        ]));

        return dataContainer;
    }

    const configDateRange = (dates: any) => dates
        ? setFilterDateRange([dates[0], dates[1]])
        : setFilterDateRange([null, null]);

    useEffect(
        () => globirdPagination.current?.refresh(),
        [currentAccount, filterDateRange])

    if (!currentAccount) {
        return <></>;
    }

    return (<div className={styles['previous-bill-block']}>
        <div className="globird-menu-option">
            <p className="globird-menu-label">Show me: </p>
            <GlobirdSelector
                id="billMenuSelector"
                options={menuOptions}
                defaultOption={menuOptions[1]}
                onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
        </div>
        <div className={styles['filter-block']}>
            <p className={styles['filter-label']}>Set date range (optional)</p>
            <RangePicker onChange={configDateRange} format={DefaultDisplayDateFormat} />
        </div>
        <GlobirdTable dataContainer={convertGlobirdTableContainer(currentInvoiceList)}></GlobirdTable>
        <GlobirdPagination
            ref={globirdPagination}
            requestDataFunc={(offset, limit) => getAccountInvoicesAsync(
                currentAccount.accountId,
                filterDateRange[0],
                filterDateRange[1],
                offset,
                limit)}
            onJumpToPage={jumpPage} />
    </div>);
}

export default BillingHistory;