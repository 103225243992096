import GlobirdSelector from 'components/Shared/GlobirdSelector';
import GlobirdSelectorOption from 'data/GlobirdSelectorOption';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentAccountDirectDebitInfo } from 'store/directDebitInfoSlice';

const menuOptions: (hasDd: boolean) => GlobirdSelectorOption<string>[] = (hasDd) => [
    { name: "Account summary", value: "accountsummary" },
    { name: hasDd ? "Update direct debit" : "Set up direct debit", value: "directdebit" },
    { name: "Change login email", value: "changeemail" },
    { name: "Change password", value: "changepassword" },
    { name: "Authorized contacts", value: "accountholder" },
    { name: "Joint Account Owner", value: "jointowner" },
    { name: "Manage concession", value: "concession"}
];

const AccountMenuOption: FC<{ defaultIndex: number, id: string }> = ({ defaultIndex, id }) => {
    const navigate = useNavigate();
    const ddInfo = useSelector(getCurrentAccountDirectDebitInfo());

    const menuOptionsResult = menuOptions(!!ddInfo);

    return <GlobirdSelector
        id={id}
        options={menuOptionsResult}
        defaultOption={menuOptionsResult[defaultIndex]}
        onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
}

export default AccountMenuOption;