import { Divider } from 'antd';
import glbdLogoMono from 'assets/globirdLogoMonoChrome.svg';
import { CSSProperties } from 'react';
import GlobirdIcon from 'components/Shared/Icons/GlobirdIcon';

export const GlobirdDivider = ({ style }: { style?: CSSProperties }) => {
    style ??= {};

    return (<Divider style={{
        margin: '5px 0',
        color: '#ededed',
        ...style
    }}>
        <span style={{
            display: 'inline-block',
            width: '23px',
            height: '13px',
        }}>
            <GlobirdIcon style={{
                display: 'inline-block',
                fontSize: '14px',
                position: 'relative',
                right: '4px',
                bottom: '1px'
            }} />
        </span>

    </Divider>)
};

export default GlobirdDivider;