import styles from './LinkManagement.module.css'
import {Form, Input, Space} from 'antd'
import ButtonWithToolTip from '../../../Shared/ButtonWithToolTip';
import referWithText from '../../../../assets/refer-with-text.svg'
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentAccount} from '../../../../store/userSlice';
import {lookupReferralLink} from '../../../../services/RequestDataService';
import GlobirdSelector from '../../../Shared/GlobirdSelector';
import menuOptions from '../MenuOptions';
import {useNavigate} from 'react-router-dom';
import {QRCodeCanvas} from 'qrcode.react';
import globirdLogo from '../../../../assets/globirdLogo-black.svg';
import {GlobirdOrPip} from '../../../Shared/GlobirdOr';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareNodes} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {DefaultDisplayDateFormat} from '../../../../data/CustomerPortalTypes';

const {Item} = Form;

const LinkManagement = () => {
    const navigate = useNavigate();
    const currentAccount = useSelector(selectCurrentAccount);
    const [referralLink, setReferralLink] = useState<string>()
    const [referralExpiry, setReferralExpiry] = useState<string>('')
    const [copyButtonText, setCopyButtonText] = useState<string>('Copy Link')
    const [showReferral, setShowReferral] = useState<boolean>(false)
    const [showReferralError, setShowReferralError] = useState<boolean>(false)
    const [shareApiEnabled, setShareApiEnabled] = useState<boolean>(false)

    useEffect(() => {
        const populateReferral = async () => {
            if (!currentAccount) {
                return;
            }

            const result = await lookupReferralLink(currentAccount.accountId);

            if (!result?.success || !result.data) {
                setShowReferral(false);
                setShowReferralError(true)
                return;
            }

            setReferralLink(result.data.link);
            
            const date = moment(result.data.expiryDate)
            if (date.isValid()) {
                setReferralExpiry(moment(date).format(DefaultDisplayDateFormat))
            }
            
            setShowReferral(true);

            if (!navigator.canShare) {
                setShareApiEnabled(false);
            } else {
                setShareApiEnabled(true);
            }
        }

        populateReferral()
    }, [currentAccount]);

    const copyToClipboard = async () => {
        if (!referralLink) {
            return;
        }

        await navigator.clipboard.writeText(referralLink);

        setCopyButtonText('Copied!');

        setTimeout(() => {
            setCopyButtonText('Copy Link');
        }, 4000);
    }

    const shareQRCode = async () => {
        const canvas = document.getElementById("canvas-qrcode") as HTMLCanvasElement;
        const dataUrl = canvas.toDataURL("image/png");
        const imageData = await fetch(dataUrl);
        const blob = await imageData.blob();
        const filesArray = [
            new File(
                [blob],
                'Referral.png',
                {
                    type: blob.type
                }
            )
        ];

        const shareData = {
            files: filesArray,
        };

        await navigator.share(shareData);
    };

    return (
        <div>
            <div className='form-container' style={{maxWidth: '1000px', paddingBottom: '35px'}}>
                <div className='globird-menu-option'>
                    <p className='globird-menu-label'>Show me:</p>
                    <GlobirdSelector
                        id='linkManagementMenuSelector'
                        options={menuOptions}
                        defaultOption={menuOptions[1]}
                        onSelected={(targetMenu) => {
                            navigate(`/${targetMenu.value}`);
                        }}/>
                </div>
                {showReferral && referralLink ?
                    <div className='field-container' style={{width: '90%'}}>
                        <h4 className={styles['page-heading']}>
                            View your referral link and share with your friends
                        </h4>
                        <p style={{paddingBottom: '10px'}}>
                            Referring your friends is as easy as copying your referral link
                            and distributing it to your friends using your chosen platform.</p>
                        <h3 className={styles['referral-heading']}>Referral link management</h3>
                        <h5 className={styles['referral-subheading']}>Copy your referral link</h5>
                        <Item>
                            <Input.Group style={{display: 'flex'}}>
                                <Input
                                    readOnly
                                    style={{flex: '1 1 auto'}}
                                    value={referralLink}
                                    id='referral-link'
                                    title='referral link'/>
                                <ButtonWithToolTip
                                    disabled={false}
                                    btnNode={<span>{copyButtonText}</span>}
                                    useAntdBtn
                                    componentClassName={[styles['copy-button-wrapper']]}
                                    btnClassName={[styles['copy-button']]}
                                    onClick={copyToClipboard}/>
                            </Input.Group>
                        </Item>
                        { referralExpiry ?
                            <p><u>Note:</u> the referral link above will expire on {referralExpiry}. The expiry date can be extended by returning to this page.</p>
                            : null
                        }
                        <GlobirdOrPip/>
                        <div style={{marginBottom: "10px"}}>
                            <Space>
                                <h5 className={styles['referral-scanqrcode-text']}>Scan QR code</h5>
                                {shareApiEnabled ?
                                    <FontAwesomeIcon
                                        color={'#e71d73'}
                                        icon={faShareNodes}
                                        size='lg'
                                        style={{cursor: 'pointer'}}
                                        onClick={shareQRCode}
                                    />
                                    : null}
                            </Space>
                        </div>
                        <QRCodeCanvas
                            id='canvas-qrcode'
                            value={referralLink}
                            level='H'
                            imageSettings={{
                                src: globirdLogo,
                                excavate: true,
                                width: 55,
                                height: 35,
                            }}
                            size={180}
                            className={styles['referral-qr-code']}/>
                    </div>
                    : null}
                {showReferralError ?
                    <div className="field-container" style={{width: '90%'}}>
                        <h2 style={{textAlign: 'center'}}> Unable to generate referral link, please refer to the terms
                            and conditions for eligibility </h2>
                    </div> : null
                }
            </div>
            <div className={styles['terms-and-conditions-container']}>
                <div className={styles['conditions-container']}>
                    <h3>Referral Conditions:</h3>
                    <ol>
                        <li>Residential accounts only (not available to Business)</li>
                        <li>No current outstanding account with GloBird</li>
                        <li>Referral is not available on some plans</li>
                    </ol>
                    <p> The full terms & conditions of this programme can be found &nbsp;
                        <a href='https://www.globirdenergy.com.au/legal/referral-terms-conditions/'
                           rel='noopener noreferrer' target='_blank'>here</a>
                    </p>
                </div>
                <img className={styles['refer-a-friend-img']} src={referWithText} alt='refer a friend'/>
            </div>
        </div>);
}

export default LinkManagement;