import * as React from 'react';
import myAccountBanner from 'assets/myAccountBanner.svg';
import { useLocation, useNavigate } from "react-router-dom";
import GlobirdSelectorOption from 'data/GlobirdSelectorOption';
import GlobirdSelector from 'components/Shared/GlobirdSelector';
import { selectUser, updateCurrentAccount, unsetUser, selectCurrentAccount } from 'store/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import authService from 'services/AuthService';
import styles from './Layout.module.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Account } from 'data/CustomerPortalTypes';
import { ellipsisString } from 'utilities/Utility';
import { ProdHostNames } from 'data/CustomerPortalTypes';
import links from 'data/Links';

type LayoutPropsType = {
    showBackgroundImage?: boolean,
    hideLogout?: boolean,
    hideAccountSelection?: boolean,
    hideReturnDashboard?: boolean,
    title?: string,
    subtitle?: string,
    routed?: boolean
};

const AccountOptionName = (account: Account): string => 
    `Account number: ${account.accountNumber} ${account.accountAddress ? '<' + account.accountAddress + '>' : ''}`;

const navbarBgColor = ProdHostNames.indexOf(window.location.hostname.toLowerCase()) > -1
    ? {}
    : { backgroundColor: 'rgb(168, 193, 178)' };

const RoutedLayout = (props: React.PropsWithChildren<LayoutPropsType>) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const currentAccnt = useSelector(selectCurrentAccount);

    const navigate = useNavigate();

    let { pathname } = useLocation();

    const goBack = () => {
        pathname = pathname.endsWith('/')
            ? pathname.substring(0, pathname.length - 1)
            : pathname;

        pathname = pathname.startsWith('/')
            ? pathname.substring(1, pathname.length - 1)
            : pathname;

        if (!pathname) {
            return;
        }

        var splitted = pathname.split('/');

        splitted.pop();

        navigate(`/${splitted.join('/')}`);
    }

    const logout = async () => {
        if (user?.isImpersonatedUser) {
            window.close();
            return;
        }

        dispatch(unsetUser());
        await authService.logout();
        navigate('/login', { replace: true });
    }

    const changeCurrentAccount = (selectedOption: GlobirdSelectorOption<number>) => {
        // console.log('choose: ', selectedOption);

        dispatch(updateCurrentAccount(selectedOption.value));
    };

    const getAccountOptionList = (): GlobirdSelectorOption<number>[] => {
        if (!user?.accounts?.length) {
            return [];
        }

        return user.accounts
            .map(a => ({
                name: AccountOptionName(a),
                cutName: ellipsisString(AccountOptionName(a), 50, 1),
                value: a.accountId
            }));
    }

    return <>
        <div>
            {
                props.hideLogout === true ? '' :
                    <div className={styles['logout-block']}>
                        <button className={classNames("globird-button-default", styles['logout-button'])} onClick={logout}>
                            <FontAwesomeIcon
                                icon={faPowerOff}
                                style={{ marginRight: '10px' }} />Logout
                        </button>
                    </div>
            }
            <div className={styles['my-account-banner']} style={navbarBgColor}>
                <p className={styles['banner-text']}>
                    <span onClick={() => navigate('/dashboard')}>
                        <img src={myAccountBanner} alt="banner-icon" />My Account
                    </span>
                </p>
                <p className={styles['title']}>{props.title ? props.title : "Take control of your details, bills and usage"}</p>
                <p className={styles['subtitle']}>{props.subtitle ? props.subtitle : ""}</p>
            </div>
        </div>
        <div>
            {
                props.hideAccountSelection === true
                    ? <></>
                    : <div className={styles['account-selector']}>
                        <GlobirdSelector
                            id="accountSelector"
                            options={getAccountOptionList()}
                            onSelected={changeCurrentAccount}
                            value={currentAccnt?.accountId} />
                    </div>
            }
        </div>
        <div>
            {props.children}
        </div>
        <div>
            {
                props.hideReturnDashboard === true ? "" :
                    <div className={styles['dashboard-return-block']}>
                        <div className={styles['dashboard-return']} onClick={goBack}>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                style={{ marginLeft: '13px' }}
                            />
                            <span style={{
                                display: 'inline-block',
                                position: 'relative',
                                left: '15px'
                            }}>Back</span>
                        </div>
                    </div>
            }
        </div>
    </>
}

const UnRoutedLayout = (props: React.PropsWithChildren<LayoutPropsType>) => <div>
    <div className={styles['my-account-banner']}>
        <p className={styles['banner-text']}>
            <span>
                <img src={myAccountBanner} alt="banner-icon" />My Account
            </span>
        </p>
        <p className={styles['title']}>{props.title ? props.title : "Take control of your details, bills and usage"}</p>
        <p className={styles['subtitle']}>{props.subtitle ? props.subtitle : ""}</p>
    </div>
</div>;

export const Layout = (props: React.PropsWithChildren<LayoutPropsType>) => {
    const routed = props.routed ?? true;

    return (
        <div className={
            classNames({
                [styles['layout']]: true,
                [styles['globird-background']]: !!props.showBackgroundImage
            })}>
            {routed ? <RoutedLayout {...props} /> : <UnRoutedLayout />}
            <div style={{ height: '42px' }}></div>
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div style={{
                    minWidth: '300px',
                    maxWidth: '600px',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '0 20px 20px 20px',
                    height: '42px'
                }}>
                    <a target="_blank" href={links.corporateWebsite.privacyPolicy} rel="noreferrer">Privacy Policy</ a>
                    <a target="_blank" href={links.corporateWebsite.legal} rel="noreferrer">Terms of Use</a>
                    <a target="_blank" href={links.corporateWebsite.myAccountFaqSection} rel="noreferrer">FAQs</ a>
                </div>
            </div>
        </div>
    );
}

export default Layout;