import {
    Button,
    Tooltip
} from 'antd';
import { ButtonType } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import classNames from 'classnames';
import { MouseEventHandler, useRef, ReactElement, CSSProperties } from 'react';

const ButtonWithToolTip = ({
    disabled,
    disableMessage,
    enableMessage,
    componentClassName,
    btnClassName,
    btnNode,
    onClick,
    useAntdBtn,
    type,
    size,
    btnStyle = {},
    componentStyle = {},
    htmlType,
    noDefaultBtnClass = false,
    id
}: {
    disabled: boolean,
    disableMessage?: string,
    enableMessage?: string,
    componentClassName?: string[],
    btnClassName?: string[],
    btnNode: ReactElement | string,
    onClick?: MouseEventHandler<HTMLElement>,
    useAntdBtn?: boolean,
    type?: ButtonType,
    btnStyle?: CSSProperties,
    componentStyle?: CSSProperties,
    size?: SizeType,
    htmlType?: ButtonHTMLType,
    noDefaultBtnClass?: boolean,
    id?: string
}) => {
    const btnRef = useRef<HTMLButtonElement>(null);

    if (useAntdBtn) {
        return disabled
            ? <Tooltip
                title={disableMessage}>
                <div
                    className={classNames(componentClassName)}
                    style={{
                        cursor: 'no-drop',
                        ...componentStyle
                    }}>
                    <Button
                        id={id}
                        size={size}
                        className={classNames(btnClassName)}
                        htmlType={htmlType}
                        type={type}
                        style={{
                            margin: 0,
                            pointerEvents: 'none',// chrome bug: mouseLeave(for toolTip) not triggered for disabled button, this act as a walkaround
                            ...btnStyle
                        }}
                        disabled>
                        {btnNode}
                    </Button>
                </div>
            </Tooltip >
            : enableMessage
                ? <Tooltip
                    title={enableMessage}>
                    <div
                        className={classNames(componentClassName)}>
                        <Button
                            id={id}
                            size={size}
                            htmlType={htmlType}
                            onClick={(e) => { btnRef?.current?.blur(); onClick?.(e); }}
                            type={type}
                            style={btnStyle}>
                            {btnNode}
                        </Button>
                    </div>
                </Tooltip>
                : <Button
                    id={id}
                    size={size}
                    htmlType={htmlType}
                    className={classNames(componentClassName, btnClassName)}
                    onClick={(e) => { btnRef?.current?.blur(); onClick?.(e); }}
                    type={type}
                    style={btnStyle}
                >
                    {btnNode}
                </Button>;
    }

    return disabled
        ? <Tooltip
            title={disableMessage}>
            <div
                className={classNames(componentClassName)}
                style={{
                    cursor: 'no-drop',
                    ...componentStyle
                }}>
                <button
                    id={id}
                    className={classNames(
                        { 'globird-button-primary': !noDefaultBtnClass },
                        btnClassName)}
                    style={{
                        width: '100%',
                        margin: 0,
                        pointerEvents: 'none', // chrome bug: mouseLeave(for toolTip) not triggered for disabled button, this act as a walkaround
                        ...btnStyle
                    }}
                    type={htmlType}
                    disabled>
                    {btnNode}
                </button>
            </div>
        </Tooltip>
        : enableMessage
            ? <Tooltip
                title={enableMessage}>
                <div
                    className={classNames(componentClassName)}>
                    <button
                        id={id}
                        style={btnStyle}
                        type={htmlType}
                        className={classNames(
                            { 'globird-button-primary': !noDefaultBtnClass },
                            btnClassName)}
                        onClick={(e) => { btnRef?.current?.blur(); onClick?.(e); }}>
                        {btnNode}
                    </button>
                </div>
            </Tooltip>
            : <button
                id={id}
                style={btnStyle}
                type={htmlType}
                className={classNames(
                    { 'globird-button-primary': !noDefaultBtnClass },
                    componentClassName,
                    btnClassName)}
                onClick={(e) => { btnRef?.current?.blur(); onClick?.(e); }}>
                {btnNode}
            </button>;
};

export default ButtonWithToolTip;
