import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SectionMenu from "../SectionMenu";
import styles from "./ElectricityReconnection.module.css";
import { Table, TablePaginationConfig } from "antd";
import { formatDisplayDate } from "utilities/Utility";
import ButtonWithToolTip from "components/Shared/ButtonWithToolTip";
import ReconnectionRetry from "./RetryForm/ReconnectionRetry";
import {
  AccountServiceReenDetailDto, ReconnectionStatus
} from 'data/CustomerPortalTypes';
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAccount } from "store/userSlice";
import { fetchReconnectionInfo, selectCurrentAccountReconnectionInfo } from "store/reconnectionInfoSlice";

const defaultPagination: TablePaginationConfig = {
  pageSize: 5,
  showSizeChanger: false,
  total: 0,
  position: ["bottomRight"],
  size: "default",
};

const ElectricityReconnection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectCurrentAccount);
  const currentAccountReconnectionInfo = useSelector(selectCurrentAccountReconnectionInfo)

  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [selectedSite, setSelectedSite] = useState<AccountServiceReenDetailDto | undefined>(undefined);
  const [data, setData] = useState<AccountServiceReenDetailDto[]>([]);

  const columns = [
    {
      title: "NMI",
      dataIndex: "nmi",
      key: "nmi",
    },
    {
      title: "Address",
      dataIndex: "siteAddress",
      key: "siteAddress",
      render: (address: string) => (
        <div className={styles["site-address-column"]}>{address}</div>
      ),
    },
    {
      title: "Reconnection Date",
      dataIndex: "reenDate",
      key: "reenDate",
      render: (date: string) => <div>{formatDisplayDate(date)}</div>,
    },
    {
      title: "Requested By",
      dataIndex: "requestBy",
      key: "requestBy",
      render: (_: any, row: AccountServiceReenDetailDto) =>
        <div>
          {row.requestBy}
          ({formatDisplayDate(row.requestOn)})
        </div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: AccountServiceReenDetailDto) => (
        <ButtonWithToolTip
          disabled={row.status !== ReconnectionStatus.Failed}
          disableMessage={row.status === ReconnectionStatus.Pending ?
            "Your reconnection request is being processed"
            : "Your reconnection request has been completed"}
          type="primary"
          useAntdBtn={true}
          btnStyle={{ fontSize: "1rem" }}
          btnNode="Retry"
          onClick={() => {
            setSelectedSite(row);
            navigate('/reconnection/retry')
          }}
        />
      ),
    },
  ];

  useEffect(() => {

    if (!currentAccount) {
      return;
    }

    if (!currentAccountReconnectionInfo) {
      dispatch(fetchReconnectionInfo(currentAccount.services.map(x => x.accountServiceId)))
      return;
    }

    if (!currentAccountReconnectionInfo.displayReconnectionPage) {
      navigate('/Dashboard');
    }

    setData(currentAccountReconnectionInfo.accountServiceReenDetailDtos);
    setPagination({
      ...pagination,
      total: data.length
    });
  }, [currentAccount, currentAccountReconnectionInfo]);

  return (
    <div className="form-container" style={{ maxWidth: "1200px" }}>
      <div className="globird-menu-option">
        <p className="globird-menu-label">Show me: </p>
        <SectionMenu
          id="reconnectionMenuSelector"
          defaultIndex={1}
        />
      </div>
      <Routes>
        <Route path="/" element={
          <div className="field-container" style={{ width: "90%" }}>
            <div className={styles["reconnection-panel"]}>
              <p className={styles["reconnection-heading"]}>
                Electricity Reconnection
              </p>
              <p className={styles["reconnection-heading-description"]}>
                Use this option if your electricity reconnection was unsuccessful,
                and you are ready for another attempt.
              </p>
              <Table
                columns={columns}
                dataSource={data}
                pagination={pagination}
                scroll={{ x: "max-content" }}
                className={styles["reconnection-table"]}
                rowKey={(row: AccountServiceReenDetailDto) => row.accountServiceId}
              />
            </div>
          </div>
        }></Route>
        <Route path="retry" element={
          <ReconnectionRetry
            accountServiceReenDetailDto={selectedSite}
          />
        }></Route>
      </Routes>
    </div>
  );
};

export default ElectricityReconnection;
