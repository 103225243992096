import { Form, Input, Button } from 'antd';
import { resetPassword, resetPasswordTokenMeta } from 'services/AuthService';
import {
    CommonPasswordValidator,
    PasswordUsernameOverlapStaticValidator,
    RepeatPasswordValidator
} from 'utilities/Validators'
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { generateRequiredRule as genRqdRule } from 'utilities/Utility';
import ActionResultPanel from 'components/ActionResultPanel';
import { useForm } from 'antd/lib/form/Form';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Item } = Form;

const ResetPassword = () => {
    const [resetSuccess, setResetSucceess] = useState<boolean | null>(null);
    const [tokenMetaResult, setTokenMetaResult] = useState<boolean | null>(null);
    const [preventSubmit, setPreventSubmit] = useState<boolean>(false);
    const location = useLocation();

    const query = queryString.parse(location.search);

    const token = query['token'];
    const email = query['email'];

    const [form] = useForm();

    useEffect(() => {
        if (!token
            || !email
            || Array.isArray(token)
            || Array.isArray(email)) {

            setResetSucceess(false);

            return;
        }

        const getMeta = async () => {
            const tokenMeta = await resetPasswordTokenMeta(token);

            if (tokenMeta === null) {
                setResetSucceess(false); // token not valid
            }
            else if (tokenMeta.data) {
                setTokenMetaResult(true);
            }
            else {
                setTokenMetaResult(false);
            }
        }

        getMeta();

    }, [email, token]);

    if (tokenMetaResult === null
        && resetSuccess === null) {
        return <></>;
    }

    const onFinish = (values: any) => {

        if (preventSubmit) {
            return;
        }

        const submit = async () => {

            const resetResult = await resetPassword(
                email as string,
                token as string,
                values["newpassword"]);

            if (resetResult?.data) {
                setResetSucceess(true);
            }
            else if (resetResult?.data === false) { // not sure why this would ever happen
                form.setFields([
                    { name: 'newpassword', errors: ['Please check your password'] },
                    { name: 'newpasswordrepeat', errors: [''] }
                ]);

                setPreventSubmit(true);
            }
            else {
                setResetSucceess(false);
            }
        };

        submit();
    };

    return (
        <div className="login">
            <div className="globird-form">
                {resetSuccess && <ActionResultPanel status="success">
                    Your have successfully reset the password for &nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        {email}
                    </span>
                </ActionResultPanel>}
                {resetSuccess === false && <ActionResultPanel status="error">
                    Oops! Something went wrong, please try again later or contact our customer service for help.
                </ActionResultPanel>}
                {resetSuccess === null && !tokenMetaResult && <ActionResultPanel status="warning">
                    Oops! It seems the link is expired, <Link className="globird-link" to="/resetpasswordrequest">click here</Link> to get another one.
                </ActionResultPanel>}
                {resetSuccess === null && tokenMetaResult && <Form
                    name="resetPassword"
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                    onFieldsChange={() => setPreventSubmit(false)}>

                    <div className="globird-form-title">
                        <FontAwesomeIcon
                            icon={faUnlockAlt}
                            style={{ marginRight: '10px' }} />Reset Password For:<br />
                        <span style={{ fontWeight: 'bold' }}>
                            {email}
                        </span>
                    </div>
                    <Item
                        name="newpassword" id="resetPasswordNew"
                        label={<label className="globird-form-field-label">Your New Password</label>}
                        rules={[
                            genRqdRule('Password'),
                            CommonPasswordValidator(),
                            PasswordUsernameOverlapStaticValidator(email as string, 'email address')
                        ]}>
                        <Input.Password type="password" allowClear style={{ width: "100%" }} />
                    </Item>
                    <Item
                        name="newpasswordrepeat" id="resetPasswordNewRepeat"
                        label={<label className="globird-form-field-label">Repeat Your New Password</label>}
                        rules={[
                            genRqdRule(null, 'Please repeat the new password.'),
                            RepeatPasswordValidator('newpassword', 'newpasswordrepeat')
                        ]}>
                        <Input.Password type="password" allowClear style={{ width: "100%" }} />
                    </Item>
                    <Item>
                        <Button
                            className="globird-button-primary"
                            htmlType="submit"
                            disabled={preventSubmit}
                            style={{ width: '100%' }}>Submit</Button>
                    </Item>
                </Form>}
                <div className="router-block">
                    <p><Link className="globird-link" to="/login">Click here</Link> to login</p>
                </div>
            </div >
        </div>
    );
}

export default ResetPassword;