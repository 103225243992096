import { useLocation, useNavigate } from 'react-router-dom';
import * as queryString from 'query-string';
import { Button, Form, Input, notification } from 'antd';
import {
    isEnumName,
    enumFromName,
    getRuleObject,
    formatJsonDate,
    generateRequiredRule as genRqdRule,
    useCountdown,
    getCountdownName,
    workoutCountDownTimeStr
} from 'utilities/Utility';
import {
    CommonPasswordValidator,
    PasswordUsernameOverlapStaticValidator,
    RepeatPasswordValidator
} from 'utilities/Validators';
import { getAccountActivateMeta, sendEmailVerificationCode } from 'services/RequestDataService';
import { activateAccount } from 'services/AuthService';
import { useEffect, useState } from 'react';
import { ActivateAccount, ActivationMetaOutcome, ActivationOutcome, VerificationCodePurpose, VerificationCodeType } from 'data/CustomerPortalTypes';
import { Link } from 'react-router-dom';
import DatePicker from 'components/Shared/DatePicker';
import ActionResultPanel from 'components/ActionResultPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import classNames from 'classnames';
import trackEvent from 'services/AnalyticsService';

const { Item } = Form;

enum ActivateType {
    Email = "Email",
    Sms = "Sms"
};

const dobInputId = 'activateAccountDobInput';

let submitted = false;

const AccountActivate = () => {
    const [form] = Form.useForm<ActivateAccount>();
    const [activMetaResult, setActivMetaResult] = useState<ActivationMetaOutcome | null>(null);
    const [activationResult, setActivationResult] = useState<null | ActivationOutcome>(null);
    const [invalidDob, setInvalidDob] = useState<string[]>([]);
    const [emailVerificationCorrelationId, setEmailVerificationCorrelationId] = useState<string | null>(null);
    const [skipEmailVerification, setSkipEmailVerification] = useState<boolean>(false);

    const { search: query } = useLocation();
    const navigate = useNavigate();
    const { emailId, phoneId, emailAddress, token, actType: activateTypeStr, dateOfBirth, sevToken } =
        queryString.parse(query);
    
    const dateOfBirthString = dateOfBirth as string;
    const dateOfBirthValue = dateOfBirthString && moment(dateOfBirthString).isValid() ? moment(dateOfBirthString) : null;

    const [emailCountdownLeft, startEmailCountdown] = useCountdown(
        getCountdownName(VerificationCodeType.Email, VerificationCodePurpose.VerifyEmailAddress), 60)

    useEffect(() => {
        const func = async () => {

            if (sevToken as string) {
                setSkipEmailVerification(true);
            }

            const metaResult = await getAccountActivateMeta(
                emailAddress as string,
                emailId as string,
                token as string,
                sevToken as string);

            if (metaResult?.data === ActivationMetaOutcome.UserUnavailable) {
                navigate('/unavailable', { replace : true, state : { unavailableFlag : true } });
            }

            setActivMetaResult(
                metaResult?.data
                ?? ActivationMetaOutcome.InvalidParams);
        };

        func();
    }, [emailAddress, emailId, token, form, sevToken]);

    if (Array.isArray(emailId)
        || Array.isArray(phoneId)
        || Array.isArray(emailAddress)
        || Array.isArray(token)
        || Array.isArray(activateTypeStr)
        || Array.isArray(sevToken)
        || !token
        || (!emailAddress && !emailId)
        || !activateTypeStr
        || !isEnumName(activateTypeStr as string, ActivateType)) {

        setActivMetaResult(ActivationMetaOutcome.InvalidParams);
    }

    const activateType = enumFromName(activateTypeStr as string, ActivateType);

    if ((activateType === ActivateType.Email
        && !emailAddress)
        || (activateType === ActivateType.Sms
            && !phoneId)) {

        setActivMetaResult(ActivationMetaOutcome.InvalidParams);
    }

    const onSubmit: (event?: React.MouseEvent<Element, MouseEvent>) => void = async (e) => {
        e?.preventDefault();

        if (submitted) {
            return;
        }

        try {
            submitted = true;

            const formValues = await form.validateFields();

            if (!skipEmailVerification && !emailVerificationCorrelationId) {
                notification.error({
                    message: 'Error',
                    description: 'Unable to activate the account, please try again later or contact our customer service for help.'
                });

                return;
            }

            const actResult = await activateAccount(
                {
                    ...formValues,
                    DateOfBirth: formatJsonDate(
                        formValues.DateOfBirth),
                    EmailVerificationCorrelationId: emailVerificationCorrelationId,
                    sevToken: sevToken as string
                });

            if (!actResult?.data) {
                notification.error({
                    message: 'Error',
                    description: 'Unable to activate the account, please try again later or contact our customer service for help.'
                })

                return;
            }

            setActivationResult(actResult.data);

            if (actResult.data === ActivationOutcome.InvalidInfo) {

                form.setFields(dobError);

                setInvalidDob([
                    ...invalidDob,
                    formatJsonDate(formValues.DateOfBirth)!]);
            }

            if (actResult.data === ActivationOutcome.InvalidPassword) { // should never happen

                form.setFields(pwdError);
            }

            if (actResult.data === ActivationOutcome.InvalidVerificationCode) {
                form.setFields(emailVerificationCodeError);
            }

            if (actResult.data === ActivationOutcome.Succeeded) {
                const { referrer } = document;

                if (referrer && (
                    referrer.includes("quote.globirdenergy.com.au") ||
                    referrer.includes("signup.globirdenergy.com.au"))) {
                        trackEvent("navigate", "account_registration", "navigate_from_oss");
                }
            }
        } finally {
            submitted = false;
        }
    }

    const sendVerifyEmailCode = async () => {
        const codeResult = await sendEmailVerificationCode(
            {
                emailAddress: emailAddress as string,
            });

        const { data, success } = codeResult || {};

        if (success && data) {
            setEmailVerificationCorrelationId(data);
            startEmailCountdown();
            
            notification.success({
                message: 'Success',
                description: 'Verification code sent successfully.'
            });
        }
    }

    if (activMetaResult === null) {
        return <></>;
    }

    return (
        <div className="login">
            <div className="globird-form">
                {
                    (activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo
                        || activMetaResult === ActivationMetaOutcome.RequireMoreInfo)
                    && (activationResult === null
                        || activationResult === ActivationOutcome.InvalidInfo
                        || activationResult === ActivationOutcome.InvalidPassword
                        || activationResult === ActivationOutcome.InvalidVerificationCode)
                    && <Form
                        form={form}
                        initialValues={{
                            EmailAddress: emailAddress ?? null,
                            // EmailId: emailId ?? null,
                            Token: token,
                            // FirstName: null,
                            // LastName: null,
                            PhoneId: phoneId ?? null,
                            Password: '',
                            ConfirmPassword: '',
                            DateOfBirth: dateOfBirthValue
                        }}
                        onKeyDown={e => {
                            if (e.code === 'Enter') {
                                e.preventDefault();
                                onSubmit();
                            }
                        }}
                        name="login"
                        layout="vertical"
                        // validateTrigger="onBlur"
                        className="login-form">
                        <div className="globird-form-title">
                            <FontAwesomeIcon
                                icon={faKey}
                                style={{ marginRight: '10px', transform: 'scaleX(-1)' }} />
                            Activate My Account
                        </div>
                        <Item
                            name="EmailAddress"
                            label={<label className="globird-form-field-label">Email address</label>}
                            rules={[
                                { type: "email", message: 'Email address is not valid.' },
                                getRuleObject((val) => {
                                    if (activateType === ActivateType.Email && val) {
                                        return null;
                                    }

                                    if (!val) {
                                        return 'Email address is required.';
                                    }

                                    return null;
                                })]}>
                            {
                                activateType === ActivateType.Email
                                    ? <Input type="text" readOnly value={emailAddress as string} style={{ width: "100%", border: 'none' }} autoComplete="email" />
                                    : <Input type="text" allowClear style={{ width: "100%" }} autoComplete="email" />
                            }
                        </Item>

                        <Item
                            name="Token"
                            hidden>
                            <Input type="text" autoComplete="off" />
                        </Item>

                        <Item
                            name="PhoneId"
                            hidden>
                            <Input type="text" autoComplete="off" />
                        </Item>

                        {skipEmailVerification ? null :
                            <Item
                                name="EmailVerificationCode"
                                required
                                label={<label className="globird-form-field-label">Email Verification Code</label>}
                                rules={[genRqdRule('Email verification code')]}>
                                <Input
                                    style={{ width: "100%" }}
                                    className={classNames('input-with-button-hidden')}
                                    disabled={!emailVerificationCorrelationId}
                                    addonAfter={
                                        <Button
                                            key="send-verify-email-code"
                                            disabled={emailCountdownLeft > 0}
                                            className="inline-verify-button"
                                            onClick={sendVerifyEmailCode}>
                                            {emailCountdownLeft <= 0
                                                ? 'Send code'
                                                : `Send code after (${workoutCountDownTimeStr(emailCountdownLeft)})`}
                                        </Button>
                                    }
                                />
                            </Item>
                        }

                        {/* <Item
                            name="FirstName"
                            hidden={activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo}
                            label={<label className="globird-form-field-label">First name</label>}
                            rules={[getRuleObject((val) => {
                                if (activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo) {
                                    return null;
                                }

                                if (!val) {
                                    return 'First name is required.';
                                }

                                return null;
                            })]}>
                            <Input
                                type="text"
                                allowClear
                                style={{ width: "100%" }} />
                        </Item>

                        <Item
                            name="LastName"
                            hidden={activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo}
                            label={<label className="globird-form-field-label">Last name</label>}
                            rules={[getRuleObject((val) => {
                                if (activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo) {
                                    return null;
                                }

                                if (!val) {
                                    return 'Last name is required.';
                                }

                                return null;
                            })]}>
                            <Input
                                type="text"
                                allowClear
                                style={{ width: "100%" }} />
                        </Item> */}

                        <Item
                            name="DateOfBirth"
                            hidden={activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo}
                            label={<label className="globird-form-field-label">Date of birth</label>}
                            required={activMetaResult !== ActivationMetaOutcome.NoNeedMoreInfo}
                            rules={[
                                getRuleObject((val) => {
                                    if (activMetaResult === ActivationMetaOutcome.NoNeedMoreInfo) {
                                        return null;
                                    }

                                    if (!val) {
                                        return 'Date of birth is required.';
                                    }

                                    const jsonDate = formatJsonDate(val);

                                    if (invalidDob.some(d => d === jsonDate)) {
                                        return dobError[0].errors[0];
                                    }

                                    return null;
                                })]}>
                            <DatePicker
                                style={{ width: "100%" }}
                                allowClear
                                id={dobInputId}
                                autoComplete="off"
                                picker="date"
                                defaultPickerValue={moment('1990-01-01')} // default show 20th century
                                disabled={dateOfBirthValue !== null}
                            />
                        </Item>
                        <Item
                            name="Password"
                            label={<label className="globird-form-field-label">Password</label>}
                            rules={[
                                CommonPasswordValidator(0),
                                genRqdRule('Password'),
                                PasswordUsernameOverlapStaticValidator(emailAddress as string, 'email address')
                            ]}>

                            <Input.Password
                                autoComplete="new-password"
                                type="password"
                                allowClear
                                style={{ width: "100%" }} />
                        </Item>

                        <Item
                            name="ConfirmPassword"
                            label={<label className="globird-form-field-label">Confirm password</label>}
                            rules={[
                                genRqdRule(null, 'Please repeat your password here.'),
                                RepeatPasswordValidator('Password', 'ConfirmPassword')]}>
                            <Input.Password type="password" allowClear style={{ width: "100%" }} autoComplete="off" />
                        </Item>

                        <Item>
                            <button
                                className="globird-button-primary"
                                style={{ width: '100%' }}
                                onClick={onSubmit}>
                                Activate
                            </button>
                        </Item>
                    </Form>
                }
                {
                    activationResult === ActivationOutcome.Succeeded && <ActionResultPanel status="success">
                        Congratulations!
                        <br />
                        <span>Your GloBird account </span>
                        <span style={{ fontWeight: 'bold' }}>
                            {emailAddress}
                        </span> is ready for use.
                        <br />
                        Click <Link to="/login">here</Link> to login.
                    </ActionResultPanel>
                }
                {
                    activMetaResult === ActivationMetaOutcome.Activated && <ActionResultPanel status="info">
                        It seems you are already with us.
                        <br />
                        Click <Link to="/login">here</Link> to login.
                    </ActionResultPanel>
                }
                {
                    (activationResult === ActivationOutcome.Errored || activMetaResult === ActivationMetaOutcome.InvalidParams) && <ActionResultPanel status="error">
                        Oops! Something went wrong, please try again later or contact our customer service for help.
                    </ActionResultPanel>
                }
                {
                    (activMetaResult === ActivationMetaOutcome.LinkExpired) && <ActionResultPanel status="warning">
                        Oops! It seems your link was expired.
                        <br />
                        Click <Link to="/register">here</Link> to register manually.
                    </ActionResultPanel>
                }
                {
                    (activationResult === ActivationOutcome.MaxAttemptReached || activMetaResult === ActivationMetaOutcome.MaxAttemptReached) && <ActionResultPanel status="warning">
                        You have reached the maximum activation attempt limit, please contact our customer service for help.
                    </ActionResultPanel>
                }
            </div>
        </div>
    );
}

export default AccountActivate;

const dobError: { name: keyof ActivateAccount, errors: string[] }[] = [
    // {
    //     name: 'FirstName',
    //     errors: ['']
    // },
    // {
    //     name: 'LastName',
    //     errors: ['']
    // },
    {
        name: 'DateOfBirth',
        errors: ['Please check the date of birth.']
    }
];

const pwdError: { name: keyof ActivateAccount, errors: string[] }[] = [
    {
        name: 'Password',
        errors: ['Please confirm your password meet the requirement.']
    },
    // {
    //     name: 'ConfirmPassword',
    //     errors: ['']
    // }
];

const emailVerificationCodeError: { name: keyof ActivateAccount, errors: string[] }[] = [
    {
        name: 'EmailVerificationCode',
        errors: ['Please enter the correct verification code.']
    }
];