import {
    configureStore,
    // getDefaultMiddleware
} from '@reduxjs/toolkit';
import userReducer from './userSlice';
import transactionReducer from './transactionSlice';
import requestReducer from './requestSlice';
import directDetbitInfoReducer from './directDebitInfoSlice';
import reconnectionInfoReducer from './reconnectionInfoSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        transation: transactionReducer,
        requestStarted: requestReducer,
        directDebit: directDetbitInfoReducer,
        reconnectionInfo: reconnectionInfoReducer
    },
    // middleware: getDefaultMiddleware({
    //     serializableCheck: false
    // }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;