import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState = false;

const requestSlice = createSlice({
    name: 'request',
    initialState: initialState,
    reducers: {
        startRequest(state, action: PayloadAction<void>) {
            return true;
        },
        endRequest(state, action: PayloadAction<void>) {
            return false;
        },
    }
});

export default requestSlice.reducer;

export const { startRequest, endRequest } = requestSlice.actions;

export const selectRequestStarted = (state: RootState) =>
    state.requestStarted;