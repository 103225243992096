import { useNavigate } from 'react-router-dom';
import styles from './Moveout.module.css'
import moment from 'moment';
import {
    Form,
    Checkbox,
    Table,
    TablePaginationConfig,
    Button,
    notification,
    Modal,
    List,
    Divider
} from 'antd'
import { useEffect, useState, useMemo } from 'react';
import {
    AccountServiceMoveOutDetailDto,
    AccountServiceMoveOutDto,
    AccountServiceStatusForMoveOut,
    DefaultDisplayDateFormat
} from 'data/CustomerPortalTypes';
import { useSelector } from 'react-redux';
import { selectCurrentAccount } from 'store/userSlice';
import { submitMoveOutAsync, getDetailsForMoveOutAsync, getPublicHolidays } from 'services/RequestDataService';
import SectionMenu from '../SectionMenu';
import { ServiceType, getServiceType, today } from 'utilities/Utility';
import supperManImg from 'assets/globirdSuperman1.png';
import DatePicker from 'components/Shared/DatePicker';
import { Moment } from 'moment';
import classNames from 'classnames'
import { generateRequiredRule as genRqdRule, getRuleObject } from 'utilities/Utility';
import { TableRowSelection } from 'antd/lib/table/interface';
import { addBusinessDays, isDisabledDate } from 'utilities/DateTool';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleInfo, faXmark, faCircle } from '@fortawesome/free-solid-svg-icons';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import { FieldData } from "rc-field-form/lib/interface";
import links from 'data/Links';

const { Item } = Form;

const defaultPagination: TablePaginationConfig = {
    pageSize: 10,
    showSizeChanger: false,
    total: 0,
    position: ['bottomRight'],
    size: "default"
};

const showChristmasWarning = () => {
    let christmasWarningPeriodStart = moment("15 Nov", "DD MMM");
    let christmasWarningPeriodEnd = moment("31 Dec", "DD MMM");

    return moment().isBetween(christmasWarningPeriodStart, christmasWarningPeriodEnd);
}

const computeDisabledMoveoutDate = (
    currentDate: Moment,
    moDto: AccountServiceMoveOutDetailDto) => {
    return isDisabledDate(currentDate, moDto.calendarControlDto);    
}

const getItemNameForMoveOutDate = (accountServiceId: number) => {
    return "MD_" + accountServiceId.toString();
}

const validateMoveOutDateValue = (
    value: Moment,
    moDto: AccountServiceMoveOutDetailDto) => {

    if (!value) {
        return null;
    }

    return computeDisabledMoveoutDate(value, moDto)
        ? "Invalid Date"
        : null;
}

const Moveout = () => {
    const currentAccount = useSelector(selectCurrentAccount);
    const [moveOutAccountServices, setMoveOutAccountServices] = useState(new Array<AccountServiceMoveOutDetailDto>());
    const [selectedAccountServiceRows, setSelectedAccountServiceRows] = useState(new Array<AccountServiceMoveOutDetailDto>());
    const [moveOutSubmissions, setMoveOutSubmissions] = useState(new Map<number, AccountServiceMoveOutDto>());
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
    const navigate = useNavigate();
    const [showMoveOutConfirmationModal, setShowMoveOutConfirmationModal] = useState<boolean>(false);
    const [showMultipleServicesConfirmationModal, setShowMultipleServicesConfirmationModal] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [agreementAccepted, setAgreementAccepted] = useState(false);
    const [formErrored, setFormErrored] = useState(false);
    const [showSelectedServicesOnly, setShowSelectedServicesOnly] = useState(false);
    const [showAcknowledgementModal, setShowAcknowledgementModal] = useState(false);
    const [filteredServicesForDisplay, setFilteredServicesForDisplay] = useState(new Array<AccountServiceMoveOutDetailDto>());
    const [publicHolidays, setPublicHolidays] = useState<Map<string, string[]> | undefined>(undefined);

    const columns = [
        {
            title: 'NMI / MIRN',
            dataIndex: 'nmi',
            key: 'nmi',
        },
        {
            title: 'Fuel Type',
            dataIndex: 'nmi',
            key: 'serviceType',
            render: (nmi: string) => <>{getServiceType(nmi).toString()}</>,
        },
        {
            title: 'Address',
            dataIndex: 'siteAddress',
            key: 'siteAddress',
            render: (address: string) =>
                <div className={styles['site-address-column']}>
                    {address}
                </div>
        },
        {
            title: 'Status',
            dataIndex: 'statusForDisplay',
            key: 'statusForDisplay',
            render: (status: string) =>
                <div className={styles['site-status-column']}>
                    {(AccountServiceStatusForMoveOut as any)[status]}
                </div>
        },
        {
            title: 'Move Out Date*',
            dataIndex: 'moveOutDate',
            key: 'moveOutDate',
            render: (date: string | null | Moment, moDto: AccountServiceMoveOutDetailDto) => renderDatePicker(date, moDto),
        },
        {
            title: 'Fee',
            dataIndex: 'moveOutFee',
            key: 'moveOutFee',
            render: (moveOutFee: string) => <>{moveOutFee}</>,
        },
    ];

    useEffect(() => {
        var servicesForDisplay = moveOutAccountServices;
        if (showSelectedServicesOnly) {
            servicesForDisplay = moveOutAccountServices.filter(x => selectedAccountServiceRows.map(x => x.accountServiceId).includes(x.accountServiceId));
        }
        setFilteredServicesForDisplay(servicesForDisplay);
        setPagination({
            ...defaultPagination,
            total: servicesForDisplay.length
        });
    }, [showSelectedServicesOnly, moveOutAccountServices, selectedAccountServiceRows])

    const updateMoveOutSubmission = (accountServiceId: number, date: Moment | null) => {

        if (!date) {
            return;
        }

        const submissions = new Map(moveOutSubmissions);

        submissions.set(accountServiceId, {
            accountServiceId: accountServiceId,
            moveOutDate: date
        })

        setMoveOutSubmissions(submissions);
    }

    const renderDatePicker = (date: string | null | Moment, moDto: AccountServiceMoveOutDetailDto) => {

        if (date) {
            return moment(date).format(DefaultDisplayDateFormat)
        }

        return selectedAccountServiceRows.find(x => x.accountServiceId === moDto.accountServiceId)
            ? <Item
                name={getItemNameForMoveOutDate(moDto.accountServiceId)}
                className={styles['move-out-date-item']}
                rules={[
                    genRqdRule(null, 'Please select a date.'),
                    getRuleObject(value => validateMoveOutDateValue(value, moDto))
                ]}>
                <DatePicker
                    style={{ width: "100%" }}
                    id="moveOutDatePicker"
                    autoComplete="off"
                    picker="date"
                    mode="date"
                    defaultPickerValue={today()}
                    onChange={newDate => updateMoveOutSubmission(moDto.accountServiceId, newDate)}
                    disabledDate={date => computeDisabledMoveoutDate(date, moDto)}
                />
            </Item>
            : <div className={styles['row-selection-check']}>
                To choose the move-out date for this service, tick the box on the left
            </div>;
    }

    const onFinish = async (_: any) => {
        if (!currentAccount) {
            return;
        }

        //Show additional confirmation if the user selected multiple move out dates
        var singleMoveOutDate = [...moveOutSubmissions.values()].every((val, _, arr) => val.moveOutDate.toString() === arr[0].moveOutDate.toString())

        if (!singleMoveOutDate) {
            setShowMultipleServicesConfirmationModal(true);
        } else {
            setShowMoveOutConfirmationModal(true);
        }
    };

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {

        let errored = false;

        for (let field of allFields) {
            let fieldName = (field.name as unknown as string[])[0];
            if (field.errors?.length
                && field.errors?.length > 0) {
                errored = true;
            }
            if (fieldName !== 'selectedServicesOnlyToggle' && !field.value) {
                errored = true;
            }
        }

        if (selectedAccountServiceRows.length > moveOutSubmissions.size) {
            errored = true;
        }
        moveOutSubmissions.forEach(moDto => {
            if (!moDto.moveOutDate) {
                errored = true;
            }
        })

        setFormErrored(errored);
        setAgreementAccepted(form.getFieldValue("agreementAcceptCheckbox"));
        setShowSelectedServicesOnly(form.getFieldValue("selectedServicesOnlyToggle"));
    }

    const submitMoveOutRequest = async () => {
        const res = await submitMoveOutAsync(moveOutSubmissions,currentAccount!.accountId);

        if (res?.success) {
            if (res.data !== null) {
                //Inform user of failed move out for some services
                notification.error({
                    message: 'Failure',
                    description: 'Failed to request move out for some sites. Please contact Customer Service'
                });
                clearForm();
            }
            else {
                //Handle success in different modal
                setShowAcknowledgementModal(true);
            }
        }
        else {
            notification.error({
                message: 'Failure',
                description: 'Move out request failed.'
            });
        }
    };

    const clearForm = () => {
        refreshMoveoutDetailAsync();
        setSelectedAccountServiceRows(new Array<AccountServiceMoveOutDetailDto>());
        setShowSelectedServicesOnly(false);
        form.resetFields();
    }

    const moveOutFormValidation: string = useMemo(() => {

        if (!selectedAccountServiceRows.length) {
            return "Please select at least one service."
        }

        if (!agreementAccepted) {
            return "Please agree to the terms."
        }

        if (formErrored) {
            return "Please fill in all the details."
        }

        return "";
    }, [selectedAccountServiceRows, agreementAccepted, formErrored])

    const moveOutSubmitButtonDisabled = useMemo(() => {
        return !(selectedAccountServiceRows.length > 0 && agreementAccepted && !formErrored);
    }, [selectedAccountServiceRows, agreementAccepted, formErrored])

    const refreshMoveoutDetailAsync = async () => {
        if (!currentAccount) {
            return;
        }

        const mvoutDetRes = await getDetailsForMoveOutAsync(
            currentAccount.services.map(x => x.accountServiceId));

        if (!mvoutDetRes?.success || !mvoutDetRes.data) {
            return;
        }

        const result = [...mvoutDetRes.data.values()]
            .sort((a, b) => a.accountServiceId - b.accountServiceId);

        setMoveOutAccountServices(result);

        return result;
    };

    useEffect(() => {

        if (!currentAccount) {
            return;
        }

        setLoading(true);

        form.resetFields();

        setMoveOutSubmissions(new Map<number, AccountServiceMoveOutDto>());
        setSelectedAccountServiceRows(new Array<AccountServiceMoveOutDetailDto>());
        setShowMoveOutConfirmationModal(false);
        setShowMultipleServicesConfirmationModal(false);
        setFilteredServicesForDisplay(new Array<AccountServiceMoveOutDetailDto>());
        setShowSelectedServicesOnly(false);

        const asyncAction = async () => {
            try {
                const [mvoutDet] = await Promise.all([
                    await refreshMoveoutDetailAsync(),
                ]);

                if (!mvoutDet) {
                    return;
                }
            }
            finally {
                setLoading(false)
            }
        }

        asyncAction();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount]);

    useEffect(() => {
        const fetchPublicHolidays = async () => {
            const publicHolidaysResponse = await getPublicHolidays();
            if (publicHolidaysResponse?.success && publicHolidaysResponse.data) {
                setPublicHolidays(publicHolidaysResponse.data);
            }
        }

        fetchPublicHolidays();
    }, []);

    const removeUncheckedSubmission = (selectedRowKeys: number[]) =>
        setMoveOutSubmissions(
            new Map([...moveOutSubmissions].filter(([k, _]) => selectedRowKeys.some(x => x === k))));
    
    const lifeSupportWarningText = (stateCode: string, isBasicMeter: boolean) => {
        const publicHolidayList = publicHolidays?.get(stateCode) ?? [];

        const finalBillEstimatedDate = addBusinessDays(moment(), 20, publicHolidayList).format(DefaultDisplayDateFormat);

        return (
            <div>
                <p>Please be aware that life support protection is active on your site. 
                    To initiate disconnection, Life Support De-Registration is mandatory. 
                    You will receive a de-registration notice at your nominated email address soon.
                </p>
                <br />
                <p>
                    {isBasicMeter ? <b>Please provide us a self-read meter photo on the suggested move-out date. <br /></b> : ''}
                    <b>We will bill up to your suggested move-out date. </b>
                </p>
                <p>
                    However, it's important to understand that the final bill will be issued after the conclusion of the life support protection period and completion of the disconnection process. 
                    This is estimated to occur on approximately {finalBillEstimatedDate}
                </p>
            </div>
        );
    }

    const rowSelection: TableRowSelection<AccountServiceMoveOutDetailDto> = {
        onChange: (_, selectedRows) => {
            setSelectedAccountServiceRows(selectedRows);
            removeUncheckedSubmission(selectedRows.map(x => x.accountServiceId));
            if (selectedRows.length > moveOutSubmissions.size) {
                setFormErrored(true);
            }
            moveOutSubmissions.forEach(moDto => {
                if (!moDto.moveOutDate) {
                    setFormErrored(true);
                }
            })
        },
        getCheckboxProps: (site) => ({ disabled: site.moveOutDate !== null }),
        selectedRowKeys: selectedAccountServiceRows.map(x => x.accountServiceId),
        hideSelectAll: true,
        onSelect: (row, selected) => {
            if (selected) {
                if (row.hasLifeSupport && getServiceType(row.nmi) === ServiceType.Power)
                {
                    Modal.confirm({
                        title: 'Life Support',
                        content: lifeSupportWarningText(row.state, row.isBasicMeter),
                        okText: 'Confirm',
                        onCancel: () => {
                            // uncheck current row in the table
                            const selectedRowKeys = selectedAccountServiceRows.map(x => x.accountServiceId);
                            const currentRowIndex = selectedRowKeys.indexOf(row.accountServiceId);
                            selectedRowKeys.splice(currentRowIndex, 1);
                            setSelectedAccountServiceRows(moveOutAccountServices.filter(x => selectedRowKeys.includes(x.accountServiceId)));
                            return;
                        },
                        width: 700
                    });
                }
                return;
            }

            form.setFieldsValue({ [getItemNameForMoveOutDate(row.accountServiceId)]: null});
            form.validateFields();
        }
    };

    const handleOk = async () => {
        setConfirmLoading(true);

        await submitMoveOutRequest();

        setConfirmLoading(false);
        setShowMoveOutConfirmationModal(false);
    }

    return (
        <div
            className="form-container"
            style={{ maxWidth: '1200px' }}>
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <SectionMenu
                    id="moveMenuSelector"
                    defaultIndex={0}
                    />
            </div>
            <div className="field-container" style={{ width: '85%' }}>
                <Form 
                    form={form}
                    className="flex-between-top flex-wrap"
                    name="moveoutForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFieldsChange={onFieldsChange}>
                    <div className={styles['move-panel']}>
                        <p className={styles['moveout-heading']}>Move out</p>
                        <p className={styles['moveout-heading-description']}>Use this option if you need to disconnect electricity, gas, or both at your property.</p>
                        <p className={styles['moveout-heading-description']}>Carefully select the service/s to disconnect from the list below.</p>
                        <p className={styles['moveout-heading-description']}>Please also read the important information beneath the services list.</p>
                        { showChristmasWarning()
                            ? <p className={styles['moveout-endofyear-text']}>
                                *Certain dates may be unavailable during the Christmas period, 
                                see more info <a rel="noopener noreferrer"
                                    href={links.corporateWebsite.landingPage + new Date().getFullYear() + 'cutoffperiod/'}
                                    target="_blank">here</a>
                                </p>
                            : null }
                        <Table
                            columns={columns}
                            rowKey={r => r.accountServiceId}
                            rowSelection={{ type: 'checkbox', ...rowSelection }}
                            rowClassName={(record) => record.moveOutDate !== null ? styles['move-table-row'] : ''}
                            dataSource={filteredServicesForDisplay}
                            pagination={pagination}
                            loading={loading}
                            scroll={{ x: 'max-content' }}
                            className={styles['move-table']}
                        />
                    </div>
                    <div className={styles['selected-services-container']}>
                        <Item name="selectedServicesOnlyToggle" valuePropName="checked" className={styles['selected-services-toggle']}>
                            <Checkbox className={styles['selected-services-text']}>Show services selected for disconnection only</Checkbox>
                        </Item>
                    </div>
                    <div className={styles['move-panel']}>
                        <Item name="agreementAcceptCheckbox" valuePropName="checked" className={styles['acceptance-checkbox']}>
                            <Checkbox className={styles['acceptance-checkbox-text']}>I understand and agree to the below</Checkbox>
                        </Item>
                        <div className={styles['move-panel-flex']}>
                            <div className={styles['move-panel-text-section']}>
                                <ul className={classNames(styles['move-important-note'], 'flex-left')}>
                                    <li style={{ fontWeight: 600 }}>
                                        No person is at risk as a result of disconnection.
                                    </li>
                                    <li style={{ fontWeight: 600 }}>
                                        Fees/charges apply for disconnecting power and/or gas services.
                                    </li>
                                    <b>
                                        <li className={styles['list-icon-asterisk']}>
                                            Disconnections can happen anytime during the day you select, so if you need power that day, select a disconnection date the next day.
                                        </li>
                                    </b>
                                    <li>
                                        Power will continue to be charged until the disconnection occurs.
                                    </li>
                                    <li>
                                        If your meter(s) are basic meter(s), please ensure safe and unhindered access to your meter(s) for an actual read to be obtained by your local distributor. Failing to do so can result in the final bill being issued based on an estimated read.
                                    </li>
                                    <li>
                                        Notify us on <a href={links.phone.general}>{links.phone.generalDisplay}</a> if changes required ASAP:
                                        <br />
                                        - we may not be able to prevent scheduled disconnection.
                                    </li>
                                    <li>
                                        Disconnections require 3 business days' notice.
                                    </li>
                                    <li>
                                        Any applicable fees will be charged on your next bill.
                                    </li>
                                    <li>
                                        A final bill for the selected service(s) will be issued up to 4 weeks later and sent to the current contact method on the account.
                                        <br />
                                        Please tell us if you need to update this.
                                    </li>
                                    <li>
                                        If you need power at your new place, sign it up with us <a rel="noopener noreferrer" href={links.corporateWebsite.movingHome} target="_blank" style={{ textDecoration: 'underline' }}>here</a>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles['move-panel-image-section']}>
                                <div className={classNames(styles['move-supperman'], 'flex-left-center')}>
                                    <img className={styles['move-supperman-img']} src={supperManImg} alt="supper man img" />
                                    <div className={styles['move-supperman-text']}>
                                        <div>Important information<br /> - please read carefully</div>
                                    </div>
                                </div>
                                <p style={{ margin: "20px 0", textAlign: "center" }}>For full details on fees visit: <a rel="noopener noreferrer" href={links.corporateWebsite.fees} target="_blank">globirdenergy.com.au/fees/</a></p>
                            </div>
                        </div>
                        <Item>
                            <Bwtt
                                type="primary"
                                htmlType="submit"
                                disabled={moveOutSubmitButtonDisabled}
                                disableMessage={moveOutFormValidation}
                                btnNode="Submit"
                                componentClassName={['form-save-button']} />
                        </Item>
                    </div>
                </Form>
            </div>
            <Modal
                visible={showMoveOutConfirmationModal}
                confirmLoading={confirmLoading}
                closable={false}
                footer={null}
                bodyStyle={{ padding: '0px' }}
                width={450}
                centered>
                <div className={styles['confirmation-dialog-header']}>
                    <FontAwesomeIcon icon={faCircleExclamation} className={styles['confirmation-dialog-header-icon']} />
                    <div className={styles['confirmation-dialog-header-text']}>
                        Confirm the service/s you selected for disconnection before continuing:
                    </div>
                </div>
                <Divider style={{ margin: '0' }} />
                <List
                    itemLayout="vertical"
                    dataSource={selectedAccountServiceRows}
                    className={styles['confirmation-dialog-site-list']}
                    renderItem={item => (
                        <List.Item>
                            <p>Meter: <b>{item.nmi} ({getServiceType(item.nmi).toString()})</b></p>
                            <p>Address: <b>{item.siteAddress}</b></p>
                            <p>Move Out Date: <b>{moveOutSubmissions.get(item.accountServiceId)?.moveOutDate.format(DefaultDisplayDateFormat)}</b></p>

                        </List.Item>
                    )}
                />
                <div className={styles['confirmation-dialog-terms']}>
                    <p className={styles['confirmation-dialog-terms-header']}>Please be aware that you have agreed:</p>
                    <ul className={styles['confirmation-dialog-terms-list']}>
                        {
                            selectedAccountServiceRows.length === 1 && moveOutAccountServices.length > 1 &&
                            <li>
                                I have opted to disconnect ONLY one fuel from my multi-services account
                            </li>
                        }
                        <li>
                            I declare that the disconnection will not cause any person harm
                        </li>
                        <li>
                            I understand and agree to the applicable <a style={{textDecoration: 'underline'}} rel="noopener noreferrer" href={links.corporateWebsite.fees} target="_blank">fees</a> and charges
                        </li>
                    </ul>
                </div>
                <div className={styles['confirmation-dialog-footer']}>
                    <div className={styles['confirmation-dialog-footer-text']}>
                        If this is correct then
                    </div>
                    <Button
                        type="primary"
                        onClick={handleOk}
                        style={{ display: 'block', margin: 'auto', minWidth: '150px', marginBottom: '20px' }}
                        loading={confirmLoading}
                        size="large">
                        Confirm
                    </Button>
                    <div className={styles['confirmation-dialog-footer-or']}>
                        OR
                    </div>
                    <div className={styles['confirmation-dialog-footer-text']}>
                        If you want to make a change
                    </div>
                    <Button
                        type="default"
                        onClick={() => setShowMoveOutConfirmationModal(false)}
                        style={goBackBtnStyle}
                        loading={confirmLoading}
                        size="large">
                        Go Back
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={showMultipleServicesConfirmationModal}
                closable={false}
                footer={null}
                bodyStyle={{ padding: '0px' }}
                width={400}
                centered>
                <div className={styles['confirmation-dialog-header']}>
                    <FontAwesomeIcon icon={faCircleExclamation} className={styles['confirmation-dialog-header-icon']} />
                    <div className={styles['confirmation-dialog-header-text']}>
                        You have selected multiple move out dates. Please confirm before proceeding.
                    </div>
                </div>
                <div className={styles['confirmation-dialog-footer']}>
                    <div className={styles['confirmation-dialog-footer-text']}>
                        If this is correct then
                    </div>
                    <Button
                        type="primary"
                        onClick={() => {
                            setShowMultipleServicesConfirmationModal(false);
                            setShowMoveOutConfirmationModal(true);
                        }}
                        style={{ display: 'block', margin: 'auto', minWidth: '150px', marginBottom: '20px' }}
                        loading={confirmLoading}
                        size="large">
                        Continue
                    </Button>
                    <div className={styles['confirmation-dialog-footer-or']}>
                        OR
                    </div>
                    <div className={styles['confirmation-dialog-footer-text']}>
                        If you want to make a change
                    </div>
                    <Button
                        type="default"
                        onClick={() => setShowMultipleServicesConfirmationModal(false)}
                        style={goBackBtnStyle}
                        loading={confirmLoading}
                        size="large">
                        Go Back
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={showAcknowledgementModal}
                closable={false}
                footer={null}
                bodyStyle={{ padding: '0.2rem' }}
                width={650}
                centered>
                <div className={styles['ack-dialog-header']}>
                    <div className={styles['ack-dialog-header-text']}>
                        <FontAwesomeIcon icon={faCircleInfo} className={styles['ack-dialog-header-icon']} />
                        Success! That's done.
                    </div>
                </div>
                <div className={styles['ack-dialog-body']}>
                    <div className={styles['ack-dialog-footer-text']}>
                        We have logged your request to disconnect the following service(s):
                    </div>
                    <List
                        itemLayout="vertical"
                        dataSource={selectedAccountServiceRows}
                        className={styles['ack-dialog-site-list']}
                        split={false}
                        renderItem={item => (
                            <List.Item className={styles['ack-dialog-site-list-item']}>
                                {item.nmi}
                                <FontAwesomeIcon icon={faCircle} className={styles['ack-dialog-spacer-icon']} />
                                {getServiceType(item.nmi).toString()}
                                <FontAwesomeIcon icon={faCircle} className={styles['ack-dialog-spacer-icon']} />
                                {item.siteAddress}
                                <FontAwesomeIcon icon={faCircle} className={styles['ack-dialog-spacer-icon']} />
                                {moveOutSubmissions.get(item.accountServiceId)?.moveOutDate.format(DefaultDisplayDateFormat)}
                            </List.Item>
                        )}
                    />
                    <div className={styles['ack-dialog-footer-text']}>
                        If you need power connected at your new place, make sure to select and complete
                        <br />
                        <a rel="noopener noreferrer"
                            href={links.oss.landingPage}
                            target="_blank"
                            className={styles['move-in-link']}>
                            <b>Move In</b> by clicking here now
                        </a>
                    </div>
                    <div className={styles['ack-dialog-footer-text']}>
                        If you need to make a change to this disconnection - call us immediately on <a href={links.phone.general}>{links.phone.generalDisplay}</a>
                    </div>
                    <Button
                        type="default"
                        onClick={() => {
                            setShowAcknowledgementModal(false);
                            clearForm();
                        }}
                        className={styles['ack-dialog-close-button']}
                        loading={confirmLoading}
                        size="middle">
                        Close
                        <FontAwesomeIcon icon={faXmark} className={styles['ack-dialog-close-icon']} />
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

const goBackBtnStyle = {
    display: 'block',
    margin: 'auto',
    minWidth: '150px',
    marginBottom: '20px',
    backgroundColor: '#FBA747',
    color: 'white',
    borderColor: '#FBA747'
};

export default Moveout;