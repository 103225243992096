import { Account, ConcessionCardInfoHistoryLiteDto, ConcessionCardStatus, DefaultDisplayDateFormat } from 'data/CustomerPortalTypes';
import { getConcessionCardsForAccount } from 'services/RequestDataService';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styles from '../Concession.module.css';
import GlobirdTable from 'components/Shared/GlobirdTable';
import { GlobirdTableContainer } from 'data/GlobirdTableContainer';
import GlobirdPagination, { GlobirdPaginationControl } from 'components/Shared/GlobirdPagination';

const statusRenderer = (val: string) => {
    switch (ConcessionCardStatus[val as keyof typeof ConcessionCardStatus]) {
        case ConcessionCardStatus.ValidationRequested:
        case ConcessionCardStatus.UnderInvestigation:
        case ConcessionCardStatus.PendingValidation:
            return 'Pending';
        case ConcessionCardStatus.Rejected:
            return 'Rejected';
        case ConcessionCardStatus.Validated:
            return 'Validated';
        default:
            return '';
    }
};

const convertToGlobirdTableContainer = (concessionCards: ConcessionCardInfoHistoryLiteDto[]): GlobirdTableContainer => {
    let dataContainer = new GlobirdTableContainer();
    dataContainer.headerList.push('Card Number', 'Card Holder', 'Card Type', 'Card Status', 'Validation Or Energy Acc Start Date', 'Validation End Date');
    for (let card of concessionCards) {
        var rowData = [
            card.concessionCardNumber.toString(),
            card.cardHolderName,
            card.concessionCardType,
            statusRenderer(card.concessionCardStatus),
            moment(card.startDate).format(DefaultDisplayDateFormat),
            card.endDate ? moment(card.endDate).format(DefaultDisplayDateFormat) : ''
        ];
        dataContainer.rowList.push(rowData);
    }
    return dataContainer;
}

const ConcessionHistory = ({
    currentAccount
}: {
    currentAccount: Account | null
}) => {

    const [currentConcessionCards, setCurrentConcessionCards] = useState<ConcessionCardInfoHistoryLiteDto[]>([]);
    const globirdPagination = useRef<GlobirdPaginationControl>(null);
    const jumpPage = (cards: ConcessionCardInfoHistoryLiteDto[]) => setCurrentConcessionCards(cards);
    
    useEffect(() => {
        if (!currentAccount) {
            return;
        }

        globirdPagination.current?.refresh();

    }, [currentAccount]);

    if (!currentAccount) {
        return <></>
    }

    return <div className={styles['concession-history-wrapper']}>
        <p className={styles['manage-concession-heading']}>Concession History</p>
        <GlobirdTable dataContainer={convertToGlobirdTableContainer(currentConcessionCards)} />
        <GlobirdPagination
            ref={globirdPagination}
            requestDataFunc={(offset, limit) => getConcessionCardsForAccount(
                currentAccount.accountId,
                offset,
                limit)}
            onJumpToPage={jumpPage} />
    </div>
}

export default ConcessionHistory;