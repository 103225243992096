import { selectUser } from 'store/userSlice';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Form, Input, Popover, Spin } from 'antd';
import { changePassword } from 'services/AuthService';
import {
    CommonPasswordValidator,
    PasswordUsernameOverlapStaticValidator,
    RepeatPasswordValidator
} from 'utilities/Validators'
import SectionMenu from '../SectionMenu';
import {
    getRuleObject,
    generateRequiredRule as genRqdRule
} from 'utilities/Utility';
import { useRef, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import ActionResultPanel from 'components/ActionResultPanel';
import Captcha from 'components/Shared/Captcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const { Item } = Form;

const oldPwdFormErr = [
    { name: 'oldpassword', errors: ['Please check your old password'] }
];

let preventSubmit = false;

let tmpChangePwdDto: any = {};

const ChangePassword = () => {
    const [form] = useForm();
    const [changePwdSucceeded, setChangePwdSucceeded] = useState<boolean | null>(null);
    const user = useSelector(selectUser);

    const [showCaptcha, setShowCaptcha] = useState(false);
    const [requireCaptcha, setRequireCaptcha] = useState(false);
    const captchaRef = useRef<HCaptcha>(null);

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    const changePasswordLocal = async (
        oldpassword: string,
        newpassword: string,
        token: string | null) => {

        try {
            const pwdResult = await changePassword(oldpassword, newpassword, token);

            if (!pwdResult?.success
                || !pwdResult?.data) {

                throw new Error();
            }

            if (pwdResult.data.isSucceeded) {
                setChangePwdSucceeded(true);

                return;
            }

            if (pwdResult.data.requireRetryCaptCha) {
                setShowCaptcha(true);

                return;
            }

            if (pwdResult.data.requireHCaptcha) {
                setRequireCaptcha(true);
            }

            form.setFields(oldPwdFormErr);
        }
        catch {
            setChangePwdSucceeded(false);
        }
    }

    const onFinish = async (values: any) => {

        if (preventSubmit) {
            return;
        }

        try {
            if (requireCaptcha) {

                setShowCaptcha(true);
                setRequireCaptcha(false);
                tmpChangePwdDto = { ...values };

                return;
            }

            changePasswordLocal(values.oldpassword, values.newpassword, null);
        }
        finally {
            preventSubmit = false;
        }
    };

    const onCaptchaVerified = (token: string) => {

        setShowCaptcha(false);
        setRequireCaptcha(false);
        captchaRef?.current?.resetCaptcha();

        changePasswordLocal(tmpChangePwdDto.oldpassword, tmpChangePwdDto.newpassword, token);
    }

    return (
        <div className="form-container">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <SectionMenu
                    id="changePasswordMenuSelector"
                    defaultIndex={3} />
            </div>
            <div className="field-container">
                {changePwdSucceeded && <ActionResultPanel status="success">
                    Your have successfully change the password.
                </ActionResultPanel>}
                {changePwdSucceeded === false && <ActionResultPanel status="error">
                    Oops! Something went wrong, please try again later or contact our customer service for help.
                </ActionResultPanel>}
                {changePwdSucceeded === null && <Spin
                    spinning={showCaptcha}>
                    <Form
                        name="login"
                        layout="vertical"
                        onFinish={onFinish}
                        form={form}>
                        <Item
                            name="oldpassword"
                            id="changePasswordOld"
                            label={<label className="globird-form-field-label">Your Current Password</label>}
                            rules={[
                                genRqdRule('Current password'),
                                CommonPasswordValidator()
                            ]}>
                            <Input.Password allowClear style={{ width: "100%" }} />
                        </Item>
                        <Item
                            name="newpassword"
                            id="changePasswordNew"
                            label={<label className="globird-form-field-label">New Password</label>}
                            rules={[
                                genRqdRule('New password'),
                                CommonPasswordValidator(),
                                PasswordUsernameOverlapStaticValidator(user!.emailAddress!, 'email address'),
                                getRuleObject(getFieldsValue => {

                                    const values = getFieldsValue();

                                    const newPwd = values['newpassword'];
                                    const oldPwd = values['oldpassword'];

                                    if (newPwd && (oldPwd === newPwd)) {
                                        return 'New password should not be the same as the old password.';
                                    }

                                    return null;
                                }, true)
                            ]}>
                            <Input.Password allowClear style={{ width: "100%" }} />
                        </Item>
                        <Item
                            name="newpasswordrepeat"
                            id="changePasswordNewRepeat"
                            label={
                                <label className="globird-form-field-label">
                                    Repeat New Password
                                </label>
                            }
                            rules={[
                                genRqdRule(null, 'Please repeat the new password.'),
                                RepeatPasswordValidator('newpassword', 'newpasswordrepeat')
                            ]}>
                            <Input.Password allowClear style={{ width: "100%" }} />
                        </Item>
                        <Item>
                            <Popover
                                visible={showCaptcha}
                                content={<Captcha
                                    ref={captchaRef}
                                    onVerify={onCaptchaVerified} />}>
                                <button
                                    className="globird-button-primary"
                                    style={{ width: '100%' }}>
                                    Submit
                                </button>
                            </Popover>
                        </Item>
                    </Form></Spin>}
            </div>
        </div >
    );
}

export default ChangePassword;