import { DatePicker as AntdDatePicker } from 'antd';
import { PickerBaseProps, PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { PanelMode } from 'rc-picker/lib/interface';
import { DefaultDisplayDateFormat, DefaultJsonDateFormat } from 'data/CustomerPortalTypes';

type DatePickerPropsType = (PickerBaseProps<Moment> | PickerDateProps<Moment>) & { id: string };

// const dateRegex = /^(?<dd>\d{2})(\/|-)(?<mm>\d{2})(\/|-)(?<yy>\d{4})$/;
const shortDateRegex = /^(?<dd>\d{1,2})(\/|-)(?<mm>\d{1,2})(\/|-)(?<yy>\d{4})$/;
const intermediateDateRegex = /^\d{1,2}-[a-zA-Z]{3}-\d{1,4}$/; // 1-Jan-2010/1-Jan-201/01-Jan-201 when this regex is detect, assuming user is editing the result, ignore input until user enter a valid date

// const monthRegex = /^(?<mm>\d{2})(\/|-)(?<yy>(\d{2}|\d{4}))$/;
const shortMonthRegex = /^(?<mm>\d{1,2})(\/|-)(?<yy>(\d{2}|\d{4}))$/;

const DatePicker: React.FC<DatePickerPropsType> = (props) => {

    const [mode, setMode] = useState<PanelMode>(props.mode ?? 'decade'); // default to decade if not specified

    const [value, setValue] = useState<Moment | null>(null); // default to decade if not specified

    const picker = props.picker ?? 'date'; // default to date picker

    const locOnValidDateDetected = useCallback((val: Moment | null) => {
        props.onChange?.(val, val?.toString() ?? '');

        setValue(val);
    }, [props]);

    useEffect(
        () => {
            const input = document.getElementById(props.id) as HTMLInputElement;

            const cb: (e: HTMLElementEventMap['input']) => any = (e: HTMLElementEventMap['input']) => {
                const val = (e.target as HTMLInputElement).value;

                if (!val) {
                    return;
                }

                // console.log(`DatePicker: ${val}`);

                let momentResult: Moment;

                // check short format
                if ((picker !== 'month' && val.length < 8)
                    || (picker === 'month' && val.length < 4)) {

                    return;
                }

                if (intermediateDateRegex.exec(val)
                    && val.length !== 11) {

                    return;
                }

                if (picker === 'month') {

                    const regexRes = shortMonthRegex.exec(val);

                    if (regexRes) {
                        const matchGps = regexRes.groups;

                        let yyyy = matchGps!['yy'];

                        if (yyyy?.length === 2) {
                            yyyy = '20' + yyyy;
                        }

                        const mm = matchGps!['mm'];

                        momentResult = moment.utc(`${yyyy}-${mm}`, 'YYYY-MM');

                        if (momentResult.isValid()) {
                            locOnValidDateDetected(momentResult);
                            return;
                        }
                    }
                }
                else {
                    const regexRes = shortDateRegex.exec(val);

                    if (regexRes) {
                        const matchGps = regexRes.groups;

                        const yyyy = matchGps!['yy'];

                        const mm = matchGps!['mm'];

                        const dd = matchGps!['dd'];

                        momentResult = moment.utc(`${yyyy}-${mm}-${dd}`);

                        // console.log(`${yyyy}-${mm}-${dd}`)

                        if (momentResult.isValid()) {
                            locOnValidDateDetected(momentResult);
                            return;
                        }
                    }
                }

                // check long format
                if ((picker !== 'month' && val.length < 10)
                    || (picker === 'month' && val.length < 5)) {

                    return;
                }

                // if (picker === 'month') {

                //     const regexRes = monthRegex.exec(val);

                //     if (regexRes) {
                //         const matchGps = regexRes.groups;

                //         let yyyy = matchGps!['yy'];

                //         if (yyyy?.length === 2) {
                //             yyyy = '20' + yyyy;
                //         }

                //         const mm = matchGps!['mm'];

                //         momentResult = moment.utc(`${yyyy}-${mm}`, 'YYYY-MM');

                //         if (momentResult.isValid()) {
                //             locOnValidDateDetected(momentResult);
                //             return;
                //         }
                //     }
                // }
                // else {
                //     const regexRes = dateRegex.exec(val);

                //     if (regexRes) {
                //         const matchGps = regexRes.groups;

                //         const yyyy = matchGps!['yy'];

                //         const mm = matchGps!['mm'];

                //         const dd = matchGps!['dd'];

                //         momentResult = moment.utc(`${yyyy}-${mm}-${dd}`);

                //         if (momentResult.isValid()) {
                //             locOnValidDateDetected(momentResult);
                //             return;
                //         }
                //     }
                // }

                momentResult = moment.utc(val);

                if (momentResult.isValid()) {
                    locOnValidDateDetected(momentResult);

                    return;
                }
            };

            input?.removeAttribute('readonly');
            input?.addEventListener('input', cb);

            return () => {
                input?.removeEventListener('input', cb);
            };
        }
        , [locOnValidDateDetected]); // called everytime when re-render

    return <AntdDatePicker
        {...props}
        mode={mode}
        format={props.format ?? DefaultDisplayDateFormat}
        onPanelChange={(_, m) => setMode(m)}
        value={props.value === undefined ? value : props.value}
        onChange={
            momentVal => {
                if (!momentVal) { // allow clear
                    locOnValidDateDetected(momentVal);
                    return;
                }

                locOnValidDateDetected(
                    moment.utc(momentVal.format(DefaultJsonDateFormat)));
            }} />;
}

export default DatePicker;

export type { DatePickerPropsType };