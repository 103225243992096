import { InputProps } from 'antd/lib/input';
import { Input } from 'antd';
import { ChangeEventHandler, useState } from 'react';

export const FilteredInput = (props?: Omit<InputProps, 'onChange' | 'value'> & { filterExpression?: RegExp, value?: string, onChange?: (v: string) => void }) => {

    if (props?.filterExpression && !props.filterExpression.global) {
        throw new Error('filterExpression regex must be global.');
    }

    const [value, setValue] = useState<string>(props?.value ?? '');

    const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {

        const val = (e.target.value && props?.filterExpression)
            ? e.target.value.replaceAll(props.filterExpression, '')
            : e.target.value;

        setValue(val);
        props?.onChange?.(val);
    }

    return <Input {...props} value={props?.value ?? value} onChange={onChange} />
}

export default FilteredInput;