import { Form, Input, message, Modal, Spin, Tooltip } from 'antd';
import { useState } from 'react';
import { selectUser, setUser } from 'store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import authService, { requestResetPassword } from 'services/AuthService';
import { UnavailableState } from 'data/CustomerPortalTypes';
// import ActionResultPanel from 'components/ActionResultPanel';
import {
    useCountdown,
    workoutCountDownTimeStr,
    generateRequiredRule as genRqdRule,
    EmailRule,
    AccountNumberRule
} from 'utilities/Utility';
import { RequestResetPasswordOutcome } from 'data/CustomerPortalTypes';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { selectRequestStarted } from 'store/requestSlice';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FilteredInput from 'components/Shared/FilteredInput';

const { Item } = Form;

let submitted = false;

const ResetPasswordRequest = () => {
    const [resetCountdownLeft, startResetCountdown] = useCountdown('reset-login-pwd', 60);

    const isRequestStarted = useSelector(selectRequestStarted);

    const [showAccntNumModal, setShowAccntNumModal] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');

    const [form] = useForm();

    const user = useSelector(selectUser);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const closeAccntNumModal = () => {
        setShowAccntNumModal(false);
        form.setFieldsValue({ accountNumber: '' });
    };

    if (user) {
        return <Navigate to="/dashboard" replace />;
    }

    const onFinish = async (values: any) => {
        // console.log('finish')

        if (submitted) {
            return;
        }

        submitted = true;

        try {
            startResetCountdown();

            const result = await requestResetPassword(
                values["email"],
                values["accountNumber"]);

            if (result?.success !== true
                || !result.data) {

                message.error({
                    content: 'Unable to send reset password Email, please try again later or contact our customer service for help.',
                    duration: 10
                });

                return;
            }

            switch (result.data) {
                case RequestResetPasswordOutcome.Succeeded:
                    message.success({
                        content:
                            values["accountNumber"]
                                ? 'Your account has not been activated yet.\nPlease follow the activation link sent to you by e-mail.'
                                : 'Reset password Email sent. If you have account with us, you should receive it soon.',
                        duration: 10
                    });
                    closeAccntNumModal();
                    break;
                case RequestResetPasswordOutcome.InvalidInfo:
                    form.setFields([{
                        name: 'accountNumber',
                        errors: ['Please check your account number.']
                    }]);
                    break;
                case RequestResetPasswordOutcome.RequireMoreInfo:
                    setShowAccntNumModal(true);
                    break;
                case RequestResetPasswordOutcome.AlreadyLoggedIn:
                    const user = await authService.authenticate();

                    if (!user) {
                        message.error({
                            content: 'Unable to send reset password Email, please try again later or contact our customer service for help.',
                            duration: 10
                        });

                        return;
                    }

                    dispatch(setUser(user));
                    break;
                case RequestResetPasswordOutcome.UserUnavailable:
                    const unavailableState : UnavailableState = { unavailableFlag : true };
                    navigate('/unavailable', { replace : true, state : unavailableState });

                    break;
            }
        }
        finally {
            submitted = false
        }
    };

    return (
        <div className="login">
            <div className="globird-form">
                <Form
                    form={form}
                    className="login-form"
                    name="requestResetPassword"
                    layout="vertical"
                    onFinish={onFinish}>

                    <div className="globird-form-title">
                        <FontAwesomeIcon
                            icon={faUnlockAlt}
                            style={{ marginRight: '10px' }} />Reset Your Password
                    </div>
                    <Item
                        name="email"
                        id="resetPasswordEmail"
                        label={<label className="globird-form-field-label">Your Email</label>}
                        rules={[genRqdRule('Email address'), EmailRule]}>
                        <FilteredInput
                            allowClear
                            style={{ width: "100%" }}
                            filterExpression={/\s/g}
                            onChange={v => setEmail(v)} />
                    </Item>
                    <Item>
                        <button
                            className="globird-button-primary"
                            disabled={resetCountdownLeft > 0}
                            // htmlType="submit"
                            style={{ width: '100%' }}>
                            {resetCountdownLeft > 0
                                ? `Resend after (${workoutCountDownTimeStr(resetCountdownLeft)})`
                                : 'Send'}
                        </button>
                    </Item>
                    <Modal
                        visible={showAccntNumModal}
                        // visible
                        title="Verify Your Account Number"
                        cancelText="Cancel"
                        okText="Submit"
                        onCancel={closeAccntNumModal}
                        onOk={form.submit}
                        maskClosable={false}>
                        <Spin spinning={isRequestStarted}>
                            <p style={{ fontSize: '17px' }}>
                                Please enter your&nbsp;
                                <Tooltip
                                    title="You can find your Account Number in your Welcome Pack, as well as on your bills."
                                >
                                    <strong style={{ cursor: 'pointer' }}>Account Number <QuestionCircleOutlined /></strong>
                                </Tooltip>
                                <br />
                                <span style={{ lineHeight: '32px' }}>
                                    for <strong>{email}</strong>:&nbsp;
                                </span>
                                <Item
                                    name="accountNumber"
                                    rules={[AccountNumberRule, genRqdRule('Account number')]}
                                    style={{ width: '200px', display: 'inline-block' }}>
                                    <Input style={{ height: '29px' }} />
                                </Item>
                            </p>
                        </Spin>
                    </Modal>
                </Form>
                <div className="router-block">
                    <p>
                        <Link className="globird-link" to="/login">Click here</Link> to go back to login
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordRequest;
