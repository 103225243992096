import { useEffect, useState } from "react";
import styles from './SignupRoadmap.module.css';
import classnames from "classnames";
import pinkCircleIcon from "../../../assets/signupJourney/pink-circle-icon.svg";
import greenCircleIcon from "../../../assets/signupJourney/green-circle-icon.svg";
import errorIcon from "../../../assets/signupJourney/error-icon.svg";
import diamondIcon from "../../../assets/signupJourney/diamond-icon.svg";
import yellowCircleIcon from "../../../assets/signupJourney/yellow-circle-icon.svg";
import { Col, Grid, Row } from "antd";
import { SignupInfoDto, SignupStatus, TransferType } from "data/CustomerPortalTypes";
import { useSelector } from "react-redux";
import { selectCurrentAccount } from "store/userSlice";
import { DataCache, ServiceType } from "utilities/Utility";
import requestDataService from "services/RequestDataService";
import links from "data/Links";
const { useBreakpoint } = Grid;

const smallScreenMoveInIframeUrl = "https://xd.adobe.com/embed/30c29307-dd20-44ff-8cac-15e81819b40e-6591/";
const largeScreenMoveInIframeUrl = "https://xd.adobe.com/embed/ae54ae15-dc30-4e2b-8ac6-b5b5a794fbe2-fb07/";
const smallScreenTransferIframeUrl = "https://xd.adobe.com/embed/1789a0bd-3d14-4c42-856e-a69590ecff32-42da/";
const largeScreenTransferIframeUrl = "https://xd.adobe.com/embed/09f1fb73-a1fd-4df6-8785-c592befbdf54-5ca2/";

const dataCache: Record<number, DataCache<SignupInfoDto[]>> = {};

const FIVE_MINUTE_INTERVAL = 300000;

const serviceTypeToDisplayName = (serviceType: ServiceType) => {
    if (serviceType === ServiceType.Power) {
        return "Electricity";
    }

    return "Gas";
}

const SignupRoadmap = () => {
    const currentAccount = useSelector(selectCurrentAccount);
    
    const [iFrameUrl, setIFrameUrl] = useState<string>(largeScreenMoveInIframeUrl);
    const [signUpInfo, setSignupInfo] = useState<SignupInfoDto[]>([]);
    const [transferType, setTransferType] = useState<TransferType | null>(null);
    const [isTransfer, setIsTransfer] = useState<boolean>(false);
    const [dualFuel, setDualFuel] = useState<boolean>(false);
    const [showDetailedInfo, setShowDetailedInfo] = useState<boolean>(false);

    const { md: isLargeScreen } = useBreakpoint();

    useEffect(() => {
        if (!currentAccount) {
            return;
        }

        const getSignupInfoFromCache = async () => {
            dataCache[currentAccount.accountId] ??=
                await DataCache.Create<SignupInfoDto[]>(
                    async () =>
                        (await requestDataService.getSignupInfo(
                            currentAccount.accountId))
                            ?.data,
                FIVE_MINUTE_INTERVAL);

            const res = dataCache[currentAccount.accountId]?.data;

            if (res && res.length > 0)
            {
                setSignupInfo(res);
                setTransferType(res[0].transferType);
                setIsTransfer(res[0].transferType === TransferType.Transfer);
                setDualFuel(res.length > 1);
            }
            else
            {
                setSignupInfo([]);
                setTransferType(null);
                setIsTransfer(false);
                setDualFuel(false);
            }
        };

        getSignupInfoFromCache();

    }, [currentAccount]);

    useEffect(() => {
        if (isLargeScreen) {
            setIFrameUrl(isTransfer ? largeScreenTransferIframeUrl : largeScreenMoveInIframeUrl);
            return;
        }
        
        setIFrameUrl(isTransfer ? smallScreenTransferIframeUrl : smallScreenMoveInIframeUrl);
    }, [isLargeScreen, isTransfer]);

    const getLabels = (signupStatus: SignupStatus, isTransfer: boolean) => {
        switch (signupStatus) {
            case SignupStatus.SignupCompleted:
                return [
                    "SIGNUP COMPLETED",
                    isTransfer ? "TRANSFER START" : "CONNECTION / INITIAL READ"
                ];
            case SignupStatus.ApplicationPaused:
                return [
                    "APPLICATION PAUSED",
                    isTransfer ? "TRANSFER START" : "CONNECTION / INITIAL READ"
                ];
            case SignupStatus.TransferOrMoveInInitiated:
                return isTransfer
                    ? ["TRANSFER START", "TRANSFER READ"]
                    : ["CONNECTION / INITIAL READ", "MOVE-IN COMPLETE"];
            case SignupStatus.TransferOrMoveInCompleted:
                return [
                    isTransfer ? "TRANSFER COMPLETE" : "MOVE-IN COMPLETE",
                    "FIRST BILL"
                ];
            default:
                return [
                    "SIGNUP COMPLETED",
                    isTransfer ? "TRANSFER START" : "CONNECTION / INITIAL READ"
                ];
        }
    };

    if (!transferType || !signUpInfo || signUpInfo.length === 0) {
        return null;
    }

    return (
        <>
            <div className={styles['journey-container']}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} order={1}>
                        <div className={styles['journey-col']}>
                            <h2 className={styles['journey-col-header']}>
                                {isTransfer ? "TRANSFER JOURNEY ROADMAP" : "MOVE-IN JOURNEY ROADMAP"}
                            </h2>
                            <p>
                                Hover your mouse (or tap) on each stop on the image below for a description of what happens at each point along your {isTransfer ? "transfer" : "move-in"} journey. Before you begin - here's some quick answers to questions we are commonly asked:
                            </p>
                            <ol>
                                {isTransfer ? <li>
                                    There's no need to contact your old retailer, we will take care of the transfer process for you.
                                </li> : null}
                                <li>
                                    Sit back and relax, we will send you correspondence at each point in the process.
                                </li>
                                <li>
                                    {dualFuel ? "Gas and Electricity" : serviceTypeToDisplayName(signUpInfo[0].serviceTypeId)} {isTransfer ? "transfers" : "connections"} can happen at differing times along the journey.
                                </li>
                            </ol>
                            {
                                signUpInfo.length > 0 && signUpInfo.map((info, _) => {
                                    const [currentStep, nextStep] = getLabels(info.signupStatus, isTransfer);
                                    return (
                                        <p style={{ marginBottom: '5px', lineHeight: '25px' }}>
                                            Your <b>{ serviceTypeToDisplayName(info.serviceTypeId) }</b> { isTransfer ? "transfer" : "connection" } is currently at step:
                                            <span style={{ margin: '0 5px', fontSize: '1em' }} className="globird-badge badge-green">{currentStep}</span>
                                            and the next step is:
                                            <span style={{ margin: '0 5px', fontSize: '1em' }} className="globird-badge badge-globird">{nextStep}</span>
                                        </p>
                                    );
                                })
                            }
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6} order={isLargeScreen ? 2 : 4}>
                        <div className={styles['journey-col']}>
                            <h2 className={styles['journey-col-header']}>MORE INFO</h2>
                            <p>
                                <b>Email us at:</b>
                                <br />
                                <a href={"mailto:" + links.email.cs}>{links.email.cs}</a>
                            </p>
                            <p>
                                <b>Chat with us online:</b>
                                <br />
                                <button
                                    className={classnames(styles['chat-button'], 'globird-button-primary')}
                                    onClick={() => window.open(links.corporateWebsite.contactUs)}
                                    >
                                    Chat
                                </button>
                            </p>
                            <p>
                                <b>Call us on:</b>
                                <br />
                                <a href={links.phone.general}>{links.phone.generalDisplay}</a>
                            </p>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6} order={3}>
                        <div className={styles['journey-col']}>
                            <h2 className={styles['journey-col-header']}>KEY</h2>
                            <div className={styles['key-icons-container']}>
                                <div>
                                    <img className={styles['journey-key-icon']} src={pinkCircleIcon} alt="pink-circle-icon" />
                                    Process initiated by GloBird
                                </div>
                                <div>
                                    <img className={styles['journey-key-icon']} src={greenCircleIcon} alt="pink-circle-icon" />
                                    Completed process
                                </div>
                                <div>
                                    <img className={styles['journey-key-icon']} src={diamondIcon} alt="pink-circle-icon" />
                                    Informative point only
                                </div>
                                <div>
                                    <img className={styles['journey-key-icon']} src={yellowCircleIcon} alt="pink-circle-icon" />
                                    Automated continuation based on regulated timeline or metering
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} order={isLargeScreen ? 4 : 2}>
                        <div className={styles['roadmapIFrameContainer']}>
                            <iframe className={styles['roadmapIFrame']} title="Signup Journey" src={iFrameUrl} />
                        </div>
                    </Col>
                    <Col span={24} order={5}>
                        <div className={styles['journey-col']}>
                            <Row gutter={[12, 12]}>
                                <Col span={isLargeScreen ? 8 : 24}>
                                    <h2 className={classnames(styles['journey-col-header'], styles['detailed-next-steps-header'])} onClick={()=> setShowDetailedInfo(!showDetailedInfo)}>
                                    WHAT HAPPENS NEXT?
                                    </h2>
                                    {showDetailedInfo ? <p>
                                        After your transfer or move-in successfully completes, you are officially a GloBird customer - welcome aboard! 
                                        You will have full access to our online account tool 'My Account' 24-7, where you can view and manage most aspects of your GloBird account. 
                                        We find that new customers often have questions about monthly billing - so here’s some info about what to expect…
                                    </p> : null}
                                    </Col>
                                {showDetailedInfo ? <>
                                    <Col span={isLargeScreen ? 8 : 24}>
                                        <p>
                                            <b>First Bill</b>
                                            <br /> 
                                            Your first bill might be shorter or longer than the standard billing period, but after that it should be on a regular cycle. 
                                            <br />
                                            <i>Shorter:</i> as the system generates a smaller initial bill 
                                            <br />
                                            <i>Longer (up to 2-3 months):</i> due to waiting for the 2nd scheduled read for a basic meter to ensure the accuracy of the bill. 
                                            <br />
                                            The ongoing billing cycle reverts to normal after your first bill. 
                                        </p>
                                        <br />
                                        <p>
                                            As it takes longer to receive the transfer read for a basic meter, you may receive the basic meter bill a month later than the smart meter. 
                                            Learn more about your first bill here: 
                                            <br />
                                            <a target="_blank" href={links.corporateWebsite.myFirstBill} rel="noreferrer">- My First Bill</a>
                                        </p>
                                    </Col>
                                    <Col span={isLargeScreen ? 8 : 24}>
                                        <p>
                                            <b>Next Bill</b>
                                            <br />
                                            To understand your ongoing bills and answers to the most commonly asked questions, go here: 
                                            <br />
                                            <a target="_blank" href={links.corporateWebsite.understandingYourBill} rel="noreferrer">- Understanding your bill</a>
                                            <br />
                                            <a target="_blank" href={links.corporateWebsite.myMonthlyBill} rel="noreferrer">- My monthly bill</a>
                                        </p>
                                    </Col>
                                </> : null}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SignupRoadmap;