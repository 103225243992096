import Cleave from 'cleave.js/react';
import moment, { Moment } from 'moment';
import { Form, Input, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { getRuleObject } from 'utilities/Utility';
import DatePicker from 'components/Shared/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcMastercard, faCcVisa, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import classnames from 'classnames';
import styles from './CreditCardField.module.css';

enum CreditCardType {
    Mastercard = "Mastercard",
    Visa = "Visa",
    // UnionPay = "UnionPay",
    Amex = "Amex"
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreditCardTypeCleaveTypeMapping: Record<CreditCardType, string> = {
    [CreditCardType.Mastercard]: 'mastercard',
    [CreditCardType.Visa]: 'visa',
    // [CreditCardType.UnionPay]: 'unionPay',
    [CreditCardType.Amex]: 'amex'
};

const CleaveTypeCreditCardTypeMapping: Record<string, CreditCardType> = {
    mastercard: CreditCardType.Mastercard,
    visa: CreditCardType.Visa,
    // unionPay: CreditCardType.UnionPay,
    amex: CreditCardType.Amex
};

const expDateInputId = 'creditCardExpDateInput';

const defaultCardIconStyle: React.CSSProperties = {
    position: 'absolute',
    fontSize: '27px',
    top: '25px',
    left: '86%'
};

const getCardIconDef = (cardType: CreditCardType) => {
    switch (cardType) {
        case CreditCardType.Mastercard:
            return faCcMastercard;
        // return {
        //     className: 'fab fa-cc-mastercard',
        //     style: defaultStyle
        // };
        case CreditCardType.Visa:
            return faCcVisa;
        // return {
        //     className: 'fab fa-cc-visa',
        //     style: defaultStyle
        // };
        // case CreditCardType.UnionPay:
        //     return {
        //         className: 'fac fa-unionpay',
        //         style: {
        //             ...defaultStyle,
        //             top: '15px',
        //             fontSize: '26px'
        //         }
        //     };
        case CreditCardType.Amex:
            return faCcAmex;
        // return {
        //     className: 'fab fa-cc-amex',
        //     style: defaultStyle
        // };
        default:
            throw new Error(`'${cardType}' is not a known credit card type.`);
    }
}

const validateCreditCardType = (cardType: CreditCardType | null) => {
    if (cardType === null) {
        // return 'Only Visa, Mastercard, Unionpay and Amex credit card is accepted.';
        return 'Only Visa, Mastercard or Amex credit card is accepted.';
    }

    return null;
}

const ccRegex = /^(4[0-9]{12}([0-9]{3})?|[25][1-7][0-9]{14}|6(011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(0[0-5]|[68][0-9])[0-9]{11}|(2131|1800|35\d{3})\d{11}|62[0-9]{14,17})$/;

const validateCreditCardNumber = (cardNumber: string) => {
    if (!cardNumber) {
        return 'Card number is required.';
    }

    const cardNumNoSpace = cardNumber.replaceAll(' ', '');

    if (!ccRegex.test(cardNumNoSpace)) {
        return 'Invalid credit card number.';
    }

    return null;
}

const validateCreditCardHolderName = (name: string) => {
    if (!name) {
        return 'Name on card is required.';
    }

    const regExp = /^[A-Za-z-'\s]+$/;
    if (!regExp.test(name)) {
        return 'Please don\'t include special characters.'; 
    }

    return null;
}

const cvnRegex = /^\d{3,4}$/;

const validateCreditCardCvn = (cvn: string) => {
    if (!cvn) {
        return 'CVV/CVC is required.';
    }

    if (!cvnRegex.test(cvn)) {
        return 'Invalid CVV/CVC';
    }

    return null;
}

// const expRegex = /^\d{2}\/\d{2}$/;

const expDateFormat = 'MM/YY';

const validateCreditCardExp = (exp: Moment) => {

    if (!exp) {
        return 'Card expiry date is required.';
    }

    const endOfMonth = exp.endOf('month');

    if (moment().isAfter(endOfMonth)) {
        return 'Card expired.';
    }

    return null;
}

const cardNumberRule = getRuleObject(getFieldsValue => {
    const values = getFieldsValue();

    const cardNumber = values['CardNumber'] as string;
    const cardType = values['CardType'] as CreditCardType | null;

    const cardNumberResult = validateCreditCardNumber(cardNumber);

    if (cardNumberResult) {
        return cardNumberResult;
    }

    const cardTypeResult = validateCreditCardType(cardType);

    if (cardTypeResult) {
        return cardTypeResult;
    }

    return null;
}, true);

interface CreditCardField {
    CardType: CreditCardType | null,
    CardNumber: string,
    CardHolderName: string,
    CardCvn: string | null,
    CardExp: Moment | null
};

const disabledDate = (current: Moment) => current && current < moment().startOf('month');

const CreditCardFields = (props: { ccForm: FormInstance<CreditCardField>, cvnRequired: boolean }) => {
    const { ccForm, cvnRequired } = props;

    return (<>
        <Form.Item hidden name="CardType">
            <Input />
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }}>
            <Form.Item
                name="CardNumber"
                label="Card Number"
                rules={[cardNumberRule]}>
                <Cleave
                    className={classnames('ant-input', styles['cc-form-cleave'])}
                    autoComplete="cc-number"
                    maxLength={19}
                    placeholder="0000 0000 0000 0000"
                    options={{
                        creditCard: true,
                        onCreditCardTypeChanged: (ct: string) => {
                            ccForm.setFieldsValue({
                                CardType: CleaveTypeCreditCardTypeMapping[ct] ?? null
                            });
                        }
                    }} />
            </Form.Item>
            <Form.Item shouldUpdate={(prv, cur) => prv.CardType !== cur.CardType} noStyle>
                {({ getFieldValue }) => getFieldValue('CardType') != null
                    // ? (<i {...getCardIconDef(ccForm.getFieldValue('CardType') as CreditCardType)}></i>)
                    ? <FontAwesomeIcon
                        icon={getCardIconDef(
                            ccForm.getFieldValue('CardType') as CreditCardType)}
                        style={defaultCardIconStyle} />
                    : null}
            </Form.Item>
        </Form.Item>
        <Form.Item
            label="Card Holder Name"
            name="CardHolderName"
            rules={[getRuleObject(validateCreditCardHolderName)]}>
            <Input autoComplete="cc-name" />
        </Form.Item>
        <Row justify={"space-between"}>
            {
                cvnRequired ?
                    <Col span={10}>
                        <Form.Item
                            label="CVV/CVC"
                            // wrapperCol={{ span: 5 }}
                            name="CardCvn"
                            rules={[getRuleObject(validateCreditCardCvn)]}>
                            <Input
                                maxLength={4}
                                autoComplete="cc-csc" />
                        </Form.Item>
                    </Col>
                    : <></>
            }
            <Col span={10}>
                <Form.Item
                    label="Card Expiry"
                    name="CardExp"
                    rules={[getRuleObject(validateCreditCardExp)]}>
                    <DatePicker
                        picker="month"
                        mode="month"
                        disabledDate={disabledDate}
                        id={expDateInputId}
                        placeholder={expDateFormat}
                        autoComplete="cc-exp"
                        allowClear={false}
                        format={expDateFormat} />
                </Form.Item>
            </Col>
        </Row>
    </>);
};

export default CreditCardFields;

export { expDateFormat };

export type {
    CreditCardField
}