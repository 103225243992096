import { Checkbox, Col, Form, Modal, Result, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Moment } from 'moment';
import { FieldData } from 'rc-field-form/lib/interface';
import moment from 'moment';
import { fetchReconnectionInfo, selectCurrentAccountReconnectionInfo } from 'store/reconnectionInfoSlice';
import { AccountServiceReenDetailDto, ReconnectionRequestDto, StateCode } from 'data/CustomerPortalTypes';
import { selectCurrentAccount } from 'store/userSlice';
import styles from "../ElectricityReconnection.module.css";
import { generateRequiredRule as genRqdRule, getRuleObject, today } from 'utilities/Utility';
import DatePicker from 'components/Shared/DatePicker';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import GlobirdDivider from 'components/Shared/GlobirdDivider';
import SupermanAlert from 'components/Shared/SupermanAlert';
import { GlobirdTableContainer } from 'data/GlobirdTableContainer';
import { submitReconnectionAsync } from 'services/RequestDataService';
import { isDisabledDate } from 'utilities/DateTool';
import links from 'data/Links';

const { Item } = Form;

let submitted = false;
const computeDisabledReconnectionDate = (
    currentDate: Moment,
    reenDto: AccountServiceReenDetailDto) => {
    const today = moment();
    var calendar = reenDto.calendarControlDto;
    // if today cut off date have pasted, disabled
    if (currentDate.isSame(today, 'day')
        && today >= moment(calendar.sameDayReEnCutOffTime)) {
        return true;
    }

    // hard code saturday and sunday not use
    var newCalenDar = {
        ...calendar,
        daysOfWeekDisabled: [0,6]
    }
    return isDisabledDate(currentDate, newCalenDar);
}

const computeTodayButtonDisbaled = (reenDto: AccountServiceReenDetailDto) => {
    let today = moment();
    var calendar = reenDto.calendarControlDto;

    // if today disabled, disable the btn
    if (isDisabledDate(today, calendar)) {
        return true;
    }
    // if today cut off date have pasted, disabled
    if (calendar.sameDayReEnCutOffTime && today >= moment(calendar.sameDayReEnCutOffTime)) {
        return true;
    }

    return false;
}

const validateReenDateValue = (
    value: Moment,
    reenDto: AccountServiceReenDetailDto) => {
    if (!value) {
        return null;
    }

    return computeDisabledReconnectionDate(value, reenDto)
        ? "Invalid Date"
        : null;
}

const getReconnectionFeeByStateCode = (stateCode?: string) => {
    switch (stateCode) {
        case StateCode.VIC:
            return 15;
        case StateCode.NSW:
            return 38;
        case StateCode.SA:
            return 66;
        case StateCode.QLD:
            return 15;
        default:
            return 15;
    }
}

const ReconnectionRetry = ({
    accountServiceReenDetailDto: reenDto
}: {
    accountServiceReenDetailDto: AccountServiceReenDetailDto | undefined
}
) => {
    const { accountId, accountServiceId, nmi, siteAddress, stateCode, serviceOrderId, reconnectionFee } = reenDto || {};
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const currentAccount = useSelector(selectCurrentAccount);
    const currentAccountReconnectionInfo = useSelector(selectCurrentAccountReconnectionInfo)
    const [reconnectionRetryDate, setReconnectionRetryDate] = useState<Moment | undefined>();
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [formErrored, setFormErrored] = useState<boolean>(true);
    const [todayBtnDisabled, setTodayBtnDisabled] = useState<boolean>(false);
    const [showPostCompleteModal, setShowPostCompleteModal] = useState<boolean>(false);

    if (!reenDto) {
        // go back if no selected info
        navigate("/reconnection");
    }

    const feeTableData = useMemo(() => {
        return new GlobirdTableContainer(
            [
                <span className={styles['reconection-fee-table-header']}>Fee(GST inc.)</span>,
                ''
            ],
            [
                [
                    'Every standard attempt', <span className={styles['reconection-fee-table-row']}>{reconnectionFee}</span>
                ]
            ]);

    }, []);

    useEffect(() => {
        if (!currentAccount) {
            return;
        }

        // account changed
        if (currentAccount.accountId != accountId) {
            navigate("/reconnection");
        }

    }, [currentAccount])


    useEffect(() => {
        if (!currentAccountReconnectionInfo) {
            return;
        }

        if (!currentAccountReconnectionInfo.displayReconnectionPage) {
            navigate("/reconnection");
        }

        setTodayBtnDisabled(computeTodayButtonDisbaled(reenDto!))
    }, [currentAccountReconnectionInfo])

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
        let errored = false;
        for (let field of allFields) {
            if (field.errors?.length
                && field.errors?.length > 0) {
                errored = true;
            }
        }

        setFormErrored(errored);
    }


    const onFinish = async (value: any) => {
        // do submit handler
        if (!accountServiceId
            || !reconnectionRetryDate
            || submitted
            || !currentAccount) {
            return
        }

        var model: ReconnectionRequestDto = {
            accountId: currentAccount.accountId,
            accountServiceId: accountServiceId,
            reenDate: reconnectionRetryDate,
            serviceOrderId: serviceOrderId!
        };

        try {
            submitted = true;
            var result = await submitReconnectionAsync(model)
            if (result?.data) {
                dispatch(fetchReconnectionInfo(currentAccount.services.map(x => x.accountServiceId)))
                setShowPostCompleteModal(true);
            }
        } catch (error) {

        } finally {
            submitted = false;
        }
    }

    return (
        <div className="field-container" style={{ width: "85%" }}>
            <div className={styles["reconnection-panel"]}>
                <p className={styles["reconnection-heading"]}>
                    Electricity Reconnection
                </p>
                <p className={styles["reconnection-retry-heading-description"]}>
                    <li>Please confirm the following details</li>
                    <li>Complete the agreement below</li>
                </p>
                <Form
                    form={form}
                    name="reconnectionForm"
                    layout="vertical"
                    style={{ marginTop: "1rem" }}
                    onFieldsChange={onFieldsChange}
                    onFinish={onFinish}
                >
                    <div className={styles['reconnection-retry-panel']} >
                        <div className={classNames(styles['reconnection-retry-site-block'])}>
                            <Row className={styles['reconnection-retry-site-header']}>
                                <Col xs={9}>
                                    NMI
                                </Col>
                                <Col xs={14} push={1}>
                                    Address
                                </Col>
                            </Row>
                            <Row className={styles['reconnection-retry-site-body']}
                                align="middle">
                                <Col xs={9}>
                                    {nmi}
                                </Col>
                                <Col xs={14} push={1}>
                                    {siteAddress}
                                </Col>
                            </Row>
                        </div>
                        <div className={classNames(styles['reconnection-retry-date-block'])}>
                            <Row className={styles['reconnection-retry-date-row']}
                                wrap={false}>
                                <Bwtt
                                    type="primary"
                                    htmlType="button"
                                    disabled={todayBtnDisabled}
                                    disableMessage={'Sorry, a same day request is no longer available at the moment due to the cut-off time set by your local distributor, please select an alternative date.'}
                                    btnNode="Today"
                                    componentClassName={[styles['reconnection-today-button']]}
                                    onClick={() => {
                                        var current: Moment = today();
                                        form.setFieldsValue({
                                            reconnectionDate: current
                                        });
                                        setReconnectionRetryDate(current);
                                        setFormErrored(false);
                                    }} />
                                <div className={styles['reconnection-retry-date-or']}>
                                    OR
                                </div>
                                <Item
                                    name='reconnectionDate'
                                    className={styles['reconnection-date-item']}
                                    rules={[
                                        genRqdRule(null, 'Please select a date.'),
                                        getRuleObject(value => validateReenDateValue(value, reenDto!))
                                    ]}>
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        id="reconnectionDatePicker"
                                        autoComplete="off"
                                        picker="date"
                                        mode="date"
                                        onChange={newDate => setReconnectionRetryDate(newDate ?? undefined)}
                                        disabledDate={date => computeDisabledReconnectionDate(date, reenDto!)}
                                    />
                                </Item>
                            </Row>
                        </div>
                    </div>
                    <GlobirdDivider />
                    <div className={classNames('globird-row', styles['reconnection-agree-block'])}>
                        <div className={classNames(
                            'globird-col',
                            styles['reconnection-agree-col-block'])}>
                            <div style={{ marginBottom: '1rem' }}>
                                <SupermanAlert size={126} />
                            </div>
                            <ul className={classNames(styles['reconnection-agree-text'])}>
                                <li>
                                    All the main switches on the electricity switchboard and circuit breakers are in the OFF position.
                                </li>
                                <li>
                                    Further attempt(s) will incur additional charge(s), for each additional request. Even on the failed attempt(s) caused by you fail to turn off all the main power switches as requested.
                                </li>
                                <li>
                                    Any applicable fees will be charged on your next bill.
                                </li>
                                <li>
                                    Re-energisation can happen anytime during the day you select, if you need the power on urgently, PLEASE CALL US on <a href={links.phone.general}>{links.phone.generalDisplay}</a>.
                                </li>
                                <br />
                                {stateCode === StateCode.NSW &&
                                    <div>
                                        <span className={styles['reconection-agree-terms-warning']}>Additional Terms & Conditions for NSW customers:
                                            <br /></span>
                                        <span>You have informed GloBird if any of the following issues exist at this site:​</span>
                                        <li>
                                            If there are damaged, dangerous, or modified appliances
                                        </li>
                                        <li>
                                            If wiring has been exposed to water
                                        </li>
                                        <li>
                                            If power sockets have multiple appliances plugged in
                                        </li>
                                        <li>
                                            If the re-connection will have any negative impact or risk on the infrastructure. For example: solar, batteries, EV charging station, pool pumps etc
                                        </li>
                                        <li>
                                            If you are unaware how to safely operate the meter box, switchboard, or any alternative energy supplies like solar, batteries and other equipment.                                     </li>
                                        <li>
                                            If any electrical works have recently taken place
                                        </li>
                                        <li>
                                            If you are currently not at or unable to access this site or are unaware of where the main switchboard is located
                                        </li>
                                        <li>
                                            If the premises has been without power for more than 6 months
                                        </li>
                                        <li>
                                            If you have any reason to believe the built-in appliances in the premises were installed incorrectly
                                        </li>
                                        <li>
                                            If there are any signs of damaged wiring
                                        </li>
                                    </div>
                                }
                            </ul>
                        </div>
                        <div className={classNames(
                            'globird-col',
                            styles['reconnection-agree-col-block'])}>
                            <div>
                                {/*order not to use small table*/}
                                <table className={classNames('globird-table')}>
                                    <thead>
                                        <tr>
                                            {
                                                feeTableData.headerList.map((header, i) =>
                                                    <th key={i}>{header}</th>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            feeTableData.rowList.map((row, i) =>
                                                <tr key={i}>
                                                    {
                                                        row.map((cell, j) =>
                                                            <td key={j}>
                                                                {cell}
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <ul>
                                <li>
                                    The more notice you give us, the more likely we can get your connection done on time and for the lowest cost.
                                </li>
                                <li>
                                    Fees shown are indicative only, additional costs may apply depending on the meter type, access, urgency, or if technicians need to site visit.
                                </li>
                                <li>
                                    Depending on the date you request, a "same day" reconnection request may incur additional after-hours charges
                                </li>
                                <li>
                                    For full details on fees visit:
                                    <span style={{ color: '#e71d73', textDecoration: 'underline' }}>
                                        <a className="globird-link" target="_blank" href={links.corporateWebsite.legalFees} rel="noreferrer">
                                            www.globirdenergy.com.au/legal/fees/
                                        </a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Item
                        name='reconnectionTerms'
                        valuePropName='checked'
                        style={{ paddingTop: '20px' }}
                    >
                        <Checkbox
                            style={{ fontWeight: '600', fontSize: '1.1rem' }}
                            value={termsAccepted}
                            onChange={event => setTermsAccepted(event.target.checked)}>
                            I understand & agree to the above.
                        </Checkbox>
                    </Item>
                    <Item>
                        <Bwtt
                            type="primary"
                            htmlType="submit"
                            disabled={!termsAccepted || formErrored}
                            disableMessage={'Please fill in reen date & accept the terms.'}
                            btnNode="Submit"
                            componentClassName={['form-save-button']} />
                    </Item>
                </Form>
            </div>
            <Modal
                visible={showPostCompleteModal}
                afterClose={() => navigate(`/dashboard`)}
                onCancel={() => setShowPostCompleteModal(false)}
                onOk={() => setShowPostCompleteModal(false)}
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable
                okButtonProps={{
                    size: 'large',
                    style: { minWidth: '150px' }
                }}
                centered>
                <Result
                    status="success"
                    style={{ paddingBottom: '10px' }}
                    title={
                        <>
                            <span>
                                Thank you, your request has been submitted, and you will be notified by SMS about the reconnection result.
                            </span>
                            <br />
                            <span>
                                If changes required, please call us on <a href={links.phone.general}>{links.phone.generalDisplay}</a> ASAP.
                            </span>
                        </>
                    }
                />
            </Modal>
        </div>
    )
}

export default ReconnectionRetry
