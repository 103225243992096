import ChangeEmailForm from 'components/Shared/ChangeEmailForm';
import SectionMenu from '../SectionMenu';

const ChangeEmail = () => {
    return <div className="form-container">
        <div className="globird-menu-option">
            <p className="globird-menu-label">Show me: </p>
            <SectionMenu
                id="changeEmailMenuSelector"
                defaultIndex={2} />
        </div>
        <div className="field-container">
            <ChangeEmailForm />
        </div>
    </div >;
}

export default ChangeEmail;