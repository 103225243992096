import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import { RootState } from 'store/store';
import { getReconnectionInfoAsync } from "services/RequestDataService";
import { ReconnectionInfo } from "data/CustomerPortalTypes"

export const fetchReconnectionInfo = createAsyncThunk(
    'fetchReconnectionInfo',
    async (accountServiceIds: number[]) => {
        return await getReconnectionInfoAsync(accountServiceIds);
    }
)

type ReconnectionInfoState = {
    [key: string]: ReconnectionInfo | null
}

const initialState: ReconnectionInfoState = {};

const reconnectionInfoSlice = createSlice({
    name: 'reconnectionInfo',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReconnectionInfo.fulfilled, (state, action) => {
            if (action?.payload?.success !== true) {
                return;
            }

            return {
                ...state,
                [action.meta.arg.toString()]: action.payload.data ?? null
            }
        })
    }
});

export default reconnectionInfoSlice.reducer;

export const getReconnectionInfoByAccountId = (accountId?: number) =>
    (state: RootState) => accountId
        ? state.reconnectionInfo[accountId]
        : undefined;

export const selectCurrentAccountReconnectionInfo = (state: RootState) =>
    state?.user?.data?.currentAccount?.accountId
        ? state.reconnectionInfo[(state.user.data.currentAccount.services.map(x => x.accountServiceId)).toString()]
        : undefined;

export const { } = reconnectionInfoSlice.actions;