import HCaptcha from '@hcaptcha/react-hcaptcha';
import { forwardRef } from 'react';
import { ProdHostNames } from 'data/CustomerPortalTypes';

const siteKey = ProdHostNames.findIndex(
    n => window.location.hostname.toLowerCase() === n) >= 0
    ? '24a50ebd-ecc4-47d6-8737-b10742cf2f4f'
    : '0804d7e3-8bfc-40bf-8fca-e047631717c0'; // it's OK expose both keys as the site keys are not sensitive

const Captcha = forwardRef<HCaptcha, { onVerify?: (token: string) => void }>((props, ref) =>
    <HCaptcha
        ref={ref}
        sitekey={siteKey}
        onVerify={props.onVerify} />);

export default Captcha;