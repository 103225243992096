import {
    createSlice,
    createAsyncThunk,
    PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
    getDirectDebitInfo
} from 'services/RequestDataService';
import { DirectDebitInfo } from 'data/CustomerPortalTypes';

export const fetchDirectDebitInfo = createAsyncThunk(
    'fetchDirectDebitInfo',
    async (accountId: number) => {
        return await getDirectDebitInfo(accountId);
    });

type DirectDebitInfoState = {
    [key: string]: DirectDebitInfo | null
}

const initialState: DirectDebitInfoState = {};

const directDebitInfoSlice = createSlice({
    name: 'directDebitInfo',
    initialState: initialState,
    reducers: {
        updateDirectDebitInfo(
            state,
            action: PayloadAction<DirectDebitInfo & { accountId: number }>) {

            if (!action?.payload) {
                return;
            }

            return {
                ...state,
                [action.payload.accountId.toString()]: action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDirectDebitInfo.fulfilled, (state, action) => {

            if (action?.payload?.success !== true) {

                return;
            }

            return {
                ...state,
                [action.meta.arg.toString()]: action.payload.data ?? null
            };
        })
    },
});

export default directDebitInfoSlice.reducer;

export const getDirectDebitInfoByAccountId = (accountId?: number) =>
    (state: RootState) => accountId
        ? state.directDebit[accountId]
        : undefined;

export const getCurrentAccountDirectDebitInfo = () =>
    (state: RootState) => state?.user?.data?.currentAccount?.accountId
        ? state.directDebit[state.user.data.currentAccount.accountId]
        : undefined;

export const { updateDirectDebitInfo } = directDebitInfoSlice.actions;