import { Select } from 'antd';
import { Site, AccountServiceStatusForDisplay } from 'data/CustomerPortalTypes';
import { CSSProperties, useState } from 'react';
import { getServiceAbrev, getServiceIcon } from 'utilities/Utility';

const { Option } = Select;

const SiteSelector = ({
    onChange,
    sites,
    defaultSite,
    currentSite,
    className,
    style
}:
    {
        onChange: (s: Site | null) => any,
        sites: Site[],
        defaultSite?: Site,
        currentSite?: Site,
        className?: string,
        style?: CSSProperties
    }) => {

    const [site, setSite] = useState<Site | null>(
        defaultSite ?? sites?.length > 0
            ? sites[0]
            : null);

    sites ??= [];

    const onSiteChange = (aId: number) => {
        const s = sites.find(s => s.accountServiceInfo.accountServiceId === aId) ?? null;
        setSite(s);
        onChange(s);
    };

    return <Select
        onChange={onSiteChange}
        placeholder="Select a site"
        className={className}
        style={style}
        value={currentSite?.accountServiceInfo.accountServiceId ?? null}>
        {
            sites.map(s => (
                <Option
                    key={`site-option-${s.nmi}`}
                    value={s.accountServiceInfo.accountServiceId}>
                    <span style={{ padding: '0 5px' }}>{getServiceIcon(s.nmi)}</span>{`${getServiceAbrev(s.nmi)} - ${s.nmi} - ${s.address}
                    ${AccountServiceStatusForDisplay[s.accountServiceInfo.status] ? ' - ' + AccountServiceStatusForDisplay[s.accountServiceInfo.status] : ''}`}
                </Option>
            ))
        }
    </Select>;
}

export default SiteSelector;