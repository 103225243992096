import { Message, MessageWorker } from 'data/Message';

export default class MessageService {
    private static messageServiceInstance: MessageService;

    messageWorkerList: MessageWorker[] = [];

    private constructor() { }

    public static get() {
        if (!MessageService.messageServiceInstance) {
            MessageService.messageServiceInstance = new MessageService();
        }

        return MessageService.messageServiceInstance;
    }

    subscribe(messageWorker: MessageWorker): number {
        this.messageWorkerList.push(messageWorker);
        return this.messageWorkerList.length - 1;
    }

    unsubscribe(messageWorkerId: number) {
        this.messageWorkerList = this.messageWorkerList.filter((_, i) => i !== messageWorkerId);
    }

    send(message: Message) {
        let targetWorkers = this.messageWorkerList.filter(x => x.message.topic === message.topic);
        if (targetWorkers && targetWorkers.length > 0) {
            for (let worker of targetWorkers) {
                worker.handler(message.data);
            }
        }
    }
}

