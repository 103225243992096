import { PropsWithChildren } from 'react';
import { Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';

const ActionResultPanel = ({ status, children }: PropsWithChildren<{ status?: ResultStatusType }>) =>
    <Result
        status={status}
        title={children}
        style={{ maxWidth: '700px' }}>
    </Result>;

export default ActionResultPanel;