import {Divider} from 'antd';
import {CSSProperties} from 'react';
import styles from './GlobirdOr.module.css'

export const GlobirdOrPip = ({style}: { style?: CSSProperties }) => {
    style ??= {};

    return (<Divider className={styles['divider']} style={{
        color: '#ededed',
        ...style
    }}>
        <div className={styles['globird-or-pip']}>
            OR
        </div>
    </Divider>)
};