import GlobirdSelector from 'components/Shared/GlobirdSelector';
import {
    AccountServiceInfo,
    AccountServiceStatusDateDto,
    AccountServiceStatusForDisplay,
    ActiveMeterStatus,
    DefaultDisplayDateFormat,
    DefaultDisplayMonthFormat,
    EuropaAccountServiceStatus,
    MeterReadBasicModel,
    MeterReadMeter,
    MeterReadRequest,
    MeterReadSmartModel,
    ServiceType,
    Site
} from 'data/CustomerPortalTypes';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
    downLoadCsvFile,
    getAccountServiceStatusDateAsync,
    getBasicMeterRead,
    getMeterListFromRead,
    getPowerMeterTypes,
    getSmartMeterRead
} from 'services/RequestDataService';
import {selectCurrentAccount} from 'store/userSlice';
import styles from './UsageChart.module.css';
import { useNavigate } from 'react-router-dom';
import { Select, DatePicker, Checkbox } from 'antd';
import ReactECharts from 'echarts-for-react';
import {cloneDeep} from 'lodash';
import moment, {Moment} from 'moment';
import {PanelMode, RangeValue} from 'rc-picker/lib/interface';
import menuOptions from '../MenuOptions';
import SiteSelector from 'components/Shared/SiteSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { shouldBeDisabledByDayDateRange, today, toSite, toUtcStartOfDay, getTimeZoneOffsetToAEST } from 'utilities/Utility';
import links from 'data/Links';

enum TimeGranularityEnum {
    Day = 'Day',
    Month = 'Month',
    Year = 'Year',
    day = 'day',
    month = 'month',
    year = 'year',
    date = 'date',
    Hour = "Hour",
    HalfHour = "HalfHour"
}

type Series = {
    name: string;
    type: string;
    stack: string | null;
    data: any[],
    symbolSize: number
}
type Legend = {
    show: boolean,
    top: number | null,
    data: Array<string>
}

const checkIsCrossAccountService = (svc: AccountServiceInfo) => svc
    .allAccountServiceIds
    .map(sId => accountServiceFrmpDateMap.map?.[sId])
    .filter(s => s?.frmpDate)
    .length > 1;

// const values
const DateFormatYMD = "YYYY-MM-DD";
const ServiceDateFormatYMD = "YYYY/MM/DD";
const DateFormatYM = "YYYY-MM";
const DateFormatDM = "DD-MMM"
const DateFormatY = "YYYY";
const DateFormatYMDHm = "YYYY-MM-DD HH:mm";
const DateFormatHm = "HH:mm";
const HourTypeArr = [TimeGranularityEnum.Hour, TimeGranularityEnum.HalfHour];
const HourTypeMinutes = {
    [TimeGranularityEnum.Hour]: 60,
    [TimeGranularityEnum.HalfHour]: 30,
    30: TimeGranularityEnum.HalfHour,
    60: TimeGranularityEnum.Hour
};
const { RangePicker } = DatePicker;
const { Option } = Select;
const TimeZoneOffset = getTimeZoneOffsetToAEST();
const MinutesADay = 1440;
const MinsecondsADay = 3600 * 24 * 1000;
const MinsecondsAHour = 60 * 60 * 1000;
const MinsecondsAHalfHour = 30 * 60 * 1000;
const DefaultOptions = {
    title: {
        text: '',
        left: 'center',
        textStyle: {
            color: '#333333',
            fontWeight: 'bold',
            fontSize: 16,
        }
    },
    color: [
        '#F73809', '#0909F7', '#09F7F7', '#FF00FF',
        '#FFFF00', '#00FF33', '#FAD860', '#F3A43B',
        '#60C0DD', '#D7504B', '#C6E579', '#F4E001',
        '#F0805A', '#26C0C0'],
    dataZoom: new Array<any>(),
    tooltip: {
        trigger: 'axis',
        position: null as null | Array<number> | Array<string>,
        formatter: function (params: string | any[]) {
            var htmlStr = '';
            for (var i = 0; i < params.length; i++) {
                var param = params[i];
                var value = param.value;//[x,y]
                var xName = safeMoment(value[0]).format(DateFormatYMD);//x
                var seriesName = param.seriesName;
                var color = param.color;

                if (i === 0) {
                    htmlStr += xName + '<br/>';
                }
                htmlStr += '<div>';

                htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' + color + ';"></span>';
                htmlStr += seriesName + ': ' + value[1];

                htmlStr += '</div>';
            }
            return htmlStr;
        }
    },
    legend: [{
        show: true,
        top: 30,
        data: new Array<string>()
    }] as Array<Legend>,
    grid: {
        top: null as number | null,
        left: '30px',
        right: '30px',
        bottom: '2%',
        containLabel: true
    },
    xAxis: {
        type: "time",
        minInterval: MinsecondsADay,
        splitLine: {
            show: false
        },
        boundaryGap: ['0%', '0%'],
        axisLabel: {
            formatter: (value: any, index: number) => safeMoment(value).format(DateFormatYMD),
            rotate: 0
        }
    },
    yAxis: {
        scale: true,
        type: 'value',
        name: "",
        min: null as (number | null),
        max: null as (number | null),
        nameTextStyle: {
            rich: {
                a: {
                    fontSize: '8px',
                    verticalAlign: 'top'
                },
                i: {
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                }
            }
        }
    },
    series: new Array<Series>()
};
const MeterType = {
    BASIC: "BASIC",
    SMART: "SMART"
}
const HourTimeGranularity = [TimeGranularityEnum.Hour, TimeGranularityEnum.HalfHour];
const TimeGranularity = {
    BASIC: [TimeGranularityEnum.Day],
    SMART: [TimeGranularityEnum.Year, TimeGranularityEnum.Month, TimeGranularityEnum.Day, ...HourTimeGranularity]
}
const BasicMeterReadDataType = {
    InvoiceReads: "Invoice Read Data",
    ActualReads: "Distributor Read/Self Read Data"
}

const requestCache = {
    meterSeted: true,
    rangeSeted: true
}

const safeMoment = (date: number | string | Date | null | undefined | Moment) => {
    if (typeof date === 'string' && date.split("-").length > 0) {
        date.replace(/-/g, "/");
    }
    return moment(date);
}

const mapSort = (map: Map<string, Series>) => {
    const sortedMap = new Map<string, Series>();
    const keys = Array.from(map.keys());

    keys.sort();

    keys.forEach(x => {
        sortedMap.set(x, map.get(x)!)
    })

    return sortedMap;
}

const emptyBasicReads = {
    [BasicMeterReadDataType.InvoiceReads]: new Array<MeterReadBasicModel>(),
    [BasicMeterReadDataType.ActualReads]: new Array<MeterReadBasicModel>(),
} as Record<string, Array<MeterReadBasicModel>>;
const emptySmartReads = new Array<MeterReadSmartModel>();
const emptyRange = null as RangeValue<moment.Moment>;
const EndOfTodayMoment = moment().endOf('day');

type AccountServiceFrmpDateMap = {
    map: null | Record<number, AccountServiceStatusDateDto>,
    callback: null | Function
}
const accountServiceFrmpDateMap: AccountServiceFrmpDateMap = {
    map: null,
    callback: null
};

const activeServiceStatus: EuropaAccountServiceStatus[] = [EuropaAccountServiceStatus.PendingSwitchOut, EuropaAccountServiceStatus.Switched];

const activeMeterStatus: ActiveMeterStatus[] = [ActiveMeterStatus.Turnedon, ActiveMeterStatus.Energized]

let smartReadCache = emptySmartReads;
let basicReadCache = emptyBasicReads;
let hasUserSelectedDate = false;
let optionsCache = cloneDeep(DefaultOptions);

// functions
const momentSort = (a: string | Date, b: string | Date) => safeMoment(a).isBefore(safeMoment(b)) ? -1 : 1;
const dateKey = (date: string | Date) => safeMoment(date).format(DateFormatYMD);
const getPickerType = (timeGranularity: string): Exclude<PanelMode, 'decade' | 'time'> => {
    switch (timeGranularity) {
        case TimeGranularityEnum.Year:
            return TimeGranularityEnum.year;
        case TimeGranularityEnum.Month:
            return TimeGranularityEnum.month;
        default:
            return TimeGranularityEnum.date;
    }
}

const getMinutesFromTimeSpan = (timeSpan: string) => {
    let hmsArr = timeSpan.split(":");
    let hourArr = hmsArr[0].split(".");
    return hourArr.length > 1
        ? MinutesADay * parseInt(hourArr[0]) + parseInt(hourArr[1]) * 60 + parseInt(hmsArr[1])
        : parseInt(hmsArr[0]) * 60 + parseInt(hmsArr[1]);
}

const getPickerFormat = (timeGranularity: string): string | undefined => {
    switch (timeGranularity) {
        case TimeGranularityEnum.Day:
            return DefaultDisplayDateFormat;
        case TimeGranularityEnum.Month:
            return DefaultDisplayMonthFormat;
        case TimeGranularityEnum.Year:
            return undefined;
        default:
            return DefaultDisplayDateFormat;
    }
}

let dateCache = null as Moment | null;
const labelFormatter = (type: TimeGranularityEnum, date: string | Moment, index: number = -1, usedForX: boolean = false) => {
    let breakline = usedForX && (window.innerWidth > 640);
    let dateMoment = safeMoment(date);
    switch (type) {
        case TimeGranularityEnum.Year:
            return dateMoment.format(DateFormatY);
        case TimeGranularityEnum.Month:
            return dateMoment.format(DateFormatYM);
        case TimeGranularityEnum.HalfHour:
        case TimeGranularityEnum.Hour:
            return breakline
                ? `${dateMoment.format(DateFormatHm)}\n${dateMoment.format(DateFormatDM)}\n${dateMoment.format(DateFormatY)}`
                : dateMoment.format(DateFormatYMDHm);
        default:
            if (usedForX) {
                let monthend1 = safeMoment(date).date(29).diff(dateMoment, 'day') === 0;
                let monthend2 = safeMoment(date).month() === 1 && safeMoment(date).date(28).diff(dateMoment, 'day') === 0;
                if (monthend1 || monthend2) {
                    if (index <= 1
                        || ((dateCache !== null) && safeMoment(date).date(22).diff(dateCache, 'day') === 0)) {
                        return "";
                    }
                }
                dateCache = dateMoment;
            }
            return breakline
                ? `${dateMoment.format(DateFormatDM)}\n${dateMoment.format(DateFormatY)}`
                : dateMoment.format(DateFormatYMD);
    }
}
const setOptionsDateFormatter = (tg: TimeGranularityEnum, isSmart: boolean, specialEndMap: Map<string, Moment> = new Map<string, Moment>()) => {
    optionsCache.tooltip.formatter = (params: string | any[]) => {
        let htmlStr = '';
        let items = '';
        let xName = '';
        let generated = 0;
        let usage = 0;
        // let legendData = new Array<string>();
        // for(let i = 0; i < optionsCache.legend.length; i++){
        //     legendData.push(...optionsCache.legend[i].data);
        // }
        for (let i = 0; i < params.length; i++) {
            let param = params[i];
            let value = param.value;//[x,y]
            let seriesName = param.seriesName;
            let color = param.color;
            // if (legendData.indexOf(seriesName) < 0) {
            //     continue;
            // }
            value[1] < 0
                ? generated += value[1]
                : usage += value[1];

            items += '<div>';
            items += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' + color + ';"></span>';
            items += seriesName + ': ' + value[1];
            items += '</div>';
        }

        if (params.length > 0) {
            let param = params[0];
            let value = param.value;//[x,y]
            xName = labelFormatter(tg, value[0]);//x
            if (isSmart && smartReadCache.length > 0) {
                let copyList = cloneDeep(smartReadCache);
                copyList.sort((a, b) => momentSort(a.readDate, b.readDate));
                let start = safeMoment(copyList[0].readDate);
                let end = safeMoment(copyList[copyList.length - 1].readDate);
                if (tg === TimeGranularityEnum.Year) {
                    let tstart = safeMoment(xName).startOf('year');
                    let tend = safeMoment(xName).endOf('year');
                    let startStr = tstart.isBefore(start) ? start.format(DateFormatYMD) : tstart.format(DateFormatYMD);
                    let endStr = tend.isBefore(end) ? tend.format(DateFormatYMD) : end.format(DateFormatYMD);
                    xName = startStr + " to " + endStr;
                } else if (tg === TimeGranularityEnum.Month) {
                    let tstart = safeMoment(xName).startOf('month');
                    let tend = safeMoment(xName).endOf('month');
                    let startStr = tstart.isBefore(start) ? start.format(DateFormatYMD) : tstart.format(DateFormatYMD);
                    let endStr = tend.isBefore(end) ? tend.format(DateFormatYMD) : end.format(DateFormatYMD);
                    xName = startStr + " to " + endStr;
                } else if (HourTypeArr.indexOf(tg) > -1) {
                    let specialEnd = specialEndMap.get(xName);
                    let tend = specialEnd || safeMoment(xName).add(HourTypeMinutes[tg as Extract<TimeGranularityEnum.Hour, TimeGranularityEnum.HalfHour>], "minutes");
                    let startStr = xName;
                    let endStr = tend.format(DateFormatHm);
                    if (endStr === "00:00") {
                        endStr = "24:00"
                    }
                    xName = startStr + " to " + endStr;
                }
            }
        }

        htmlStr += xName + '<br/>';
        if (isSmart) {
            htmlStr += "Total Usage: " + usage.toFixed(4) + '<br/>';
            generated < 0 && (htmlStr += "Total Solar Export: " + (generated * -1).toFixed(4) + '<br/>');
        }
        htmlStr += items;
        return htmlStr;
    };
    optionsCache.xAxis.axisLabel.formatter =
        (value: any, index: number) => labelFormatter(tg as TimeGranularityEnum, value, index, true);
}

const buildReadObj = (list: Array<MeterReadBasicModel> | Array<MeterReadSmartModel>, tg: string, isSmart: boolean, displayInLocalTimeZone: boolean)
    : [Array<MeterReadBasicModel | MeterReadSmartModel>, Map<string, Moment>]=> {
    const specialEndMap = new Map<string, Moment>();
    if (isSmart) {
        var copyList = cloneDeep(list) as Array<MeterReadSmartModel>;
        copyList.sort((a, b) => momentSort(a.readDate, b.readDate));
        let readObj = new Map<string, MeterReadSmartModel>();
        switch (tg) {
            case TimeGranularityEnum.Year: {
                for (let i = 0; i < copyList.length; i++) {
                    let key = buildLegend(copyList[i]) + safeMoment(copyList[i].readDate).year() + "";
                    if (readObj.has(key)) {
                        let read = readObj.get(key)!;
                        read.usage += copyList[i].usage;
                        read.usage = Number(read.usage.toFixed(4));
                        readObj.set(key, read)
                    } else {
                        let read = copyList[i];
                        read.readDate = safeMoment(copyList[i].readDate).startOf("year").toDate();
                        readObj.set(key, read)
                    }
                }
                optionsCache.dataZoom = [];
                optionsCache.grid.bottom = '2%';
                optionsCache.xAxis.minInterval = MinsecondsADay * 365;
                break;
            }
            case TimeGranularityEnum.Month: {
                for (let i = 0; i < copyList.length; i++) {
                    let key = buildLegend(copyList[i]) + safeMoment(copyList[i].readDate).startOf("month").format(DateFormatYM);
                    if (readObj.has(key)) {
                        let read = readObj.get(key)!;
                        read.usage += copyList[i].usage;
                        read.usage = Number(read.usage.toFixed(4));
                        readObj.set(key, read)
                    } else {
                        let read = copyList[i];
                        read.readDate = safeMoment(copyList[i].readDate).startOf("month").toDate();
                        readObj.set(key, read)
                    }
                }
                optionsCache.dataZoom = [];
                optionsCache.grid.bottom = '2%';
                optionsCache.xAxis.minInterval = MinsecondsADay * 28;
                break;
            }
            case TimeGranularityEnum.HalfHour:
            case TimeGranularityEnum.Hour: {
                let minutes = 60;
                optionsCache.xAxis.minInterval = MinsecondsAHour;
                if (tg === TimeGranularityEnum.HalfHour) {
                    minutes = 30;
                    optionsCache.xAxis.minInterval = MinsecondsAHalfHour;
                }
                if (copyList.length > 0) {
                    let parts = MinutesADay / minutes;
                    let readCountADay = copyList[0].usageArray.length;
                    let minuteStep = readCountADay / MinutesADay;
                    let stepCount = ~~(readCountADay / parts);
                    let offset = displayInLocalTimeZone
                        ? TimeZoneOffset
                        : 0;
                    const fillHourRead = (start: number, end: number, read: MeterReadSmartModel) => {
                        let startMoment = safeMoment(read.readDate).startOf("day").add(start + offset, "minute");
                        let endMoment = safeMoment(read.readDate).startOf("day").add(end + offset, "minute");
                        let usageArr = [...read.usageArray];
                        let usageArrIntp = usageArr.splice(~~(start * minuteStep), ~~((end - start) * minuteStep));
                        while (usageArrIntp.length > 0) {
                            let t = usageArrIntp.splice(0, stepCount);
                            let copyRead = cloneDeep(read);
                            copyRead.readDate = startMoment.toDate();
                            copyRead.usage = t.length > 0 ? Number(t.reduce((pre, cur) => pre + cur).toFixed(4)) : 0;
                            let key = buildLegend(copyRead) + startMoment.format(DateFormatYMDHm);
                            startMoment.add(minutes, "minutes");
                            // for hour Granularity and end at 30 minute
                            if(startMoment.isAfter(endMoment)) {
                                startMoment.add(-minutes, "minutes");
                                let specialStart = labelFormatter(tg, startMoment);
                                specialEndMap.set(specialStart, endMoment);
                            }
                            readObj.set(key, copyRead)
                        }
                    }

                    for (let i = 0; i < copyList.length; i++) {
                        if (copyList[i].timePeriods) {
                            for (let j = 0; j < copyList[i].timePeriods.length; j++) {
                                let tp = copyList[i].timePeriods[j];
                                let startMinute = getMinutesFromTimeSpan(tp.startTime);
                                let endMinute = getMinutesFromTimeSpan(tp.endTime);
                                if (startMinute < endMinute) {
                                    fillHourRead(startMinute, endMinute, copyList[i]);
                                } else {
                                    fillHourRead(0, endMinute, copyList[i]);
                                    fillHourRead(startMinute, MinutesADay, copyList[i]);
                                }
                            }
                        } else {
                            fillHourRead(0, MinutesADay, copyList[i]);
                        }
                    }
                    optionsCache.dataZoom = [];
                    optionsCache.grid.bottom = '2%';
                    if (copyList.length > 1) {
                        var startValue = safeMoment(copyList[0].readDate).startOf('day').add(offset, 'minute').toDate();
                        var endValue = safeMoment(copyList[0].readDate).startOf('day').add(offset + MinutesADay, 'minute').toDate();
                        optionsCache.dataZoom = [
                            {
                                id: 'dataZoomX',
                                type: 'slider',
                                show: true,
                                startValue,
                                endValue,
                                brushSelect: false,
                                maxValueSpan: MinsecondsADay,
                                handleSize: 8
                            },
                            {
                                type: 'inside',
                                startValue,
                                endValue,
                                zoomOnMouseWheel: false,
                                moveOnMouseWheel: true
                            }
                        ]
                        optionsCache.grid.bottom = '15%';
                    }
                }
                break;
            }
            default: {
                optionsCache.dataZoom = [];
                optionsCache.grid.bottom = '2%';
                optionsCache.xAxis.minInterval = MinsecondsADay;
                copyList.forEach((r: MeterReadSmartModel) => readObj.set(buildLegend(r) + dateKey(r.readDate), r));
                if (copyList.length > 1) {
                    let startValue = copyList[0].readDate;
                    let endValue = copyList[copyList.length - 1].readDate;
                    optionsCache.dataZoom = [
                        {
                            id: 'dataZoomX',
                            type: 'slider',
                            show: true,
                            startValue,
                            endValue,
                            brushSelect: true,
                            minValueSpan: MinsecondsADay,
                            handleSize: 8
                        },
                        {
                            type: 'inside',
                            startValue,
                            endValue,
                            zoomOnMouseWheel: false,
                            moveOnMouseWheel: true
                        }
                    ]
                    optionsCache.grid.bottom = '15%';
                }
                break;
            }
        }
        return [Array.from(readObj.values()), specialEndMap];
    } else {
        let copyList = cloneDeep(list) as Array<MeterReadBasicModel>;
        copyList.sort((a, b) => momentSort(a.readDate, b.readDate));
        let readObj = new Map<string, MeterReadBasicModel>();
        if (copyList.length > 1) {
            let startValue = safeMoment(copyList[0].readDate).toDate();
            let endValue = safeMoment(copyList[copyList.length - 1].readDate).toDate();
            optionsCache.dataZoom = [
                {
                    id: 'dataZoomX',
                    type: 'slider',
                    show: true,
                    startValue,
                    endValue,
                    brushSelect: true,
                    minValueSpan: MinsecondsADay,
                    handleSize: 50,
                    moveHandleSize: 12
                },
                {
                    type: 'inside',
                    startValue,
                    endValue,
                    zoomOnMouseWheel: false,
                    moveOnMouseWheel: true
                }
            ];
            optionsCache.grid.bottom = '15%';
        }
        copyList.forEach((r: MeterReadBasicModel) => readObj.set(buildLegend(r) + dateKey(r.readDate), r));
        return [Array.from(readObj.values()), specialEndMap];
    }
}

const qianhengxian = (s: string) => s ? `-${s}` : '';

const buildLegend = (r: MeterReadBasicModel | MeterReadSmartModel) => {
    let strarr = [r.suffix, r.chargeType]
    let pre = strarr.filter(s => Boolean(s)).join("-");
    if (pre) {
        return `${pre}`;
    }
    return "";
};

// const splitSeries = (list: Series[]) => {
//     let result = new Array<Series>();
//     for (let v of list) {
//         let max = 0;
//         v.data.forEach(t => max = t[1] > max ? t[1] : max);
//         max = max / 2;
//         v.data.sort((a, b) => a[0] - b[0]);
//         let t = new Array<number>();
//         t.push(0);
//         for (let i = 0; i < v.data.length - 1; i++) {
//             let x = v.data[i + 1][1] - v.data[i][1];
//             if (x < 0 && -x > max) {
//                 t.push(i + 1);
//             }
//         }
//         t.push(v.data.length);
//         for (let i = 0; i < t.length - 1; i++) {
//             let c = cloneDeep(v);
//             c.data = v.data.slice(t[i], t[i + 1]);
//             result.push(c);
//         }
//     }
//     return result;
// }

const dataProcesser = (list: Array<MeterReadBasicModel> | Array<MeterReadSmartModel>, isSmart: boolean, timeGranularity: string, displayInLocalTimeZone: boolean)
    : [string[], Series[], Map<string, Moment>] => {
    var [builtList, specialEndMap] = buildReadObj(list, timeGranularity, isSmart, displayInLocalTimeZone);
    var legends = new Map<string, Series>();
    // var groups = new Map<string, Series>();
    // var basicRegressionSeries = new Array<Series>();
    builtList.sort(
        (a: MeterReadBasicModel | MeterReadSmartModel, b: MeterReadBasicModel | MeterReadSmartModel) => momentSort(a.readDate, b.readDate));

    var chartType = isSmart ? "bar" : "line";
    let max = 0;
    let min = 0;
    builtList.forEach((r: MeterReadBasicModel | MeterReadSmartModel) => {
        let legend = buildLegend(r);
        let seriesData;
        if (isSmart) {
            var flag = r.suffix.startsWith("B") ? -1 : 1;
            let usage = (r as MeterReadSmartModel).usage * flag;
            seriesData = [r.readDate, usage];
            min = min < usage ? min : usage;
            max = max > usage ? max : usage;
        } else {
            seriesData = [r.readDate, (r as MeterReadBasicModel).readIndex];
            // let timeSeriesData = [(new Date(r.readDate)).getTime(), (r as MeterReadBasicModel).readIndex];
            // let key = r.suffix || "Gas";
            // groups.has(key)
            //     ? groups.get(key)!.data.push(timeSeriesData)
            //     : groups.set(key, {
            //         name: key,
            //         type: 'line',
            //         stack: key,
            //         data: [timeSeriesData],
            //         symbolSize: 0.1
            //     });
        }
        legends.has(legend)
            ? legends.get(legend)!.data.push(seriesData)
            : legends.set(legend, {
                name: legend,
                type: chartType,
                stack: isSmart ? "one" : null,
                data: [seriesData],
                symbolSize: 10,
            });
    })

    if (!isSmart) {
        // basicRegressionSeries = splitSeries(Array.from(groups.values()));
        // basicRegressionSeries.forEach(s => {
        //     let t = ecStat.regression("polynomial", s.data, 1);
        //     s.data = t.points;
        // })
        optionsCache.yAxis.min = null;
        optionsCache.yAxis.max = null;
    } else {
        optionsCache.yAxis.min = min < 0 ? null : 0;
        optionsCache.yAxis.max = max > 0 ? null : 0;
    }

    const sortedLegends = mapSort(legends);
    return [Array.from(sortedLegends.keys()), Array.from(sortedLegends.values()), specialEndMap];
}

const buildRequest = (
    nmi: string,
    meter: MeterReadMeter,
    tg: Exclude<PanelMode, 'decade' | 'time'>,
    range: RangeValue<moment.Moment>,
    isAcrossAccount: boolean) =>
    new MeterReadRequest(
        nmi,
        meter.serialNumber,
        range ? range[0] ? range[0]!.startOf(tg).format(ServiceDateFormatYMD) : null : null,
        range ? range[1] ? range[1]!.endOf(tg).format(ServiceDateFormatYMD) : null : null,
        meter.meterReadType === MeterType.SMART,
        isAcrossAccount);

const getMeterData = (
    accountServiceId: number,
    meterReadRequest: MeterReadRequest,
    requireEmptyResponse: boolean) => {

    if (requireEmptyResponse) {
        smartReadCache = cloneDeep(emptySmartReads);
        basicReadCache = cloneDeep(emptyBasicReads);

        return new Promise<void>((resolve) => resolve());
    }

    return meterReadRequest.isSmart
        ? new Promise<void>((resolve) => {
            getSmartMeterRead(accountServiceId, meterReadRequest)
                .then(res => res?.data
                    ? smartReadCache = cloneDeep(res.data)
                    : smartReadCache = cloneDeep(emptySmartReads))
                .catch(() => smartReadCache = cloneDeep(emptySmartReads))
                .finally(() => resolve());
        })
        : new Promise<void>((resolve) => {
            getBasicMeterRead(accountServiceId, meterReadRequest)
                .then(res => res?.data
                    ? basicReadCache = cloneDeep(res.data)
                    : basicReadCache = cloneDeep(emptyBasicReads))
                .catch(() => basicReadCache = cloneDeep(emptyBasicReads))
                .finally(() => resolve());
        });
}


const setChartData = (isSmart: boolean, tg: TimeGranularityEnum, displayInLocalTimeZone: boolean) => {
    const lineCount = window.innerWidth <= 320 ? 1 : window.innerWidth <= 640 ? 2 : 4;
    const top = window.innerWidth <= 640 ? 40 : 30;
    const overUnit = window.innerWidth <= 640 ? 20 : 0;
    const buildSingle = (list: Array<MeterReadBasicModel> | Array<MeterReadSmartModel>) => {
        let [legends, series, specialEndMap] = dataProcesser(list, isSmart, tg, displayInLocalTimeZone);
        let lines = Math.floor(legends.length / lineCount) + 1;
        optionsCache.legend = [{
            show: true,
            top: top,
            data: [...legends]
        } as Legend];
        optionsCache.grid.top = lines * 30 + top + overUnit;
        optionsCache.series = series;
        optionsCache.color = colorBuilder(series.length);
        setOptionsDateFormatter(tg, isSmart, specialEndMap);
    }

    const buildMultiple = (dic: Record<string, Array<MeterReadBasicModel>>) => {
        let totalLegends = new Array<Legend>();
        let totalSeriess = new Array<Series>();
        let lineWidth = 4;
        let size = 8;
        let opacity = 0.3;
        let ttop = top;
        for (let k in dic) {
            let [, series] = dataProcesser(dic[k], isSmart, tg, displayInLocalTimeZone);
            let newLegends = new Array<string>();
            // eslint-disable-next-line no-loop-func
            series.forEach(s => {
                let name = `${k}${qianhengxian(s.name)}`;
                let newSeries = {
                    name: name,
                    type: "line",
                    stack: null,
                    data: s.data,
                    lineStyle: {
                        width: lineWidth,
                        opacity: opacity
                    },
                    symbolSize: size
                } as Series;
                newLegends.push(name);
                totalSeriess.push(newSeries);
            });
            totalLegends.push({
                show: true,
                top: ttop,
                data: [...newLegends]
            } as Legend);
            let lines = Math.floor(newLegends.length / lineCount) + 1;
            ttop += lines * 30;
            lineWidth -= 2;
            size -= 2;
            opacity += 0.7;
        }
        let count = 0;
        totalLegends.forEach(x => count += x.data.length);
        if (count <= lineCount) {
            let t = totalLegends[0];
            let data = new Array<string>();
            totalLegends.forEach(x => data.push(...x.data));
            t.data = data;
            totalLegends = [t];
        }
        optionsCache.legend = totalLegends;
        optionsCache.grid.top = ttop + overUnit;
        optionsCache.series = totalSeriess;
        optionsCache.color = colorBuilder(totalSeriess.length);
        setOptionsDateFormatter(tg, isSmart);
    }

    if (isSmart) {
        optionsCache.xAxis.boundaryGap = ['0%', '0%'];
        buildSingle(smartReadCache);
    } else {
        optionsCache.xAxis.boundaryGap = ['2%', '2%'];
        buildMultiple(basicReadCache);
    }
}

const fixTwoHex = (n: number) => ("0" + n.toString(16)).slice(-2);
const colorBuilder = (num: number) => {
    let parts = 0;
    for (let i = 2; ; i++) {
        let c = Math.pow(i, 3);
        if (c - i > num) {
            parts = i + 1;
            break;
        }
    }
    let count = Math.floor(255 / parts);
    let arr = [];
    for (let i = 1; ; i++) {
        let x = i * count;
        if (x > 255) {
            break;
        } else {
            arr.push(count * i)
        }
    }
    let rgb = {
        r: [...arr],
        g: [...arr],
        b: [...arr]
    }
    let color = [];
    let len = arr.length;
    for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
            for (let k = 0; k < len; k++) {
                if (k !== i && k !== j && j !== i) {
                    color.push("#" + fixTwoHex(rgb.r[i]) + fixTwoHex(rgb.g[j]) + fixTwoHex(rgb.b[k]));
                }
            }
        }
    }

    let clen = color.length;
    let skipCount = clen / num;
    let colorout = [];
    let skiped = 0;
    let skipindex = Math.floor(skiped * skipCount);
    while (skipindex < clen) {
        colorout.push(color[skipindex]);
        skiped++;
        skipindex = Math.floor(skiped * skipCount);
    }

    return colorout;
}

// const bindState = (stateMap: Map<string, [any, Function]>) => {
//     var obj = Object.create(null);
//     for (let key of Array.from(stateMap.keys())) {
//         let x = stateMap.get(key);
//         let copy = "__" + key;
//         Object.defineProperty(obj, copy, {
//             enumerable: false,
//             configurable: false,
//             writable: true,
//             value: x![0]
//         });
//         Object.defineProperty(obj, key, {
//             enumerable: true,
//             configurable: false,
//             // eslint-disable-next-line no-loop-func
//             get() { return obj[copy]; },
//             // eslint-disable-next-line no-loop-func
//             set(v) { obj[copy] = v; x![1](v); },
//         });
//     }
//     return obj;
// }
const DownLoadIcon = <FontAwesomeIcon
    icon={faDownload}
    style={{ margin: "0 3px", color: "#e71d73" }} />;

const DotLine = (props: {
    color: string,
    label: string
}) =>
    <div style={{ display: "inline-block", height: "16px", lineHeight: "16px", fontSize: "12px", verticalAlign: "top" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <div className="flex-solid" style={{ borderBottom: `2px solid ${props.color}`, width: "20px", height: "0px" }}></div>
            <div className="flex-solid" style={{ border: `2px solid ${props.color}`, width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#fff", marginLeft: "-15px" }}></div>
            <div style={{ marginLeft: "10px", color: "#e71d73" }}>{props.label}:</div>
        </div>
    </div>;


const UsageChart = () => {
    const currentAccount = useSelector(selectCurrentAccount);
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [currentAccountService, setCurrentAccountService] =
        useState<AccountServiceInfo | null>(null);
    const [meterList, setMeterList] = useState<MeterReadMeter[]>();
    const [currentMeterId, setCurrentMeterId] = useState<string>();
    const [currentTimeGranularity, setCurrentTimeGranularity] = useState(TimeGranularity.BASIC);
    const [timeGranularityIndex, setTimeGranularityIndex] = useState(0);
    const [currentDateRange, setCurrentDateRange] = useState(emptyRange)
    const [options, setOptions] = useState(cloneDeep(DefaultOptions));
    const [showActualTip, setShowActualTip] = useState(false);
    const [currentMeterType, setCurrentMeterType] = useState("");
    const [meterReadType, setMeterReadTypes] = useState<Record<string,string>>();
    const [disableDateArr, setDisableDateArr] = useState({ frmpdate: null as Moment | null, closedate: null as Moment | null });
    const [displayInLocalTimeZone, setDisplayInLocalTimeZone] = useState(true);

    const disableAll = (disableDateArr.closedate as any) < (disableDateArr.frmpdate as any);

    const navigate = useNavigate();

    const meterLabelText = (meter: MeterReadMeter) => {
        const currentMeterReadType = meterReadType ? (meterReadType[meter.serialNumber] ?? meter.meterReadType) : meter.meterReadType;
        const meterStatus = meter.serialStatus ? ` (${meter.serialStatus})` : '';
        return `${currentMeterReadType.toUpperCase()} METER - ${meter.serialNumber}${meterStatus}`;   
    }

    if (window.innerWidth <= 640) {
        optionsCache.xAxis.axisLabel.rotate = -90;
        optionsCache.tooltip.position = ['0%', '100%'];
    } else {
        optionsCache.xAxis.axisLabel.rotate = 0;
        optionsCache.tooltip.position = null;
    }

    const buildChart = (isSmart: boolean, tg: TimeGranularityEnum) => {
        setChartData(isSmart, tg, displayInLocalTimeZone);
        if (basicReadCache[BasicMeterReadDataType.ActualReads].length > 0) {
            setShowActualTip(true);
        } else {
            setShowActualTip(false);
        }
        setOptions(cloneDeep(optionsCache));
    }

    const downLoadCsvFileLocal = () => {

        let meter = meterList?.find(m => m.meterId === currentMeterId);
        let site = siteList.find(s => s.accountServiceInfo === currentAccountService);
        let pt = getPickerType(currentTimeGranularity[timeGranularityIndex]);

        if (!meter || !site) {
            return;
        }

        downLoadCsvFile(
            site.accountServiceInfo.accountServiceId,
            buildRequest(
                site.nmi,
                meter, pt,
                currentDateRange,
                checkIsCrossAccountService(currentAccountService!)));
    }

    const siteSelected = (accountService: AccountServiceInfo | null) => {

        setCurrentAccountService(accountService);
    }

    const serialSelected = (meterId: string | undefined) => {
        setCurrentMeterId(meterId);
    }

    const rangeSelected = (range: RangeValue<moment.Moment>) => {
        if (!range) {
            return;
        }

        hasUserSelectedDate = true;
        setCurrentDateRange([
            range[0] ? toUtcStartOfDay(range[0]) : null,
            range[1] ? toUtcStartOfDay(range[1]) : null]);
    }

    const timeGranularitySelected = (index: number) => setTimeGranularityIndex(index);

    const legendselectchanged = () => { };
    const datazoom = () => dateCache = null;

    const getSiteTpye = () => {
        let site = siteList.find(s => s.accountServiceInfo === currentAccountService);

        if (!site) {
            return '';
        }

        return site.nmi.startsWith("5")
            ? "Gas"
            : "Electricity";
    }

    const setDatePicker = (
        daterange: RangeValue<moment.Moment> | null,
        frmprange: RangeValue<moment.Moment>) => {

        setCurrentDateRange(daterange);
        setDisableDateArr({ frmpdate: frmprange![0], closedate: frmprange![1] });
    }

    useEffect(() => {
        optionsCache = cloneDeep(DefaultOptions);
        hasUserSelectedDate = false;
        accountServiceFrmpDateMap.map = null
        accountServiceFrmpDateMap.callback = null
    }, [])

    useEffect(() => {

        if (!currentAccount?.services?.length) {
            return;
        }

        accountServiceFrmpDateMap.map = null;

        const sortSiteList = (siteList: Site[]) => {
            return siteList.sort((site1, site2) => {
                // reverse(): to make sure the service with status not in the list is at the bottom
                const allServiceStatus = Object.keys(AccountServiceStatusForDisplay).reverse();
                const index1 = allServiceStatus.indexOf(site1.accountServiceInfo.status);
                const index2 = allServiceStatus.indexOf(site2.accountServiceInfo.status);

                return index2 - index1;
            })
        }

        const siteList = toSite(currentAccount.services)
        const sortedSiteList = sortSiteList(siteList)
        setSiteList(sortedSiteList);

        const action = async () => {

            const missingSIds = currentAccount.services
                .map(acs => acs.allAccountServiceIds)
                .flat()
                .filter(id => !accountServiceFrmpDateMap.map?.[id]);

            if (!missingSIds.length) {
                accountServiceFrmpDateMap.callback?.(accountServiceFrmpDateMap.map);

                return;
            }

            const res = await getAccountServiceStatusDateAsync(
                currentAccount.services.map(acs => acs.allAccountServiceIds).flat());

            if (!res?.data) {
                return;
            }

            accountServiceFrmpDateMap.map = { ...accountServiceFrmpDateMap.map, ...res.data };

            accountServiceFrmpDateMap.callback?.(accountServiceFrmpDateMap.map);
        }

        action();

    }, [currentAccount]);

    useEffect(() => {
        let activeSites: Site[] = [];

        if (siteList.length) {
            activeSites = siteList.filter(s => activeServiceStatus.includes(s.accountServiceInfo.status as EuropaAccountServiceStatus));
        };

        activeSites.length === 1 ? siteSelected(activeSites[0].accountServiceInfo) : siteSelected(null);
    }, [siteList])

    useEffect(() => {
        let site = siteList.find(
            s => s.accountServiceInfo === currentAccountService);

        accountServiceFrmpDateMap.callback = null;

        if (site && currentAccountService) {

            let nmi = site.nmi;
            if (nmi) {
                nmi.startsWith("5")
                    ? optionsCache.yAxis.name = "{i|M}{a| 3}"
                    : optionsCache.yAxis.name = "kWh";
            } else {
                optionsCache.yAxis.name = "";
            }

            requestCache.meterSeted = false;

            const getSortedMeterList = (meterList: MeterReadMeter[]) => {
                // show Turnedon and Energized first
                return meterList.sort((meter1, meter2) => {
                    return activeMeterStatus.indexOf(meter2.serialStatus as ActiveMeterStatus)
                        - activeMeterStatus.indexOf(meter1.serialStatus as ActiveMeterStatus);
                })
            }

            if (site.accountServiceInfo.serviceType === ServiceType.Power) {
                getPowerMeterTypes(site.nmi)
                    .then(res => {
                        if (res?.data) {
                            setMeterReadTypes(res.data);
                        }
                    });
            } else {
                setMeterReadTypes(undefined);
            }

            getMeterListFromRead(currentAccountService.accountServiceId)
                .then(res => {
                    if (res?.data?.length) {
                        setMeterList(getSortedMeterList(res.data))
                    } else {
                        setMeterList(undefined);
                    }
                });
            // .catch(e => console.log(e));
        } else {
            setMeterList(undefined);
        }

        let map = accountServiceFrmpDateMap.map;

        let setFrmpRange = (map: Record<number, AccountServiceStatusDateDto>) => {

            requestCache.rangeSeted = false;

            const allFrmpAndCloseDate = Object.keys(map)
                .filter(k => currentAccountService?.allAccountServiceIds
                    .some(aId => aId === parseInt(k)))
                .map((k) => ({
                    frmpDate: map[parseInt(k)].frmpDate,
                    accountClosedDate: map[parseInt(k)].accountClosedDate
                }));

            // console.log(allFrmpAndCloseDate)

            let frmp = (allFrmpAndCloseDate
                .sort((a, b) => ((a.frmpDate as Moment | null)?.unix() ?? Number.MAX_SAFE_INTEGER) -
                    ((b.frmpDate as Moment | null)?.unix() ?? Number.MAX_SAFE_INTEGER))
            [0]?.frmpDate ?? null) as Moment | null; // min frmp date or null

            let end = (allFrmpAndCloseDate
                .sort((a, b) => ((b.accountClosedDate as Moment | null)?.unix() ?? Number.MAX_SAFE_INTEGER) -
                    ((a.accountClosedDate as Moment | null)?.unix() ?? Number.MAX_SAFE_INTEGER))
            [0]?.accountClosedDate ?? EndOfTodayMoment) as Moment; // max close date or today

            // console.log(frmp,end)

            if (!frmp) {
                setDatePicker(null, [today(), today().add(-10, 'day')]); // disable all dates if frmp is null

                return;
            }

            let daterange = null as RangeValue<moment.Moment> | null;

            const setRangeWithDefault = () => {

                let fourteenMonthBeforeEnd = moment(end).add(-14, "months");

                let start = frmp!.isBefore(fourteenMonthBeforeEnd)
                    ? fourteenMonthBeforeEnd
                    : frmp;

                daterange = [start, end];
            }

            if (!hasUserSelectedDate) {

                setRangeWithDefault();

            } else if (currentDateRange) {

                if (frmp.isAfter(currentDateRange[1]) || end.isBefore(currentDateRange[0])) {

                    setRangeWithDefault();

                } else {

                    let tomoment = end.isAfter(currentDateRange[1])
                        ? currentDateRange[1]?.isBefore(frmp!)
                            ? end
                            : currentDateRange[1]
                        : end;
                    let startmoment = frmp.isBefore(currentDateRange[0])
                        ? currentDateRange[0]?.isAfter(tomoment)
                            ? frmp
                            : currentDateRange[0]
                        : frmp;
                    daterange = [startmoment, tomoment];

                }
            } else {

                setRangeWithDefault();

            }

            setDatePicker(daterange, [frmp, end]);
        }

        if (map) {
            setFrmpRange(map);
        } else {
            accountServiceFrmpDateMap.callback = setFrmpRange;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccountService])

    useEffect(() => {
        if (!meterList) {
            serialSelected(undefined);
            return;
        }

        if (meterList[0].meterId === currentMeterId) {
            requestCache.meterSeted = true;
            doRequest();
        } else {
            let activeMeters: MeterReadMeter[] = meterList.filter(meter => activeMeterStatus.includes(meter.serialStatus as ActiveMeterStatus));
            activeMeters.length === 1 ? serialSelected(activeMeters[0].meterId) : serialSelected(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meterList])

    const doRequest = () => {
        let meter = meterList?.find(m => m.meterId === currentMeterId);
        let site = siteList.find(s => s.accountServiceInfo === currentAccountService);

        if (!meter || !site) {
            setCurrentTimeGranularity(TimeGranularity.BASIC);

            return;
        }

        let isSmart = meter.meterReadType === MeterType.SMART;
        let curTimeGranularity = isSmart ? TimeGranularity.SMART : TimeGranularity.BASIC;
        let tg = currentTimeGranularity[timeGranularityIndex] as TimeGranularityEnum;
        if (!requestCache.meterSeted || !requestCache.rangeSeted) {
            basicReadCache = emptyBasicReads;
            smartReadCache = emptySmartReads;
            buildChart(isSmart, tg);
            return;
        }
        let pt = getPickerType(tg);
        if (currentTimeGranularity !== curTimeGranularity) {
            setCurrentTimeGranularity(curTimeGranularity);
            pt = TimeGranularityEnum.date;
            tg = TimeGranularityEnum.Day;
        }
        let center = window.innerWidth > 640 ? " - " : "\n";
        optionsCache.title.text = `${site.nmi}${center}${meterLabelText(meter)}`;

        isSmart
            ? setCurrentMeterType(MeterType.SMART)
            : setCurrentMeterType(MeterType.BASIC);

        getMeterData(
            site.accountServiceInfo.accountServiceId,
            buildRequest(
                site.nmi,
                meter,
                pt,
                currentDateRange,
                checkIsCrossAccountService(currentAccountService!)),
            disableAll)
            .then(() => buildChart(isSmart, tg));
    }

    useEffect(() => {
        requestCache.meterSeted = true;
        doRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMeterId])

    useEffect(() => {
        requestCache.rangeSeted = true;
        doRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDateRange, disableDateArr])

    useEffect(() => {
        let meter = meterList?.find(m => m.meterId === currentMeterId);
        if (meter) {
            setTimeGranularityIndex(meter.meterReadType === MeterType.SMART ? 2 : 0);
        } else {
            setTimeGranularityIndex(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTimeGranularity])

    useEffect(() => {
        let meter = meterList?.find(m => m.meterId === currentMeterId);
        if (meter) {
            buildChart(meter.meterReadType === MeterType.SMART, currentTimeGranularity[timeGranularityIndex] as TimeGranularityEnum);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeGranularityIndex, displayInLocalTimeZone])

    return (
        <div className={styles["usage-chart-block"]}>
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="usageChartMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions[0]}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>
            <div className={styles["usagechart-item"]}>
                <p>Select Your Gas/Electricity Service</p>
                <SiteSelector
                    onChange={s => s && siteSelected(s.accountServiceInfo)}
                    sites={siteList}
                    currentSite={siteList?.find(s => s.accountServiceInfo === currentAccountService)}
                    className={styles["usagechart-item-select"]}
                />
                {/* <Select
                    className={styles["usagechart-item-select"]}
                    onChange={siteSelected}
                    value={currentAccountServiceId}>
                    {
                        siteList.map(site => (
                            <Option key={site.accountServiceId} value={site.accountServiceId}><i style={{ padding: "0 5px" }} className={`fa fa-${site.nmi.startsWith("5") ? "fire" : "bolt"}`}></i>{`${site.nmi.startsWith("5") ? "Gas" : "Pow"} - ${site.nmi} - ${site.address}`}</Option>
                        ))
                    }
                </Select> */}
            </div>
            {
                currentAccountService ?
                    <div className={styles["usagechart-item"]}>
                        <p>Select Your {getSiteTpye()} Meter</p>
                        <Select className={styles["usagechart-item-select"]} onChange={v => serialSelected(v)} value={currentMeterId}>
                            {
                                meterList?.map(meter => (
                                    <Option key={meter.meterId} value={meter.meterId}>{meterLabelText(meter)}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    : null
            }
            {
                currentMeterId &&
                <>
                    <div className={styles["usagechart-item"]}>
                        <p>Select Usage Period</p>
                        <div
                            className='flex-left-center'>
                            <Select className={styles["usagechart-item-select"]} onChange={v => timeGranularitySelected(v)} value={timeGranularityIndex}>
                                {
                                    currentTimeGranularity.map((tg, index) => (
                                        <Option key={index} value={index}>{tg}</Option>
                                    ))
                                }
                            </Select>
                            <RangePicker
                                picker={getPickerType(currentTimeGranularity[timeGranularityIndex])}
                                value={currentDateRange}
                                onChange={rangeSelected}
                                disabledDate={date => shouldBeDisabledByDayDateRange(
                                    date,
                                    disableDateArr.frmpdate,
                                    disableDateArr.closedate)}
                                format={getPickerFormat(currentTimeGranularity[timeGranularityIndex])} />
                            {
                                currentTimeGranularity[timeGranularityIndex] === TimeGranularityEnum.Hour
                                    || currentTimeGranularity[timeGranularityIndex] === TimeGranularityEnum.HalfHour
                                    ? <Checkbox
                                        checked={displayInLocalTimeZone}
                                        onChange={event => setDisplayInLocalTimeZone(event.target.checked)}
                                        style={{ marginLeft: "30px" }}>
                                        Display In My Local Time Zone
                                    </Checkbox>
                                    : <></>
                            }
                        </div>
                    </div>
                    <p style={{ width: "100%", textAlign: "right" }}>
                        <FontAwesomeIcon
                            icon={faDownload}
                            title="download meter read summary"
                            onClick={disableAll ? undefined : downLoadCsvFileLocal}
                            style={{
                                cursor: disableAll ? 'not-allowed' : 'pointer',
                                marginRight: '30px',
                                color: disableAll ? 'gray' : '#e71d73'
                            }}
                        />
                    </p>
                    <ReactECharts lazyUpdate={false} notMerge={true} className={styles["usagechart-chart"]} option={options} onEvents={{ "legendselectchanged": legendselectchanged, "datazoom": datazoom }} />
                    {
                        currentMeterType === MeterType.BASIC
                            ? <div style={{ fontSize: "12px", lineHeight: "16px" }}>
                                <p style={{ margin: "10px 0" }}>
                                    <DotLine color={optionsCache.color.length <= 2 && showActualTip ? optionsCache.color[0] : "#000"} label="Invoice Read Data" /> Invoice Read Data shows the meter reads used to calculate your bills. We prefer to bill you based on actual reads. On bills where an actual meter read is not available GloBird may bill you using an estimate, and then adjust your next bill accordingly when an actual read is received.
                                    {
                                        showActualTip && getSiteTpye() === "Gas"
                                            ? <span> To learn more about gas estimates click <a target="_blank" href={links.corporateWebsite.estimation} rel="noreferrer">here</a></span>
                                            : <></>
                                    }
                                </p>
                                {
                                    showActualTip
                                        ? <p style={{ margin: "10px 0" }}><DotLine color={optionsCache.color.length === 2 ? optionsCache.color[1] : "#000"} label="Distributor Read/Self Read Data" /> Distributor Read Data shows all the meter reads from distributor, including actual and estimated meter reads. Self Read Data shows the reads you provided to us.</p>
                                        : <></>
                                }
                                <p style={{ margin: "10px 0" }}>{DownLoadIcon}: Click {DownLoadIcon} button to view meter read summary. It includes: read date, read type (actual/estimate) and read index.</p>
                            </div>
                            : <></>
                    }
                    <p style={{ textAlign: 'justify' }}>
                        Depending on your meter configuration, you could be paying a different price for power depending on when you use it. See what time of the day your meter is on Peak, Shoulder or Off-Peak. You might save money by using power in the Off-Peak times. Please note all times shown here are Australian Eastern Standard Time (not local time). So depending on your state or territory the times listed may be different during daylight savings because they are not adjusted to daylight saving times.<br />
                        More information <a href={links.corporateWebsite.tariffTimetables}>here</a>
                        {
                            currentTimeGranularity[timeGranularityIndex] === TimeGranularityEnum.Hour
                            || currentTimeGranularity[timeGranularityIndex] === TimeGranularityEnum.HalfHour
                            ? <>
                                <br />
                                *With smart and manually read interval meters, the hour or half-hour data is displayed in Australian Eastern Standard Time. Please note there is an option to display in local time zone if you prefer.
                              </>
                            : <></>
                        }
                    </p>
                </>
            }
        </div>
    );
}

export default UsageChart;
