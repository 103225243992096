import { useEffect, useState } from 'react';
import {
    toSelfReadUploadList,
    formatDisplayDate,
    getServiceIcon,
    DataCache
} from 'utilities/Utility';
import {
    Form,
    Input,
    Divider,
    Tooltip,
    Table,
    TablePaginationConfig,
    Tag,
    TableProps,
    notification
} from 'antd';
import {
    getSelfReadsAsync,
    getSelfReadCountAsync,
    cancelSelfReadAsync
} from 'services/RequestDataService';
import {
    SelfReadReasonDisplay,
    Account,
    ListSelfReadUpload,
    ListSelfMeterRead,
    SelfReadReviewReasonDesc,
    SelfReadUploadReviewReasonDesc,
    SelfReadReviewReason,
    SelfReadUploadReview,
    SelfReadApprovalStatus
} from 'data/CustomerPortalTypes';
import ClockCircleOutlined from '@ant-design/icons/lib/icons/ClockCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import ButtonWithToolTip from 'components/Shared/ButtonWithToolTip';
import { refreshAccountPendingReadAsync } from 'components/Areas/Usage/SelfRead/NewSelfReadForm';
import styles from './SelfReadList.module.css';

const ListPageSize = 20;

const { Item: FormItem } = Form;

const { TextArea } = Input;

let dataCache: { [key: string]: DataCache<SelfReadUploadReview[]> } = {};
let countCache: { [key: number]: DataCache<number> } = {};

const resetCache = () => {
    dataCache = {};
    countCache = {};

    return true;
};

const SelfReadList = ({ currentAccount, forceRefresh, onListRefreshed }: { currentAccount: Account | null, forceRefresh?: boolean, onListRefreshed?: () => any }) => {

    const [data, setData] = useState<ListSelfReadUpload[]>([]);

    const [pagination, setPagination] = useState<TablePaginationConfig>(
        {
            current: 1,
            pageSize: ListPageSize,
            showSizeChanger: false,
            total: 0
        });

    const [loading, setLoading] = useState<boolean>(false);

    const actionRenderer = (_: any, row: ListSelfReadUpload) => {
        return <ButtonWithToolTip
            disabled={row.approvalStatus !== SelfReadApprovalStatus.PendingApproval}
            disableMessage={row.approvalStatus === SelfReadApprovalStatus.Cancelled
                ? 'This self read has already been cancelled.'
                : 'This self read has already been reviewed.'}
            useAntdBtn={true}
            type="primary"
            btnStyle={{ fontSize: '0.8rem' }}
            btnNode="Cancel"
            onClick={async () => {
                const result = await cancelSelfReadAsync(row.accountServiceId);

                if (result?.message && !result?.data) {
                    notification.error({
                        message: 'Error',
                        description: result.message,
                        duration: 10
                    }); // when such result is returned, it implies that the read has already been cancelled
                }

                if (!result?.success) {
                    return;
                }

                if (result?.data) {
                    notification.success({
                        message: 'Success',
                        description: 'Successfully cancelled the self read.',
                        duration: 10
                    });
                }

                resetCache();
                currentAccount && refreshAccountPendingReadAsync(currentAccount.accountId);
                initialize();
            }}

            size="small" />
    };

    const columns = [
        {
            title: 'Service',
            dataIndex: 'nmi',
            render: (val: string) => {
                const site = currentAccount
                    ?.services
                    ?.filter(s => s.siteIdentifier === val)
                    ?.[0];

                // return <Tooltip title={`${val} - ${site?.siteAddress}`}>
                //     {getServiceIcon(val)} {val} - {site?.siteAddress}
                // </Tooltip>;
                return <>{getServiceIcon(val)} {val} - {site?.siteAddress}</>;
            },
            ellipsis: true,
        },
        {
            title: 'Upload Time',
            dataIndex: 'uploadDateTime'
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            render: statusRenderer,
            className: styles['approval-status']
        },
        {
            title: 'Action',
            render: actionRenderer
        }
    ];

    const getPageData = async (offset: number, limit: number) => {
        if (!currentAccount) {
            return;
        }

        setLoading(true);

        try {
            const data = (dataCache[`${currentAccount.accountId}|${offset}|${limit}`] ??=
                await DataCache.Create<SelfReadUploadReview[]>(
                    async () => (await getSelfReadsAsync(
                        currentAccount.accountId,
                        offset,
                        limit))?.data,
                    300000))
                ?.data;

            if (!data) {
                return;
            }

            setData(toSelfReadUploadList(data));
        }
        finally {
            setLoading(false);
        }
    };

    const onTableChange: TableProps<ListSelfReadUpload>['onChange'] = (p) => {

        setPagination(p);

        getPageData(
            (p.current! - 1) * p.pageSize!,
            p.pageSize!);
    }

    const initialize = async () => {

        if (!currentAccount) {
            return;
        }

        setLoading(true);

        let count = 0;

        try {
            const data = (countCache[currentAccount.accountId] ??= await DataCache.Create<number>(
                async () => (await getSelfReadCountAsync(
                    currentAccount.accountId))?.data,
                300000)).data;

            if (data === null || data === undefined) {
                return;
            }

            count = data

            setPagination({
                ...pagination,
                total: data
            });
        }
        finally {
            setLoading(false);
        }

        count
            ? getPageData(0, ListPageSize)
            : setData([]);
    };

    useEffect(() => {
        if (!currentAccount) {
            return;
        }

        if (forceRefresh) {
            resetCache();
            onListRefreshed?.();
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount]);

    return <div style={{ padding: '0 5px' }}>
        <Table
            columns={columns}
            rowKey={r => r.selfReadUploadId}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={onTableChange}
            expandable={{ expandedRowRender: meterReadRowRenderer }}
            scroll={{ x: 'max-content' }}
        />
    </div>;
}

const statusRenderer = (val: SelfReadApprovalStatus) => {
    if (val === SelfReadApprovalStatus.PendingApproval) {
        return <Tag icon={<ClockCircleOutlined />} color="warning">
            Pending
        </Tag>;
    }

    if (val === SelfReadApprovalStatus.Approved) {
        return <Tag icon={<CheckCircleOutlined />} color="success">
            Approved
        </Tag>;
    }

    if (val === SelfReadApprovalStatus.Rejected) {
        return <Tag icon={<CloseCircleOutlined />} color="error">
            Rejected
        </Tag>
    }

    if (val === SelfReadApprovalStatus.Cancelled) {
        return <Tag icon={<MinusCircleOutlined />} color="#f5f5f5" style={{ color: "#bfbfbf" }}>
            Cancelled
        </Tag>
    }
};

const meterReadRowRenderer = (u: ListSelfReadUpload) => {
    const singleRegReads = u.reads.filter(r => !r.isMultiRegister);
    const multiRegReads = u.reads.filter(r => r.isMultiRegister);

    const showRegisterType = singleRegReads.length > 0
        && multiRegReads.length > 0;

    return <>
        <Divider
            orientation="left"
            style={{ borderTopColor: '#bdbdbd' }}>
            Upload Info
        </Divider>
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}>
            <FormItem
                label="Upload Reason">
                <Input
                    readOnly
                    value={SelfReadReasonDisplay[u.readReason]} />
            </FormItem>
            <FormItem
                label="Read Date">
                <Input
                    readOnly
                    value={formatDisplayDate(u.readDate)} />
            </FormItem>
            {/* <FormItem
                label="Read Note"
                wrapperCol={{ span: 12 }}>
                <TextArea
                    readOnly
                    style={{
                        minHeight: u.readNote
                            ? '200px'
                            : undefined
                    }}
                    value={u.readNote ?? ''} />
            </FormItem> */}
        </Form>
        <Divider
            orientation="left"
            style={{ borderTopColor: '#bdbdbd' }}>
            Meter Reads
        </Divider>
        {singleRegReads.length > 0 && <Table
            style={{
                border: '1px solid #ededed',
                borderRadius: '5px'
            }}
            title={showRegisterType
                ? () => 'Single-Register Meters'
                : undefined}
            columns={singleRegCols}
            rowKey={r => r.selfReadId}
            size="middle"
            dataSource={singleRegReads}
            pagination={false} />}
        {multiRegReads.length > 0 && <Table
            style={{
                border: '1px solid #ededed',
                borderRadius: '5px'
            }}
            title={showRegisterType
                ? () => 'Multi-Register Meters'
                : undefined}
            columns={multiRegCols}
            rowKey={r => r.selfReadId}
            size="middle"
            expandable={{ expandedRowRender: registerReadRowRenderer }}
            dataSource={multiRegReads}
            pagination={false} />}
        <Divider
            orientation="left"
            style={{ borderTopColor: '#bdbdbd' }}>
            Review Result
        </Divider>
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}>
            <FormItem label="Review Status">
                {statusRenderer(u.approvalStatus)}
            </FormItem>
            <FormItem label="Status Reason" wrapperCol={{ span: 14 }}>
                {u.approvalStatus === SelfReadApprovalStatus.PendingApproval
                    ? <Tooltip title="The read upload is still pending review, please wait till the review is completed.">
                        <span>
                            <Input value={''} readOnly disabled />
                        </span>
                    </Tooltip>
                    : <TextArea
                        value={u.approvalStatus === SelfReadApprovalStatus.Cancelled
                            ? 'The read upload has been cancelled by user.'
                            : SelfReadUploadReviewReasonDesc[u.reviewReason!]}
                        readOnly
                        autoSize={true}
                    />
                }
            </FormItem>
            <FormItem
                label="Feedback"
                wrapperCol={{ span: 14 }}>
                {u.approvalStatus === SelfReadApprovalStatus.PendingApproval
                    ? <Tooltip title="The read upload is still pending review, please wait till the review is completed.">
                        <span><TextArea value={''} readOnly disabled /></span>
                    </Tooltip>
                    : <TextArea
                        value={u.reviewNote ?? ''}
                        readOnly
                        style={{
                            minHeight: u.reviewNote
                                ? '200px'
                                : undefined
                        }} />}
            </FormItem>
        </Form>
    </>;
};

const registerReadRowRenderer = (r: ListSelfMeterRead) => {
    const regCols = [
        {
            title: 'Register Id',
            children: [
                {
                    title: 'Uploaded',
                    dataIndex: 'registerId'
                },
                {
                    title: 'Accepted',
                    dataIndex: 'accpetedRegisterId'
                }
            ]
        },
        {
            title: 'Register Index',
            children: [
                {
                    title: 'Uploaded',
                    dataIndex: 'registerIndex'
                },
                {
                    title: 'Accepted',
                    dataIndex: 'accpetedRegisterIndex'
                }
            ]
        },
        {
            title: <span>
                Status <Tooltip title="This status represents status of each uploaded read, it may not be the same as the overall status."><QuestionCircleOutlined /></Tooltip>
            </span>,
            dataIndex: 'approvalStatus',
            render: (val: any) => val !== null
                ? statusRenderer(val)
                : <></>,
            className: styles['approval-status']
        },
        {
            title: 'Reason',
            dataIndex: 'reviewReason',
            render: (val: any) => val !== null
                ? SelfReadReviewReasonDesc[val as SelfReadReviewReason]
                : <></>
        }
    ];

    return <Table
        columns={regCols}
        rowKey={r => r.selfReadId}
        size="small"
        dataSource={r.registerReads!}
        pagination={false} />;
};

const singleRegCols = [
    {
        title: 'Meter Serial',
        children: [
            {
                title: 'Uploaded',
                dataIndex: 'meterSerial'
            },
            {
                title: 'Accepted',
                dataIndex: 'accpetedMeterSerial'
            }
        ]
    },
    {
        title: 'Meter Index',
        children: [
            {
                title: 'Uploaded',
                dataIndex: 'readIndex'
            },
            {
                title: 'Accepted',
                dataIndex: 'accpetedReadIndex'
            }
        ]
    },
    {
        title: <span>
            Status <Tooltip title="This status represents status of each uploaded read, it may not be the same as the overall status."><QuestionCircleOutlined /></Tooltip>
        </span>,
        dataIndex: 'approvalStatus',
        render: (val: any) => val !== null
            ? statusRenderer(val)
            : <></>,
        className: styles['approval-status']
    },
    {
        title: 'Reason',
        dataIndex: 'reviewReason',
        render: (val: any) => val
            ? SelfReadReviewReasonDesc[val as SelfReadReviewReason]
            : ''
    }
];

const multiRegCols = [
    {
        title: 'Meter Serial',
        children: [
            {
                title: 'Uploaded',
                dataIndex: 'meterSerial'
            },
            {
                title: 'Accepted',
                dataIndex: 'accpetedMeterSerial'
            }
        ]
    }
];

export default SelfReadList;