import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from 'store/store';
import appInsights from 'appInsights';

// eslint-disable-next-line no-extend-native
!Promise.prototype.finally && (Promise.prototype.finally = function (callback: any) {
  return this.then(
    value => (this.constructor as any).resolve(callback()).then(() => value),
    reason => (this.constructor as any).resolve(callback()).then(() => { throw reason })
  )
});

// eslint-disable-next-line
String.prototype.replaceAll ??= function (this: any, search: any, replacement: any) {
  return this.replace(new RegExp(search, 'g'), replacement);
};

appInsights.trackPageView();

// (window as any).FontAwesomeConfig.autoReplaceSvg = 'nest';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
