import { Menu } from "antd";
import SectionMenu from "../SectionMenu";
import AddJointOwnerForm from "./AddJointOwnerForm";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UpdateJointOwnerForm from "./UpdateJointOwnerForm";

const JointOwner = () => {
    const navigate = useNavigate();
    let { pathname } = useLocation();
    
    pathname = pathname.endsWith('/')
    ? pathname.substring(0, pathname.length - 1)
    : pathname;
    
    const currentMenu = pathname.toLowerCase() === `/jointowner/update`
    ? 'update'
    : 'add';
    
    const [menu, setMenu] = useState<'add' | 'update'>(currentMenu);

    useEffect(() => {
        setMenu(currentMenu);
    }, [currentMenu]);
    
    const onMenuChange = ({ key }: { key: string }) => {

        if (key === 'add') {
            navigate(`/jointowner`);
        }
    
        if (key === 'update') {
            navigate(`/jointowner/update`);
        }
    };

    return (
        <div className="form-container" style={{ maxWidth: '700px' }}>
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <SectionMenu
                    id="jointOwnerMenuSelector"
                    defaultIndex={5} />
            </div>
            <Menu
                style={{ backgroundColor: 'rgba(249,249,249,1)' }}
                onClick={onMenuChange}
                selectedKeys={[menu]}
                mode="horizontal">
                <Menu.Item key="add">
                    Add Joint Owner
                </Menu.Item>
                <Menu.Item key="update">
                    Update Joint Owner
                </Menu.Item>
            </Menu>
            <Routes>
                <Route path="/" element={<AddJointOwnerForm />} />
                <Route path="update" element={<UpdateJointOwnerForm />} />
            </Routes>
        </div>
    );
}

export default JointOwner;