import GlobirdSelector from 'components/Shared/GlobirdSelector';
import GlobirdTable from 'components/Shared/GlobirdTable';
import { GlobirdTableContainer } from 'data/GlobirdTableContainer';
import { useNavigate } from 'react-router-dom';
import menuOptions from '../MenuOptions';
import './SelfServeForm.css'
import pdfIcon from 'assets/file-pdf.svg';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import links from 'data/Links';

const formList = [
    {
        formName: "Abolishment (Permanent Removal) of Electricity Supply",
        state: "VIC",
        description: "For removal of a electricity meter in Victoria",
        link: links.forms.VIC.electricityAbolishmentForm
    },
    {
        formName: "Application for service – Electrical Works Request (EWR)",
        state: "VIC",
        description: "Covers new and upgrades of electricity connections",
        link: links.forms.VIC.ewrForm
    },
    {
        formName: "Gas application",
        state: "ALL",
        description: "Covers new, abolished and upgraded gas connections",
        link: links.forms.ALL.gasApplicationForm
    },
    {
        formName: "Direct Debit",
        state: "ALL",
        description: "Setup Direct Debit payments from your nominated account",
        link: links.forms.ALL.directDebitForm
    },
    {
        formName: "Medical Confirmation Form (Life Support)",
        state: "VIC",
        description: "VIC Life Support Concession form and info",
        link: links.forms.VIC.lifeSupportForm
    },
    {
        formName: "Life Support Concession and Machine Notification Application",
        state: "VIC",
        description: "Assists VIC households with life support concession",
        link: links.forms.VIC.lifeSupportConcessionForm
    },
    {
        formName: "Medical Cooling Concession Application",
        state: "VIC",
        description: "Assists VIC households with costs for medical cooling",
        link: links.forms.VIC.medicalCoolingConcessionForm
    },
    {
        formName: "Life Support Rebate Application",
        state: "NSW",
        description: "NSW rebate for operating approved life support equipment",
        link: links.forms.NSW.lifeSupportRebateForm
    },
    {
        formName: "Medical Energy Rebate Application",
        state: "NSW",
        description: "NSW rebate for customers who have an inability to self-regulate body temperature",
        link: links.forms.NSW.medicalEnergyRebateForm
    },
    {
        formName: "SA Power – Notification of installation/removal of Life Support Equipment",
        state: "SA",
        description: "Registration for life support equipment and protection",
        link: links.forms.SA.lifeSupportNotificationForm
    },
    {
        formName: "Energex – Life support Medical Confirmation form",
        state: "QLD",
        description: "Registration for life support equipment and protection",
        link: links.forms.QLD.energexLifeSupportForm
    },
    {
        formName: "Ergon Energy – Life support Medical Confirmation form",
        state: "QLD",
        description: "Registration for life support equipment and protection",
        link: links.forms.QLD.ergonLifeSupportForm
    },
    {
        formName: "Add Joint Account Owner Application",
        state: "ALL",
        description: "Add a joint account owner to your account",
        link: links.forms.ALL.jointOwnerApplicationForm
    }
]

const SelfServeForm = () => {
    const navigate =useNavigate();
    const getGlobirdTableContainer = (): GlobirdTableContainer => {
        let dataContainer = new GlobirdTableContainer();
        dataContainer.headerList.push("Form", "State", "Description", "Format");
        for (let form of formList) {
            var rowData = [
                (<p>{form.formName}</p>),
                form.state,
                form.description,
                (<a target="_blank"
                    rel="noreferrer"
                    href={form.link}>
                    <img className="pdf-icon" src={pdfIcon} alt="pdf-icon" />
                </a>)
            ];

            dataContainer.rowList.push(rowData);
        }

        return dataContainer;
    }
    return (
        <div className="self-serve-form-block">
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <GlobirdSelector
                    id="sefMenuSelector"
                    options={menuOptions}
                    defaultOption={menuOptions[2]}
                    onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
            </div>
            <div style={{ margin: "30px 0 25px 0" }}>
                <h4>Download the form you need and email the completed form to
                    <a rel="noreferrer noopener" href={"mailto:" + links.email.cs} target="_blank"> {links.email.cs} </a>
                    or post it to PO BOX 398 Ringwood VIC 3134
                </h4>
                <h4><span className="phone-icon"><FontAwesomeIcon
                    icon={faPhone}/></span>
                    Can’t find what you’re looking for? Call us on <a href={links.phone.general}>{links.phone.generalDisplay}</a> and we’ll help you locate it.</h4>
            </div>
            <div>
                <GlobirdTable dataContainer={getGlobirdTableContainer()}></GlobirdTable>
            </div>
        </div>
    );
}

export default SelfServeForm;