import 'antd/dist/antd.less';
import 'components/Shared/shared.css';
import 'components/Shared/flex.css';
import 'components/Shared/animation.css';
import 'components/Shared/antd-override.css';

import {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Spin} from 'antd';
import * as queryString from 'query-string';

import authService from 'services/AuthService';
import MessageService from 'services/MessageService';

import Dashboard from 'components/Dashboard';
import Layout from 'components/Shared/Layout';
import Login from 'components/Login';
import CustomerPortalRouter, {AnonymousRoutes, AnyRoutes, ProtectedRoutes} from 'components/CustomerPortalRouter';
import ResetPassword from 'components/ResetPassword';
import AccountActivate from 'components/AccountActivate';
import AccountRegistration from 'components/AccountRegistration';
import ResetPasswordRequest from 'components/ResetPasswordRequest';

import ChangePassword from 'components/Areas/Account/ChangePassword';
import AccountSummary from 'components/Areas/Account/AccountSummary';
import AccountLink from 'components/Areas/Account/AccountLink';
import DirectDebit from 'components/Areas/Account/DirectDebit';
import ChangeEmail from 'components/Areas/Account/ChangeEmail';
import Concession from 'components/Areas/Account/Concession';
import AccountHolder from 'components/Areas/Account/AccountHolder';

import Moveout from 'components/Areas/Property/Moveout';
// import Site from 'components/Areas/Property/Site';
import ElectricityReconnection from 'components/Areas/Property/ElectricityReconnection';

import UsageChart from 'components/Areas/Usage/UsageChart';
import SelfRead from 'components/Areas/Usage/SelfRead';

import PayBill from 'components/Areas/Billing/PayBill';
import BillingHistory from 'components/Areas/Billing/BillingHistory'
import PaymentHistory from 'components/Areas/Billing/PaymentHistory';

import FAQ from 'components/Areas/Support/FAQ';
import menuOptions from 'components/Areas/Support/MenuOptions';
import Assistance from 'components/Areas/Support/Assistance';
import SelfServeForm from 'components/Areas/Support/SelfServeForm';

import Correspondences from 'components/Correspondences';
import CorrespondenceDetail from 'components/CorrespondenceDetail';

import {selectUser, setUser} from 'store/userSlice';
import {Message, MessageTopic} from 'data/Message';
import {endRequest, startRequest} from 'store/requestSlice';
import {ImpersonateTokenName} from 'data/CustomerPortalTypes';
import {getImpersonateTokenByCodeAsync} from 'services/RequestDataService';
import {removeFromQuery} from 'utilities/Utility';
import Unavailable from 'components/Unavailable';
import LinkManagement from "./components/Areas/ReferAFriend/LinkManagement";
import JointOwner from 'components/Areas/Account/JointOwner';
import LoginOneTimePassword from "./components/LoginOneTimePassword";
import Callback from 'components/Areas/Support/Callback';

function App() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);

    //initialize message service
    MessageService.get();

    useEffect(() => {
        const asyncActions = async () => {

            try {
                const query = queryString.parse(window.location.search);
                const { impersonateTokenCode } = query;

                if (impersonateTokenCode && typeof impersonateTokenCode === 'string') {
                    const tokenResult = await getImpersonateTokenByCodeAsync(impersonateTokenCode);

                    if (tokenResult?.data) {
                        sessionStorage.setItem(ImpersonateTokenName, tokenResult.data);

                        window.history.replaceState(
                            null,
                            '',
                            `${window.location.pathname}${removeFromQuery(query, ['impersonateTokenCode'])}`);
                    }
                }
            } catch { }

            try {
                const authenticateUser = await authService.authenticate();

                authenticateUser && dispatch(setUser(authenticateUser))
            }
            finally {
                setInitialized(true)
            }
        }

        asyncActions();

        const requestStartMsgWorkerId = MessageService.get().subscribe({
            message: {
                topic: MessageTopic.requestStart,
                data: null
            },
            handler: (message: Message) => {
                setRequestProcessing(true);
                dispatch(startRequest());
            }
        });

        const requestEndMsgWorkerId = MessageService.get().subscribe({
            message: {
                topic: MessageTopic.requestEnd,
                data: null
            },
            handler: (message: Message) => {
                setRequestProcessing(false);
                dispatch(endRequest());
            }
        });

        return () => {
            MessageService.get().unsubscribe(requestStartMsgWorkerId);
            MessageService.get().unsubscribe(requestEndMsgWorkerId);
        }
    }, [dispatch]); // run only once

    // useEffect(() => console.log(requestProcessing), [requestProcessing])

    if (!initialized) {
        return <Spin spinning={true}>
            <Layout hideAccountSelection={true} routed={false} />
        </Spin>
    }

    return (
        <>
            {user?.isImpersonatedUser && <div
                style={{ width: '100vw', height: '20px', color: 'white', backgroundColor: '#ffab00', textAlign: 'center' }}>
                Impersonating Session...
            </div>}
            <Router>
                <Spin spinning={requestProcessing} delay={400}>
                    {/* <Spin spinning delay={400}> */}
                    <CustomerPortalRouter
                        loginPath={'/login'}
                        returnPathParamName={'returnPath'}>
                        <AnyRoutes>
                            <Route path='/accountactivate' element={
                                <Layout showBackgroundImage={false} hideLogout={true} hideAccountSelection={true} hideReturnDashboard={true}>
                                    <AccountActivate />
                                </Layout>
                            } />
                            {/* login must be in AnyRoute for login redirect to work */}
                            <Route path='/login' element={
                                <Layout showBackgroundImage={true} hideLogout={true} hideAccountSelection={true} hideReturnDashboard={true}>
                                    <Login />
                                </Layout>
                            } />
                            <Route path='/unavailable' element={
                                <Layout showBackgroundImage={true} hideLogout={true} hideAccountSelection={true}>
                                    <Unavailable />
                                </Layout>
                            } />
                        </AnyRoutes>
                        <AnonymousRoutes>
                            <Route path='/register' element={
                                <Layout showBackgroundImage={true} hideLogout={true} hideAccountSelection={true} hideReturnDashboard={true}>
                                    <AccountRegistration />
                                </Layout>
                            } />
                            <Route path='/resetPassword' element={
                                <Layout showBackgroundImage={true} hideLogout={true} hideAccountSelection={true} hideReturnDashboard={true}>
                                    <ResetPassword />
                                </Layout>
                            } />
                            <Route path='/resetpasswordrequest' element={
                                <Layout showBackgroundImage={true} hideLogout={true} hideAccountSelection={true} hideReturnDashboard={true}>
                                    <ResetPasswordRequest />
                                </Layout>
                            } />
                            <Route path='/otp' element={
                                <Layout showBackgroundImage={true} hideLogout={true} hideAccountSelection={true}
                                        hideReturnDashboard={true}>
                                    <LoginOneTimePassword/>
                                </Layout>
                            }/>
                        </AnonymousRoutes>
                        <ProtectedRoutes>
                            {user && user.accounts.length > 0 ?
                                <>
                                    <Route path='/dashboard' element={
                                        <Layout hideReturnDashboard={true}>
                                            <Dashboard />
                                        </Layout>
                                    } />
                                    <Route path='/accountlink' element={
                                        <Layout title="My Account" subtitle="Link GloBird Account" hideAccountSelection={true}>
                                            <AccountLink />
                                        </Layout>
                                    } />
                                    <Route path='/changeemail' element={
                                        <Layout title="My Account" subtitle="Change Login Email" hideAccountSelection={true}>
                                            <ChangeEmail />
                                        </Layout>
                                    } />
                                    <Route path='/correspondences' element={
                                        <Layout >
                                            <Correspondences />
                                        </Layout>
                                    } />
                                    <Route path='/correspondences/:id' element={
                                        <Layout hideAccountSelection={true}>
                                            <CorrespondenceDetail />
                                        </Layout>
                                    } />
                                    <Route path='/accountsummary' element={
                                        <Layout title="My Details" subtitle="Account Summary">
                                            <AccountSummary />
                                        </Layout>
                                    } />
                                    <Route path='/directdebit' element={
                                        <Layout title="My Details" subtitle="Direct Debit">
                                            <DirectDebit />
                                        </Layout>
                                    } />
                                    <Route path='/concession/*' element={
                                        <Layout title="My Details" subtitle="Concession">
                                            <Concession />
                                        </Layout>
                                    } />
                                    <Route path='/accountholder' element={
                                        <Layout title="My Details" subtitle="Account Holder">
                                            <AccountHolder />
                                        </Layout>
                                    } />
                                    <Route path='/jointowner/*' element={
                                        <Layout title="My Details" subtitle="Joint Account Owner">
                                            <JointOwner />
                                        </Layout>
                                    } />
                                    <Route path='/moveout' element={
                                        <Layout title="My Property" subtitle="Move out">
                                            <Moveout />
                                        </Layout>
                                    } />
                                    {/* <Route path='/site' element={
              <Layout title="My Property & Plan" subtitle="My meter & site">
                <Site />
              </Layout>
            } /> */}
                                    <Route path='/reconnection/*' element={
                                        <Layout title="My Property" subtitle="Electricity Reconnection">
                                            <ElectricityReconnection />
                                        </Layout>
                                    } />
                                    <Route path='/paybill' element={
                                        <Layout title="My Bill" subtitle="Pay My Bill">
                                            <PayBill />
                                        </Layout>
                                    } />
                                    <Route path='/billinghistory' element={
                                        <Layout title="My Bill" subtitle="Billing History">
                                            <BillingHistory />
                                        </Layout>
                                    } />
                                    <Route path='/paymenthistory' element={
                                        <Layout title="My Bill" subtitle="Billing History">
                                            <PaymentHistory />
                                        </Layout>
                                    } />
                                    <Route path='/usagechart' element={
                                        <Layout title="My Energy Usage" subtitle="Usage Chart">
                                            <UsageChart />
                                        </Layout>
                                    } />
                                    <Route path='/selfread/*' element={
                                        <Layout title="My Energy Usage" subtitle="Upload Self Read">
                                            <SelfRead />
                                        </Layout>
                                    } />
                                    <Route path='/faq' element={
                                        <Layout title="My Support" subtitle="FAQs">
                                            <FAQ menuOptions={menuOptions} />
                                        </Layout>
                                    } />
                                    <Route path='/assistance' element={
                                        <Layout title="My Support" subtitle="Request Assistance">
                                            <Assistance />
                                        </Layout>
                                    } />
                                    <Route path='/selfserveform' element={
                                        <Layout title="My Support" subtitle="Self Serve Forms">
                                            <SelfServeForm />
                                        </Layout>
                                    } />
                                    <Route path='/callback' element={
                                        <Layout title="My Support" subtitle="Request a call-back">
                                            <Callback />
                                        </Layout>
                                    } />
                                    <Route path='/changepassword' element={
                                        <Layout title="My Details" subtitle="Change My Password">
                                            <ChangePassword />
                                        </Layout>
                                    } />
                                    <Route path='/linkmanagement' element={
                                        <Layout title="Power Perks" subtitle="Referral Link Management">
                                            <LinkManagement/>
                                        </Layout>
                                    }/>
                                    <Route path="*" element={
                                        <Navigate to={{ pathname: '/dashboard' }} replace />
                                    } />
                                </>
                                :
                                <>
                                    <Route path='/dashboard' element={
                                        <Layout hideReturnDashboard={true} hideAccountSelection={true}>
                                            <Dashboard />
                                        </Layout>
                                    } />
                                    <Route path='/faq' element={
                                        <Layout title="My Support" subtitle="FAQs" hideAccountSelection={true}>
                                            <FAQ menuOptions={[{ name: "FAQs", value: "faq" }]} />
                                        </Layout>
                                    } />
                                    <Route path="*" element={
                                        <Navigate to={{ pathname: '/dashboard' }} replace />
                                    } />
                                </>
                            }
                        </ProtectedRoutes>
                    </CustomerPortalRouter>
                </Spin>
            </Router >
        </>);
}

export default App;
