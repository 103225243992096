import { Button, Divider, Form, Select, Space, notification } from "antd";
import { CallbackInfo, DateTimeFormat, DefaultDisplayDateFormat, HourOnlyFormat } from "data/CustomerPortalTypes";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCallbackTimeSlotInfo, ignoreCallback, updateScheduledDateTime } from "services/RequestDataService";
import { selectCurrentAccount } from "store/userSlice";
import '../Callback.css';
import moment, { Moment } from "moment";
import DatePicker from "components/Shared/DatePicker";
import { disabledCallbackDates as isCallbackDateDisabled, generateRequiredRule, getRuleObject } from "utilities/Utility";
import { defaultCallbackTimeSlots, renderTimeSlotSelectOptions } from "..";
import trackEvent from "services/AnalyticsService";
import { addBusinessDays } from "utilities/DateTool";

const buildTimeSlotString = (scheduledDateTime : moment.Moment | Date) => {
    const timeSlotStart = moment(scheduledDateTime).format(`${DefaultDisplayDateFormat} (${HourOnlyFormat}`);
    const timeSlotEnd = moment(scheduledDateTime).add(1, 'hour').format(`${HourOnlyFormat})`);

    return `${timeSlotStart} - ${timeSlotEnd}`;
}

const ExistingCallback = ({
    existingCallbackInfoList,
    refreshCallbackInfo,
    publicHolidays
} : {
    existingCallbackInfoList: CallbackInfo[],
    refreshCallbackInfo: () => void,
    publicHolidays: string[]
}) => {
    const [form] = Form.useForm();
    const { Item } = Form;

    const currentAccount = useSelector(selectCurrentAccount);

    const [showScheduledDateForm, setShowScheduledDateForm] = useState<boolean>(false);
    const [selectedCallbackDate, setSelectedCallbackDate] = useState<Moment | null>(null);
    const [callbackTimeSlots, setCallbackTimeSlots] = useState<Map<number, boolean>>(defaultCallbackTimeSlots);
    const [selectedScheduledCallbackIndex, setSelectedScheduledCallbackIndex] = useState<number | null>(null);

    const ignoreCallbackRequest = async (callerPhoneNumber: string) => {
        if (!currentAccount) {
            return;
        }

        var response = await ignoreCallback(currentAccount.accountId, callerPhoneNumber);

        const { success, data } = response || {};
        
        if (success && data) {
            notification.success({
                message: 'Callback request deleted',
                description: 'The callback request has been deleted successfully.'
            });
            
            trackEvent("click", "callback", "ignore_callback", currentAccount.accountId);
            
            refreshCallbackInfo();
        }
    };

    const getCallbackTimeSlots = useCallback(async (targetDate: Moment) => {
        const timeSlotsResponse = await getCallbackTimeSlotInfo(targetDate);

        const { success, data: timeSlotMap } = timeSlotsResponse || {};

        if (success && timeSlotMap && timeSlotMap.size > 0) {
            setCallbackTimeSlots(timeSlotMap);

            // find the first time slot which is not booked, and set it as the default value
            const timeSlotKeys = Array.from(timeSlotMap.keys());
            const firstAvailableTimeSlot = timeSlotKeys.find(time => !timeSlotMap?.get(time));

            if (firstAvailableTimeSlot) {
                form.setFieldsValue({ callbackTimeSlot: firstAvailableTimeSlot });
            } else {
                //No available time slots, display error on form
                form.setFields([
                    {
                        name: 'callbackDate',
                        errors: ['No available time slots for this date. Please select another date.']
                    }
                ]);
                form.setFieldsValue({ callbackTimeSlot: undefined });
            }
        } else {
            setCallbackTimeSlots(defaultCallbackTimeSlots);
        }
    }, [form]);

    useEffect(() => {
        if (publicHolidays) {
            const today = moment();
            const nextBusinessDay = addBusinessDays(today, 1, publicHolidays);
            setSelectedCallbackDate(nextBusinessDay);
        }
    }, [publicHolidays]);

    useEffect(() => {
        if (!showScheduledDateForm || !selectedCallbackDate) {
            return;
        }
        getCallbackTimeSlots(selectedCallbackDate);
    }, [showScheduledDateForm, selectedCallbackDate, getCallbackTimeSlots]);

    const onFinish = async (values: any) => {
        if (!currentAccount || !selectedCallbackDate || !values.callbackTimeSlot || selectedScheduledCallbackIndex === null) {
            return;
        }

        //Combine the date and time to create a single datetime object
        const scheduledDateTime = 
            moment(selectedCallbackDate)
            .set({ 'h': values.callbackTimeSlot, 'm': 0, 's': 0 })
            .format(DateTimeFormat);

        const response = await updateScheduledDateTime(
            currentAccount.accountId, existingCallbackInfoList[selectedScheduledCallbackIndex].callerPhoneNumber, scheduledDateTime);

        const { success, data } = response || {};
        
        if (success && data) {
            notification.success({
                message: "Success",
                description: "Callback time has been updated"
            });

            trackEvent("click", "callback", "edit_callback_time", currentAccount.accountId);

            setShowScheduledDateForm(false);
            refreshCallbackInfo();
        }
    }

    const validateScheduledDateValue = (value: Moment | null) => {
        if (!value) {
            return null;
        }

        return isCallbackDateDisabled(value, publicHolidays)
            ? 'Invalid date'
            : null;
    }

    const renderEditScheduledDateForm = () => {
        return <Form
            form={form}
            name="callbackForm"
            layout="vertical"
            onFinish={onFinish}
            style={{ marginTop: '5px' }}>
                <Item
                    name="callbackDate"
                    label={<label className="globird-form-field-label">Callback Date</label>}
                    initialValue={selectedCallbackDate}
                    rules={[
                        generateRequiredRule('Callback Date'),
                        getRuleObject(value => validateScheduledDateValue(value))
                    ]}>
                    <DatePicker
                        id='callbackDate'
                        style={{ width: '100%' }}
                        mode='date'
                        onChange={val => setSelectedCallbackDate(val)}
                        disabledDate={currentDate => isCallbackDateDisabled(currentDate, publicHolidays)}
                        allowClear={false}/>
                </Item>
                <Item
                    name="callbackTimeSlot"
                    label={<label className="globird-form-field-label">Callback Time Slot</label>}
                    rules={[generateRequiredRule('Callback Time Slot')]}>
                    <Select>
                        {renderTimeSlotSelectOptions(callbackTimeSlots)}
                    </Select>
                </Item>
                <Space wrap>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        id={"updateCallbackTimeBtn"}
                        disabled={false}>
                        Update Callback Time
                    </Button>
                    <Button
                        onClick={hideScheduledDateForm}
                        type="default"
                        htmlType="button"
                        size="middle"
                        id={"cancelChangeCallbackTimeBtn"}
                        disabled={false}>
                        Cancel
                    </Button>
                </Space>
        </Form>;
    }

    const showScheduledDateFormWithIndex = (index: number) => {
        setSelectedScheduledCallbackIndex(index);
        setShowScheduledDateForm(true);
    }

    const hideScheduledDateForm = () => {
        setShowScheduledDateForm(false);
        setSelectedScheduledCallbackIndex(null);
    }

    if (!currentAccount || !existingCallbackInfoList) {
        return null;
    }

    return (
        <div className="callback-form">
            <h2>You have existing callback request(s)</h2>
            {existingCallbackInfoList.map((existingCallbackInfo, index) =>
                <>
                    <Divider style={{ margin: '15px 0' }} />
                    <p style={{ marginTop: '15px' }}><b>Phone Number:</b> {existingCallbackInfo.callerPhoneNumber}</p>
                    <p><b>Category:</b> {existingCallbackInfo.callbackType}</p>
                    {selectedScheduledCallbackIndex === index && showScheduledDateForm && selectedCallbackDate
                        ? renderEditScheduledDateForm()
                        : <>
                            {existingCallbackInfo.scheduleUtcDateTime
                                ? <>
                                    <p><b>Scheduled Time:</b> {buildTimeSlotString(existingCallbackInfo.scheduleUtcDateTime)}</p>
                                    <p style={{ marginTop: '10px' }}>We'll do our best to contact you around the requested time slot, however, we cannot always guarantee it will happen at the requested time.</p>
                                </>
                                : null}
                            <Space wrap style={{ marginTop: '10px' }}>
                                <Button
                                    onClick={() => showScheduledDateFormWithIndex(index)}
                                    type="primary"
                                    htmlType="submit"
                                    size="middle"
                                    id={"cancelCallbackTimeBtn"}
                                    disabled={false}>
                                    Change Callback Time
                                </Button>
                                <Button
                                    onClick={() => ignoreCallbackRequest(existingCallbackInfo.callerPhoneNumber)}
                                    type="default"
                                    htmlType="submit"
                                    size="middle"
                                    id={"cancelCallbackRequestBtn"}
                                    disabled={false}>
                                    Cancel Callback Request
                                </Button>
                            </Space>
                        </>}
                </>)}
        </div>
    );
};

export default ExistingCallback;