import styles from './AccountHolder.module.css'
import classNames from 'classnames';
import {
    useEffect,
    useMemo,
    useState
} from "react";
import { useSelector } from 'react-redux';
import { selectCurrentAccount } from 'store/userSlice';
import {
    Form,
    Input,
    notification,
    Select,
    Checkbox,
    Modal
} from 'antd'
import { ContactDto } from 'data/CustomerPortalTypes';
import DatePicker from 'components/Shared/DatePicker';
import {
    getAuthorizedContacts,
    upsertAuthorizedContact,
    deleteAuthorizedContact
} from 'services/RequestDataService';
import {
    AuMobileRule,
    ContactNameRule,
    dtoDateToFormDate,
    EmailRule,
    generateRequiredRule as genRqdRule,
    generateDobOver18Rule as genDobRule,
    generateCheckBoxRule as chkBxRule,
    generateLengthRule as genLenRule,
    formatDisplayDate,
    propOrderedStringify,
    formatJsonDate,
    AllContactTitles
} from 'utilities/Utility';
import SectionMenu from '../SectionMenu';
import { FieldData } from 'rc-field-form/lib/interface';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import FilteredInput from 'components/Shared/FilteredInput';
import { useNavigate } from 'react-router-dom';
import { NON_NUMBER_REGEX, WHITESPACE_REGEX } from "data/constant";

const { Item } = Form;

const defaultNewContact = {
    contactId: null,
    title: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null
};

const noAgreeTermErr = {
    name: 'agreeTerm',
    errors: []
};

const chkMsg = 'Please read the term and agree before continue.';

let submitted: boolean = false;
let deleteSubmitted: boolean = false;

type ContactDtoForm = ContactDto & { agreeTerm: boolean };

const AccountHolder = () => {

    const currentAccount = useSelector(selectCurrentAccount);

    const navigate = useNavigate();

    const [form] = Form.useForm<ContactDtoForm>();
    const [formValueUpdated, setFormValueUpdated] = useState<boolean>(false);
    const [formErrored, setFormErrored] = useState<boolean>(false);

    const [existingContacts, setExistingContacts] = useState<ContactDto[] | null>(null);

    const [editingContact, setEditingContact] = useState<ContactDto | undefined>(undefined);

    const addContactBtnDisabled =
        useMemo(() => (existingContacts?.length ?? 0) >= 5, [existingContacts]);

    const saveBtnDisabled =
        useMemo(() => !formValueUpdated || formErrored, [formValueUpdated, formErrored]);

    const saveBtnDisableMsg =
        useMemo(() => {
            if (formErrored) {
                return 'Please fix all errors before continue.';
            }

            if (!formValueUpdated) {
                return 'Contact unchanged.';
            }

            return '';
        }, [formValueUpdated, formErrored]);

    const notEditingNewContact = useMemo(() => editingContact?.contactId !== null, [editingContact]);

    const [showDelConfirmModal, setShowDelConfirmModal] = useState<boolean>(false);

    const changeEditingContact = (editingContactToSet: ContactDto | undefined) => {

        if (editingContactToSet !== undefined) {
            form.setFieldsValue({
                ...editingContactToSet,
                dateOfBirth: dtoDateToFormDate(editingContactToSet.dateOfBirth),
                agreeTerm: false
            });

            form.setFields([noAgreeTermErr]);
        }

        setEditingContact(editingContactToSet);
    };

    const refreshAuthorizedContactInfo = async () => {
        if (!currentAccount) {
            return;
        }

        const result = await getAuthorizedContacts(currentAccount.accountId);

        if (result?.success !== true || !result.data) {
            return;
        }

        const existingContacts = result.data;

        setExistingContacts(existingContacts);

        let contactToEdit: ContactDto | undefined = undefined;

        if (editingContact && existingContacts.length > 0) {

            if (editingContact.contactId === null) { // was editing new contact previously
                contactToEdit = existingContacts.sort((a, b) => b.contactId! - a.contactId!)[0]; // find the latest contact
            }
            else {
                contactToEdit = existingContacts.find(c => c.contactId === editingContact.contactId);
            }

        }

        if (!contactToEdit && existingContacts.length > 0) {

            contactToEdit = existingContacts[0];
        }

        changeEditingContact(contactToEdit);
    }

    useEffect(() => { refreshAuthorizedContactInfo(); },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentAccount]);

    if (!existingContacts || !currentAccount) {
        return <></>;
    }

    const deleteContact = async () => {
        if (deleteSubmitted) {
            return;
        }

        deleteSubmitted = true;

        setShowDelConfirmModal(false);

        try {
            if (!editingContact?.contactId || !currentAccount.accountId) {
                return;
            }

            const result = await deleteAuthorizedContact(currentAccount.accountId, editingContact.contactId);

            if (result?.success === true) {
                notification.success({
                    message: 'Success',
                    description: 'Successfully deleted authorized contact.'
                });

                setFormErrored(false);
                setFormValueUpdated(false);

                await refreshAuthorizedContactInfo();
            }
        }
        finally {
            deleteSubmitted = false;
        }
    };

    const selectContactIdToEdit = (selectedContactId: number | null | undefined) => {
        if (selectedContactId === null) {
            changeEditingContact(defaultNewContact);
        }
        else if (selectedContactId === undefined) {
            changeEditingContact(undefined);
        }
        else {
            changeEditingContact(
                existingContacts.find(x => x.contactId === selectedContactId))
        }

        setFormErrored(false);
        setFormValueUpdated(false);
    };

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
        if (!editingContact) {
            return;
        }

        let changed = false;
        let errored = false;

        for (let field of allFields) {
            if (field.errors?.length
                && field.errors?.length > 0) {

                errored = true;
            }

            if ((field.name as unknown as string[])[0] === 'agreeTerm') { continue; }

            const changePropName = (field.name as unknown as string[])[0] as keyof ContactDto;

            const currentValue = editingContact[changePropName];

            const fieldValue = field.value as typeof currentValue;

            if (changePropName === 'email') {
                const fieldValueNorm = (fieldValue as string)?.toLowerCase();
                const currentValueNorm = (currentValue as string)?.toLowerCase();

                if (fieldValueNorm !== currentValueNorm) {
                    changed = true;
                }
            }
            else if (changePropName === 'dateOfBirth') {
                if (formatDisplayDate(fieldValue as any) !== formatDisplayDate(currentValue as any)) {
                    changed = true;
                }
            }
            else {
                if (fieldValue !== currentValue) {
                    changed = true;
                }
            }
        }

        setFormValueUpdated(changed);
        setFormErrored(errored);
    };

    const onFinish = async (upsertDto: ContactDto) => {

        if (submitted || !upsertDto) {
            return;
        }

        submitted = true

        try {
            if (existingContacts?.length > 0) {
                // && upsertDto.contactId === null) {

                const serilUpsertDto = propOrderedStringify({
                    ...upsertDto,
                    dateOfBirth: formatDisplayDate(upsertDto.dateOfBirth),
                    contactId: undefined,
                    agreeTerm: undefined
                }).toLowerCase();

                const dupContacts = existingContacts
                    .filter(c =>
                        c
                        && (!upsertDto.contactId || (upsertDto.contactId !== c.contactId))
                        && (propOrderedStringify({
                            ...c,
                            dateOfBirth: formatDisplayDate(c.dateOfBirth),
                            contactId: undefined,
                            agreeTerm: undefined
                        }).toLowerCase() === serilUpsertDto));

                if (dupContacts.length > 0) {
                    alert('Duplicated contact is not allowed.');

                    return;
                }
            }

            const upsertResult = await upsertAuthorizedContact(
                currentAccount.accountId,
                {
                    ...upsertDto,
                    dateOfBirth: formatJsonDate(upsertDto.dateOfBirth) // dateOfBirth should not be nullable here
                });

            if (upsertResult?.success === true) {
                notification.success({
                    message: "Success",
                    description: upsertDto.contactId
                        ? "Successfully added authorized contact."
                        : "Successfully updated authorized contact."
                });

                setFormErrored(false);
                setFormValueUpdated(false);

                await refreshAuthorizedContactInfo();
            }
        }
        finally {
            submitted = false;
        }
    };

    return (
        <div className="form-container" style={{ maxWidth: '700px' }}>
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <SectionMenu
                    id="accountHolderMenuSelector"
                    defaultIndex={4} />
            </div>
            <div className="field-container" style={{ width: '90%' }}>
                <Form
                    form={form}
                    name="accountSummaryForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFieldsChange={onFieldsChange}>
                    <Item
                        name="contactId"
                        hidden>
                        <Input />
                    </Item>
                    <div className="globird-row">
                        <div className={classNames('globird-col', styles['account-holder-col-block'])}>
                            <Item
                                label={<label className="globird-form-field-label">Authorized contacts</label>}>
                                <Input.Group compact={notEditingNewContact}>
                                    <Select
                                        style={{
                                            width: notEditingNewContact
                                                ? '75%'
                                                : '100%'
                                        }}
                                        value={editingContact?.contactId as any}
                                        placeholder={!notEditingNewContact
                                            ? 'New Contact'
                                            : 'To get started, add a new contact'}
                                        onChange={(val: number | undefined) => selectContactIdToEdit(val)}
                                    >
                                        {
                                            existingContacts.map((c) => (
                                                <Select.Option
                                                    key={`scdAccHol-contactId-${c.contactId}`}
                                                    label={`${c.firstName} ${c.lastName}`}
                                                    value={c.contactId!}>
                                                    {`${c.firstName} ${c.lastName}`}
                                                </Select.Option>))
                                        }
                                    </Select>
                                    {/* {notEditingNewContact && <AddContactBtn
                                        btnDisabled={addContactBtnDisabled}
                                        onClick={() => selectContactIdToEdit(null)} />} */}
                                    {notEditingNewContact && <Bwtt
                                        disabled={addContactBtnDisabled}
                                        disableMessage="Maximum of 5 authorized contacts are allowed."
                                        useAntdBtn={true}
                                        componentClassName={[styles['new-contact-button-wrapper']]}
                                        btnClassName={[styles['new-contact-button']]}
                                        btnNode={<span></span>}
                                        onClick={() => selectContactIdToEdit(null)}
                                        id={"addNewContact"} 
                                    />}
                                </Input.Group>
                            </Item>
                        </div>
                    </div>
                    {
                        editingContact &&
                        <div>
                            <div className="globird-row">
                                <div className={classNames('globird-col', styles['account-holder-col-block'])}>
                                    <Item
                                        name="title"
                                        label={<label className="globird-form-field-label">Title</label>}
                                        rules={[genRqdRule('Title')]}>
                                        <Select>
                                            {AllContactTitles.map(k =>
                                                <Select.Option value={k} key={`scdAccHol-contactTitle-${k}`}>{k}</Select.Option>)}
                                        </Select>
                                    </Item>
                                    <Item
                                        name="firstName"
                                        label={<label className="globird-form-field-label">First Name</label>}
                                        rules={
                                            [genRqdRule('First name'), 
                                            ContactNameRule, 
                                            genLenRule('First name', 50)]
                                        }>
                                        <Input 
                                            allowClear
                                            onBlur={(e) => {
                                                const { value } = e.target;
                                                form.setFieldsValue({ firstName: value.trim() });
                                                form.validateFields(['firstName']);
                                            }}
                                        />
                                    </Item>
                                    <Item
                                        name="lastName"
                                        label={<label className="globird-form-field-label">Last Name</label>}
                                        rules={
                                            [genRqdRule('Last name'), 
                                            ContactNameRule, 
                                            genLenRule('Last name', 50)]
                                        }>
                                        <Input 
                                            allowClear
                                            onBlur={(e) => {
                                                const { value } = e.target;
                                                form.setFieldsValue({ lastName: value.trim() });
                                                form.validateFields(['lastName']);
                                            }}
                                        />
                                    </Item>
                                </div>
                                <div className={classNames('globird-col', styles['account-holder-col-block'])}>
                                    <Item
                                        name="phoneNumber"
                                        label={<label className="globird-form-field-label">Mobile Number</label>}
                                        rules={[genRqdRule('Mobile number'), AuMobileRule]}>
                                        <FilteredInput
                                            allowClear
                                            maxLength={10}
                                            filterExpression={NON_NUMBER_REGEX}
                                        />
                                    </Item>
                                    <Item
                                        name="email"
                                        label={<label className="globird-form-field-label">Email</label>}
                                        rules={[genRqdRule('Email address'), EmailRule]}>
                                        <FilteredInput
                                            allowClear
                                            filterExpression={WHITESPACE_REGEX} />
                                    </Item>
                                    <Item
                                        name="dateOfBirth"
                                        label={<label className="globird-form-field-label">Date of birth</label>}
                                        rules={[
                                            genRqdRule('Date of birth'),
                                            genDobRule('Authorized contact must be over 18.')]
                                        }>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            id="scdAccHol-dob-picker"
                                            allowClear />
                                    </Item>
                                </div>
                            </div>
                            <div className={styles['agree-term-block']}>
                                <Item
                                    name="agreeTerm"
                                    valuePropName="checked"
                                    rules={[chkBxRule(chkMsg)]}>
                                    <Checkbox style={{ textAlign: 'justify' }}>
                                        By clicking the save button and adding an authorised contact to your account, you authorise that person to make
                                        enquiries and changes to your account, including changing energy plans, disconnecting supply, changing direct debit
                                        details, withdrawing account credits, authorise data sharing arrangements, and other important aspects that may
                                        cause harm, inconvenience or financial impact. GloBird Energy is not liable for the actions or impact on your account
                                        by the person you nominate as an authorised person.
                                    </Checkbox>
                                </Item>
                            </div>
                            {editingContact.contactId ? null : <div style={{ paddingLeft: '12px' }}>
                                <p className={styles["account-holder-information-heading"]}>
                                    Important information regarding account types
                                </p>
                                <p className={styles["account-holder-information-text"]}>
                                        <b>Authorised contacts</b> can make enquiries, authorise data sharing arrangements and changes to the account but are not named on the bill or financially responsible.
                                        <br /><b>Joint account owners</b> can make enquiries, authorise data sharing arrangements and changes to the account, are named on the bill, and are financially responsible.
                                    <br />If you would prefer to add a joint account owner instead, click {" "}
                                    <u><a href="#" onClick={() => navigate("/jointowner")}>here</a></u>
                                </p>
                            </div>}
                            <div className="globird-row">
                                {editingContact.contactId && <div className={classNames('globird-col', styles['account-holder-col-block'])}>
                                    <Item>
                                            <button
                                                type="button"
                                                id={"delete"}
                                            className={
                                                classNames('globird-button-primary', 'form-save-button')}
                                            onClick={() => setShowDelConfirmModal(true)}>
                                            Delete
                                        </button>
                                    </Item>
                                </div>}
                                <div className={classNames('globird-col', styles['account-holder-col-block'])}>
                                    <Item>
                                            <Bwtt
                                                disabled={saveBtnDisabled}
                                                disableMessage={saveBtnDisableMsg}
                                                componentClassName={['form-save-button']}
                                                btnNode="Save"
                                                id={"save"}
                                        />
                                    </Item>
                                </div>
                            </div>
                        </div>
                    }
                </Form>
            </div>
            <Modal
                visible={showDelConfirmModal}
                onCancel={() => setShowDelConfirmModal(false)}
                onOk={deleteContact}
                closable={false}
                centered>
                Please confirm that you want to remove <strong>{`${editingContact?.firstName} ${editingContact?.lastName}`}</strong> as an authorised contacts.<br /><br />
                <strong>Note:</strong> If <strong>{`${editingContact?.firstName} ${editingContact?.lastName}`}</strong> has created one or more CDR arrangements,
                then sharing account data via those arrangements will cease. This could affect the services those Accredited Data Recipients were providing you.
            </Modal>
        </div>
    );
}

export default AccountHolder;