import React from 'react'
import './SupermanAlert.css';
import supperManImg from 'assets/globirdSuperman1.png';
import classNames from 'classnames';

const SupermanAlert = ({ size }: {
    size?: number
}) => {
    // css
    if (!size) {
        return (
            <div className={classNames('supperman', 'flex-left-center')}>
                <img className='supperman-img' src={supperManImg} alt="supper man img" />
                <div className='supperman-text'>
                    <div>Important information<br /> - please read carefully</div>
                </div>
            </div>
        )
    }

    return (
        <div className={classNames('supperman', 'flex-left-center')} style={{ borderRadius: size }} >
            <img className='supperman-img' src={supperManImg} alt="supper man img"
                style={{
                    width: size,
                    height: size,
                    borderRadius: size
                }}
            />
            <div className='supperman-text'>
                <div>Important information<br /> - please read carefully</div>
            </div>
        </div>
    )
}

export default SupermanAlert;