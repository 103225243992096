import GlobirdSelector from 'components/Shared/GlobirdSelector';
import GlobirdSelectorOption from 'data/GlobirdSelectorOption';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentAccountReconnectionInfo } from 'store/reconnectionInfoSlice';

const menuOptions: GlobirdSelectorOption<string>[] = [
    { name: "Move out", value: "moveout" },
    { name: "Electricity Reconnection", value: "reconnection" }
    //{ name: "My meter & site", value: "site" },
];

const PropertyMenuOption: FC<{ defaultIndex: number, id: string }> = ({ defaultIndex, id }) => {
    const navigate = useNavigate();
    const currentAccountReconnectionInfo = useSelector(selectCurrentAccountReconnectionInfo);
    var menuOptionsResult: GlobirdSelectorOption<string>[];
    if (currentAccountReconnectionInfo?.displayReconnectionPage) {
        menuOptionsResult = menuOptions;
    } else {
        menuOptionsResult = menuOptions
            .filter(x => x.value !== 'reconnection');
    }

    return <GlobirdSelector
        id={id}
        options={menuOptionsResult}
        defaultOption={menuOptionsResult[defaultIndex]}
        onSelected={(targetMenu) => { navigate(`/${targetMenu.value}`); }} />
}

export default PropertyMenuOption;