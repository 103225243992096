import styles from './AccountSummary.module.css'
import {
    useEffect,
    useMemo,
    useState
} from "react";
import { useSelector } from 'react-redux';
import { selectCurrentAccount, selectUser } from 'store/userSlice';
import {
    Form,
    Input,
    notification,
    Button,
    Select,
    Modal,
    Spin,
} from 'antd'
import {
    AccountInfo,
    AccountInfoUpdateDto,
    VerificationCodePurpose as VrfctCdPp,
    VerificationCodeType as VrfctCdTp,
    DeliveryType
} from 'data/CustomerPortalTypes';
import VerificationCodeModal from 'components/Shared/VerificationCodeModal';
import {
    getAccountInfo,
    updateAccountInfo,
    sendCode
} from 'services/RequestDataService';
import SectionMenu from '../SectionMenu';
import {
    dtoDateToFormDate,
    formatDisplayDate,
    SuburbNameRule,
    useCountdown,
    generateRequiredRule as genRqdRule,
    AuMobileRule,
    EmailRule,
    PostcodeRule,
    generateLengthRule as genLenRule,
    VarcharRule,
    workoutCountDownTimeStr,
    getCountdownName,
    AllContactTitles,
    AllDeliveryTypes,
    AllStateCodes
} from 'utilities/Utility';
import classNames from 'classnames';
import { FieldData } from 'rc-field-form/lib/interface';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import ChangeEmailForm from 'components/Shared/ChangeEmailForm';
import { selectRequestStarted } from 'store/requestSlice';
import FilteredInput from 'components/Shared/FilteredInput';

const { Item } = Form;

const needVerifyEmailError: { name: Extract<keyof AccountInfoUpdateDto, 'email'>, errors: string[] } = {
    name: 'email',
    errors: ['Please verify the new email address before continue.']
}

const validEmail: { name: Extract<keyof AccountInfoUpdateDto, 'email'>, errors: string[] } = {
    name: 'email',
    errors: []
}

const needVerifyPhoneError: { name: Extract<keyof AccountInfoUpdateDto, 'phoneNumber'>, errors: string[] } = {
    name: 'phoneNumber',
    errors: ['Please verify the new mobile number before continue.']
}

const validPhone: { name: Extract<keyof AccountInfoUpdateDto, 'phoneNumber'>, errors: string[] } = {
    name: 'phoneNumber',
    errors: []
}

const DeliveryTypeDisplayText: Record<DeliveryType, string> = {
    [DeliveryType.Email]: 'Email',
    [DeliveryType.PostalMail]: 'Postal Mail'
};

let submitted: boolean = false;

const AccountSummary = () => {
    const currentAccount = useSelector(selectCurrentAccount);
    const user = useSelector(selectUser);

    const [currentAccountInfo, setCurrentAccountInfo] = useState<AccountInfo | null>();

    const [formValueUpdated, setFormValueUpdated] = useState<boolean>(false);

    const [formErrored, setFormErrored] = useState<boolean>(false);
    const [phoneErrored, setPhoneErrored] = useState<boolean>(false);
    const [emailErrored, setEmailErrored] = useState<boolean>(false);

    const [emailUpdated, setEmailUpdated] = useState<boolean>(false);
    const [phoneUpdated, setPhoneUpdated] = useState<boolean>(false);

    const [validNewEmail, setValidNewEmail] = useState<string>('');
    const [validNewPhone, setValidNewPhone] = useState<string>('');

    const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState<boolean>(false);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState<boolean>(false);

    const [verifiedNewEmail, setVerifiedNewEmail] = useState<string>('');
    const [verifiedNewPhone, setVerifiedNewPhone] = useState<string>('');

    const [newEmailVerified, setNewEmailVerified] = useState<boolean>(false);
    const [newPhoneVerified, setNewPhoneVerified] = useState<boolean>(false);

    const [deliveryType, setDeliveryType] = useState<DeliveryType | null>(null);

    // console.log(getCountdownName(VrfctCdTp.Sms, VrfctCdPp.ChangeEuropaAccountContact))

    const [smsCountdownLeft, startSmsCountdown] = useCountdown(
        getCountdownName(VrfctCdTp.Sms, VrfctCdPp.ChangeEuropaAccountContact), 60)
    const [emailCountdownLeft, startEmailCountdown] = useCountdown(
        getCountdownName(VrfctCdTp.Email, VrfctCdPp.ChangeEuropaAccountContact), 60)

    const [phoneCodeCorrelationId, setPhoneCodeCorrelationId] = useState<string>('');
    const [phoneCodeTicketId, setPhoneCodeTicketId] = useState<string | null>(null);

    const [emailCodeCorrelationId, setEmailCodeCorrelationId] = useState<string>('');
    const [emailCodeTicketId, setEmailCodeTicketId] = useState<string | null>(null);

    const [showAskChangeLoginModal, setShowAskChangeLoginModal] = useState<boolean>(false);
    const [showChangeLoginModal, setShowChangeLoginModal] = useState<boolean>(false);
    const [changeLoginEmail, setChangeLoginEmail] = useState<string>('');
    const [changeLoginTicket, setChangeLoginTicket] = useState<string | null>(null);

    const isRequestStarted = useSelector(selectRequestStarted);

    const [form] = Form.useForm<AccountInfoUpdateDto>();

    const closePhoneModal = useMemo(() => () => setShowVerifyPhoneModal(false), []);
    const closeEmailModal = useMemo(() => () => setShowVerifyEmailModal(false), []);
    const closeAskChangeLoginModal = useMemo(() => () => setShowAskChangeLoginModal(false), []);
    const closeChangeLoginModal = useMemo(() => () => setShowChangeLoginModal(false), []);

    const onPhoneVerified = useMemo(() => (phoneTickId: string, newPhoneNumber: string) => {
        setNewPhoneVerified(true);
        setShowVerifyPhoneModal(false);
        setPhoneCodeTicketId(phoneTickId);
        setVerifiedNewPhone(newPhoneNumber);
    }, []);

    const onEmailVerified = useMemo(() => (emailTickId: string, newEmail: string) => {
        setEmailCodeTicketId(emailTickId);
        setVerifiedNewEmail(newEmail);
        setNewEmailVerified(true);
        setShowVerifyEmailModal(false);
    }, []);

    const saveBtnDisabled =
        useMemo(() => !formValueUpdated || formErrored, [formValueUpdated, formErrored]);

    const saveBtnDisableMsg =
        useMemo(() => {
            if (formErrored) {
                return 'Please fix all errors before continue.';
            }

            if (!formValueUpdated) {
                return 'Account summary unchanged.';
            }

            return '';
        }, [formValueUpdated, formErrored]);

    const refreshCurrentAccountInfo = async () => {
        if (!currentAccount) {
            return;
        }

        const currentAccountInfo =
            await getAccountInfo(currentAccount.accountId);

        if (!currentAccountInfo
            || !currentAccountInfo.success
            || !currentAccountInfo.data) {

            return;
        }

        const result = {
            ...currentAccountInfo.data,
            dateOfBirth: dtoDateToFormDate(currentAccountInfo.data.dateOfBirth)
        };

        form.setFieldsValue(result);

        setCurrentAccountInfo(result);
        setDeliveryType(result.deliveryType);
    }

    const resetForm = () => {
        setFormValueUpdated(false);

        setFormErrored(false);
        setPhoneErrored(false);
        setEmailErrored(false);

        setEmailUpdated(false);
        setPhoneUpdated(false);

        setVerifiedNewEmail('');
        setVerifiedNewPhone('');

        setNewEmailVerified(false);
        setNewPhoneVerified(false);

        setPhoneCodeCorrelationId('');
        setPhoneCodeTicketId(null);

        setEmailCodeCorrelationId('');
        setEmailCodeTicketId(null);

        setValidNewEmail('');
        setValidNewPhone('');
    }

    useEffect(() => {
        refreshCurrentAccountInfo();
        resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount, form]);

    if (!currentAccountInfo || !currentAccount) {
        return <></>;
    }

    const onFinish = async (values: AccountInfoUpdateDto) => {
        if (!currentAccount || submitted) {
            return;
        }

        submitted = true;

        try {
            if ((!newPhoneVerified && phoneUpdated)
                || (!newEmailVerified && emailUpdated)) {

                const fields: FieldData[] = [];

                if (!newPhoneVerified && phoneUpdated) {
                    fields.push(needVerifyPhoneError);
                }

                if (!newEmailVerified && emailUpdated) {
                    fields.push(needVerifyEmailError);
                }

                form.setFields(fields);

                return;
            }

            const result = await updateAccountInfo(
                currentAccount.accountId,
                values,
                phoneCodeTicketId,
                emailCodeTicketId);

            if (result?.success !== true) {
                return;
            }

            notification.success({
                message: "Success",
                description: "Successfully updated account summary."
            });

            setCurrentAccountInfo(null);

            resetForm();

            if (emailUpdated
                && verifiedNewEmail?.toLowerCase() !== user?.emailAddress?.toLowerCase()) {

                setChangeLoginEmail(verifiedNewEmail);
                setChangeLoginTicket(emailCodeTicketId);
                setShowAskChangeLoginModal(true);
            }

            await refreshCurrentAccountInfo();
        }
        finally {
            submitted = false;
        }
    }

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {

        let changed = false;
        let errored = false;

        let emailUpdated = false;
        let emailErrored = false;
        let emailVerified = false;

        let phoneUpdated = false;
        let phoneErrored = false;
        let phoneVerified = false;

        let currentEmail = '';
        let currentPhone = '';

        for (let field of allFields) {

            const changePropName = (field.name as unknown as string[])[0] as keyof AccountInfo;

            const currentValue = currentAccountInfo[changePropName];

            const fieldValue = field.value as typeof currentValue;

            switch (changePropName) {
                case 'email': {
                    const fieldValueNorm = (fieldValue as string)?.toLowerCase();
                    const currentValueNorm = (currentValue as string)?.toLowerCase();
                    const verifiedValueNorm = (verifiedNewEmail as string)?.toLowerCase();

                    currentEmail = fieldValue as string;

                    if (fieldValueNorm !== currentValueNorm) {
                        changed = true;
                        emailUpdated = true;
                    } else {
                        emailUpdated = false;
                    }

                    if (field.errors?.length
                        && field.errors?.length > 0) {

                        emailErrored = true;
                        errored = true;
                    }
                    else {
                        emailErrored = false;
                    }

                    if (verifiedValueNorm
                        && verifiedValueNorm === fieldValueNorm) {

                        emailVerified = true;
                    } else {

                        emailVerified = false;
                    }

                    break;
                }
                case 'phoneNumber': {
                    currentPhone = fieldValue as string;

                    if (fieldValue !== currentValue) {
                        changed = true;
                        phoneUpdated = true;
                    } else {
                        phoneUpdated = false;
                    }

                    if (field.errors?.length
                        && field.errors?.length > 0) {

                        phoneErrored = true;
                        errored = true;
                    }
                    else {
                        phoneErrored = false;
                    }

                    if (verifiedNewPhone
                        && verifiedNewPhone === fieldValue) {

                        phoneVerified = true;
                    } else {
                        phoneVerified = false;
                    }

                    break;
                }
                default: {
                    if (fieldValue !== currentValue) {
                        changed = true;
                    }

                    if (field.errors?.length
                        && field.errors?.length > 0) {

                        errored = true;
                    }
                }
            }
        }

        setEmailUpdated(emailUpdated);
        setEmailErrored(emailErrored);
        setNewEmailVerified(emailVerified);

        setPhoneUpdated(phoneUpdated);
        setPhoneErrored(phoneErrored);
        setNewPhoneVerified(phoneVerified);

        setFormValueUpdated(changed);
        setFormErrored(errored);

        if (currentEmail && emailUpdated && !emailErrored) {
            setValidNewEmail(currentEmail);
        }

        if (currentPhone && phoneUpdated && !phoneErrored) {
            setValidNewPhone(currentPhone);
        }
    }

    const sendVerifySmsCode = async () => {

        startSmsCountdown();

        form.setFields([validPhone]);

        let phoneNumber = validNewPhone;

        const codeResult = await sendCode(
            {
                RecipientIdentifier: phoneNumber,
                Purpose: VrfctCdPp.ChangeEuropaAccountContact,
                CodeType: VrfctCdTp.Sms
            },
            currentAccount.accountId
        );

        if (codeResult?.success === true && codeResult?.data) {
            setPhoneCodeCorrelationId(codeResult.data);
            setShowVerifyPhoneModal(true);
        }
        else {
            setShowVerifyPhoneModal(false);
        }
    }

    const sendVerifyEmailCode = async () => {

        startEmailCountdown();

        form.setFields([validEmail]);

        const emailAddress = validNewEmail;

        const codeResult = await sendCode(
            {
                RecipientIdentifier: emailAddress,
                Purpose: VrfctCdPp.ChangeEuropaAccountContact,
                CodeType: VrfctCdTp.Email
            },
            currentAccount.accountId);


        if (codeResult?.success === true && codeResult?.data) {
            setEmailCodeCorrelationId(codeResult.data);
            setShowVerifyEmailModal(true);
        }
        else {
            setShowVerifyEmailModal(false);
        }
    }

    return (
        <div className="form-container" style={{ maxWidth: '700px' }}>
            <div className="globird-menu-option">
                <p className="globird-menu-label">Show me: </p>
                <SectionMenu
                    id="accountSummaryMenuSelector"
                    defaultIndex={0} />
            </div>
            <div className="field-container">
                <Form
                    form={form}
                    name="accountSummaryForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFieldsChange={onFieldsChange}>
                    <div className="globird-row">
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])}>
                            <Item
                                name="displayName"
                                label={<h3>Display Name On Invoice</h3>}>
                                <p>{currentAccountInfo.displayName}</p>
                            </Item>
                        </div>
                    </div>
                    <div className={styles['account-summary-section']}>
                        <h3>Primary Contact</h3>
                        <hr></hr>
                    </div>
                    <div className="globird-row">
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="firstName"
                                label={<label className="globird-form-field-label">First Name</label>}>
                                <p className={styles['account-summary-field']}>{currentAccountInfo.firstName}</p>
                            </Item>
                        </div>
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="lastName"
                                label={<label className="globird-form-field-label">Last Name</label>}>
                                <p className={styles['account-summary-field']}>{currentAccountInfo.lastName}</p>
                            </Item>
                        </div>
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="dateOfBirth"
                                label={<label className="globird-form-field-label">Date of birth</label>}>
                                <p className={styles['account-summary-field']}>{formatDisplayDate(currentAccountInfo.dateOfBirth)}</p>
                            </Item>
                        </div>
                    </div>

                    <div style={{ padding: "0px 10px" }}>
                        <hr></hr>
                    </div>

                    <div className="globird-row">
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="deliveryType"
                                label={<label className="globird-form-field-label">
                                    Correspondence Method
                                </label>}
                                rules={[genRqdRule('Correspondence Method')]}>
                                <Select
                                    id={"deliverymethod"}
                                    defaultValue={currentAccountInfo.deliveryType}
                                    onChange={val => setDeliveryType(val)}>
                                    {AllDeliveryTypes.map(k =>
                                        <Select.Option
                                            value={k}
                                            key={`acctSumm-deliveryType-${k}`}>
                                            {DeliveryTypeDisplayText[k] ?? k}
                                        </Select.Option>)}
                                </Select>
                            </Item>
                            <Item
                                name="title"
                                label={<label className="globird-form-field-label">Title</label>}
                                rules={[genRqdRule('Title')]}>
                                <Select
                                    id={"title"}>
                                    {AllContactTitles.map(k =>
                                        <Select.Option value={k} key={`acctSumm-contactTitle-${k}`} data-auto={"title-option"}>{k}</Select.Option>)}
                                </Select>
                            </Item>
                            <Item
                                name="phoneNumber"
                                label={<label className="globird-form-field-label">Mobile Number</label>}
                                rules={[genRqdRule('Mobile number'), AuMobileRule]}>
                                <Input
                                    allowClear
                                    className={classNames({
                                        'input-with-button-hidden': !phoneUpdated
                                    })}
                                    onBlur={(e) => {
                                        const { value } = e.target;
                                        form.setFieldsValue({ phoneNumber: value.replace(/\D/g, '') });
                                        form.validateFields(['phoneNumber']);
                                    }}
                                    id={"mobilenumber"}
                                    addonAfter={
                                        <Button
                                            key="accnt-summ-verify-phone"
                                            hidden={!phoneUpdated}
                                            disabled={newPhoneVerified
                                                || phoneErrored
                                                || smsCountdownLeft > 0}
                                            className="inline-verify-button"
                                            onClick={sendVerifySmsCode} >
                                            {phoneErrored
                                                ? 'Verify'
                                                : newPhoneVerified
                                                    ? 'Verified'
                                                    : smsCountdownLeft <= 0
                                                        ? 'Verify'
                                                        : `Verify after (${workoutCountDownTimeStr(smsCountdownLeft)})`}
                                        </Button>
                                    }
                                />
                            </Item>
                            <Item
                                name="email"
                                label={<label className="globird-form-field-label">Email</label>}
                                rules={[genRqdRule('Email address'), EmailRule]}
                                tooltip={deliveryType === DeliveryType.Email
                                    ? 'Your bill will be sent to this email address.'
                                    : undefined}>
                                <FilteredInput
                                    allowClear
                                    className={classNames({
                                        'input-with-button-hidden': !emailUpdated
                                    })}
                                    id="email"
                                    filterExpression={/\s/g}
                                    addonAfter={
                                        <Button
                                            hidden={!emailUpdated}
                                            key="accnt-summ-verify-email"
                                            disabled={newEmailVerified
                                                || emailErrored
                                                || emailCountdownLeft > 0}
                                            className="inline-verify-button"
                                            onClick={sendVerifyEmailCode}>
                                            {emailErrored
                                                ? 'Verify'
                                                : newEmailVerified
                                                    ? 'Verified'
                                                    : emailCountdownLeft <= 0
                                                        ? 'Verify'
                                                        : `Verify after (${workoutCountDownTimeStr(emailCountdownLeft)})`}
                                        </Button>
                                    } />
                            </Item>
                            <Item
                                name="addressLine1"
                                label={<label className="globird-form-field-label">Postal Address Line 1</label>}
                                rules={[genRqdRule('Address line 1'), genLenRule('Address line 1', 100), VarcharRule]}
                                tooltip={deliveryType === DeliveryType.PostalMail
                                    ? 'Your bill will be sent to this address via postal mail.'
                                    : undefined}>
                                <Input allowClear id={"postalAddress1"} />
                            </Item>
                            <Item
                                name="addressLine2"
                                label={<label className="globird-form-field-label">Postal Address Line 2</label>}
                                rules={[genLenRule('Address line 2', 100), VarcharRule]}>
                                <Input allowClear id={"postalAddress2"} />
                            </Item>
                        </div>
                    </div>
                    <div className="globird-row">
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="suburb"
                                label={<label className="globird-form-field-label">Suburb</label>}
                                rules={[genRqdRule('Suburb'), SuburbNameRule, genLenRule('Suburb', 50), VarcharRule]}>
                                <Input allowClear id={"suburb"}/>
                            </Item>
                        </div>
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="stateCode"
                                label={<label className="globird-form-field-label">State Code</label>}
                                rules={[genRqdRule('State code')]}>
                                <Select id={"state"}>
                                    {AllStateCodes.map(k =>
                                        <Select.Option value={k} key={`acctSumm-stateCode-${k}`} data-auto={"state-option"}>{k}</Select.Option>)}
                                </Select>
                            </Item>
                        </div>
                        <div className={
                            classNames(
                                'globird-col',
                                styles['account-summary-col-block'])
                        }>
                            <Item
                                name="postcode"
                                label={<label className="globird-form-field-label">Post Code</label>}
                                rules={[genRqdRule('Post code'), PostcodeRule]}>
                                <Input allowClear id={"postcode"} />
                            </Item>
                        </div>
                    </div>
                    <Item>
                        <Bwtt
                            disabled={saveBtnDisabled}
                            disableMessage={saveBtnDisableMsg}
                            componentClassName={['form-save-button']}
                            btnNode="Save"
                        />
                    </Item>
                </Form>
            </div>
            <VerificationCodeModal
                title="Verify Your Mobile Phone"
                visible={showVerifyPhoneModal}
                onCancel={closePhoneModal}
                codePurpose={VrfctCdPp.ChangeEuropaAccountContact}
                codeType={VrfctCdTp.Sms}
                corrlelationId={phoneCodeCorrelationId}
                recipientIdentifier={validNewPhone}
                onVerificationSucceeded={onPhoneVerified}
                accountId={currentAccount.accountId}
                resendCountdownLeft={smsCountdownLeft}
                resendCb={sendVerifySmsCode}
            />
            <VerificationCodeModal
                title="Verify Your Email"
                visible={showVerifyEmailModal}
                onCancel={closeEmailModal}
                codePurpose={VrfctCdPp.ChangeEuropaAccountContact}
                codeType={VrfctCdTp.Email}
                corrlelationId={emailCodeCorrelationId}
                recipientIdentifier={validNewEmail}
                onVerificationSucceeded={onEmailVerified}
                accountId={currentAccount.accountId}
                resendCountdownLeft={emailCountdownLeft}
                resendCb={sendVerifyEmailCode} />
            <Modal
                visible={showAskChangeLoginModal}
                okText="Yes, please"
                cancelText="No, thanks"
                onCancel={closeAskChangeLoginModal}
                onOk={() => { setShowChangeLoginModal(true); closeAskChangeLoginModal(); }}
                maskClosable={false}>
                Would you like to change your login Email to <strong>{currentAccountInfo.email}</strong>?
            </Modal>
            <Modal
                visible={showChangeLoginModal}
                onCancel={closeChangeLoginModal}
                maskClosable={false}
                footer={null}>
                <Spin spinning={isRequestStarted}>
                    <ChangeEmailForm
                        defaultEmail={changeLoginEmail}
                        verifyTicketId={changeLoginTicket!}
                        onChangeSucceeded={closeChangeLoginModal} />
                </Spin>

            </Modal>
        </div >
    );
}

export default AccountSummary;